import GlobalStateRecord from "models/view-models/global-state-record";
import UserSettingRecord from "models/view-models/user-setting-record";
import { Icons } from "atoms/constants/icons";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import NotificationDotIcon from "atoms/icons/notification-dot-icon";
import Button from "atoms/buttons/button";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useUserSettings from "utilities/hooks/domain/user-settings/use-user-settings";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import { t } from "utilities/localization-utils";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-bookview-user-preferences";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BookviewContentWidthToggleButton: React.FC = () => {
    const { globalState, setGlobalState } = useGlobalState();
    const { createOrUpdate: createOrUpdateUserSettings } = useUserSettings();

    const [loading, setLoading] = useState<boolean>(false);

    const narrowViewDefaultSetting = globalState.getUserSetting("NarrowView");

    const narrowView =
        globalState.getUserSettingValue<boolean>("NarrowView") ?? undefined;

    const currentUserId = globalState.currentIdentity?.userId();

    const { handleToggleContentExpanded } = useBookviewLayoutContext();

    const tooltipContent = !narrowView ? t("narrowView") : t("wideView");

    useEffect(() => {
        if (narrowView) {
            handleToggleContentExpanded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSettingToggle = async (
        value: boolean,
        setting?: UserSettingRecord
    ) => {
        if (currentUserId == null || loading || value == null) {
            return;
        }

        setLoading(true);

        const dto = setting?.with({
            value: `${value}`,
        });

        const result = await createOrUpdateUserSettings(dto, currentUserId);

        if (result == null) {
            setLoading(false);
            return;
        }

        setGlobalState((globalState: GlobalStateRecord) =>
            globalState.updateUserSetting(result)
        );
        setLoading(false);
    };

    const handleNarrowViewDefaultToggle = async (value: boolean) => {
        await handleSettingToggle(value, narrowViewDefaultSetting);
        handleToggleContentExpanded();
    };

    return (
        <div className={CSS_BASE_CLASS_NAME}>
            <Tooltip
                content={tooltipContent}
                cssClassName={`${CSS_BASE_CLASS_NAME}__tooltip`}
                durationInMs={0}
                hideOnClick={true}
                placement={TooltipPlacement.Bottom}>
                <Button
                    accessibleText={t(
                        "bookviewContentWidthToggle_accessibleText"
                    )}
                    ariaDisabled={false}
                    cssClassName={`${CSS_BASE_CLASS_NAME}__button`}
                    onClick={() => handleNarrowViewDefaultToggle(!narrowView)}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.TertiaryAlt}>
                    {narrowViewDefaultSetting?.userId !== currentUserId && (
                        <div style={{ marginTop: "-1px" }}>
                            <NotificationDotIcon pulse={true} />
                        </div>
                    )}
                    {!narrowView ? (
                        <Icon
                            size={IconSizes.Base}
                            type={Icons.CloseExpansion}
                        />
                    ) : (
                        <Icon size={IconSizes.Base} type={Icons.Enlarge} />
                    )}
                </Button>
            </Tooltip>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BookviewContentWidthToggleButton;

// #endregion Exports
