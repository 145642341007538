import Anchor from "atoms/anchors/anchor";
import EmulateOrganizationMemberButton from "./emulate-organization-member-button";
import EmulateUserButton from "./emulate-user-button";
import ExtendFreeTrial from "molecules/extend-free-trial/extend-free-trial";
import Heading from "atoms/typography/heading";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import React, { useEffect, useMemo } from "react";
import SubscriptionTabs from "./subscription-tabs";
import UserDetails, { UserDetail } from "./user-details";
import useSupportUserDetail from "utilities/hooks/domain/admin/support/use-support-user-detail";
import { AdminRoleTypes } from "utilities/enumerations/role-type";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import { useParams } from "react-router-dom";

interface Params {
    id: string;
}

const BASE_CLASS = "c-support-user-detail-page";
const USER_DETAIL_DATA = `c-support-user-detail-page__user-details__data`;

const SupportUserDetailPage: React.FC = () => {
    const { id } = useParams<Params>();

    const { getUserDetail, loading, supportUser, updateFreeTrialExpDate } =
        useSupportUserDetail();

    useEffect(() => {
        const userId = Number(id);

        getUserDetail(userId);
    }, [getUserDetail, id]);

    const eulaAccepted =
        (supportUser?.eulaAccepted ||
            supportUser?.subscriptions?.some((s) => s.eulaAccepted)) ??
        false;

    const isUserAdmin = supportUser?.subscriptions?.some((s) =>
        AdminRoleTypes.includes(s.roleType)
    );

    const freeTrialSubscription = supportUser?.getFreeTrial();

    const userDetails: UserDetail[] = [
        { heading: "email", data: supportUser?.email ?? "N/A" },
        { heading: "external id", data: supportUser?.legacyId ?? "N/A" },
        { heading: "last login", data: supportUser?.formatLastLogin() ?? "" },
        { heading: "eula accepted", data: eulaAccepted ? "Yes" : "No" },
    ];

    const hasOrgMemberRole = useMemo(
        () =>
            supportUser?.userOrganizationRoles &&
            supportUser?.userOrganizationRoles?.length > 0,
        [supportUser?.userOrganizationRoles]
    );

    const onlyHasOrgMemberRole = useMemo(
        () =>
            hasOrgMemberRole &&
            supportUser?.subscriptions?.every((s) => s.description == null),
        [hasOrgMemberRole, supportUser?.subscriptions]
    );

    return (
        <div className={BASE_CLASS}>
            <BackButton />
            <div className={`${BASE_CLASS}__user-details`}>
                {loading && <Loader />}

                {!loading && supportUser == null && null}

                {!loading && supportUser && (
                    <>
                        <div className={`${BASE_CLASS}__user-details__user`}>
                            <Heading priority={HeadingPriority.Five}>
                                {supportUser.fullName}
                            </Heading>
                            {!onlyHasOrgMemberRole && (
                                <EmulateUserButton
                                    guestId={supportUser.id}
                                    isAdmin={isUserAdmin}
                                />
                            )}
                            {hasOrgMemberRole &&
                                supportUser?.userOrganizationRoles?.map(
                                    (orgRole) => (
                                        <EmulateOrganizationMemberButton
                                            key={orgRole.userRoleOrganizationId}
                                            organizationName={
                                                orgRole.organizationName
                                            }
                                            guestId={orgRole.userId}
                                            userRoleOrganizationId={
                                                orgRole.userRoleOrganizationId
                                            }
                                            isAdmin={isUserAdmin}
                                        />
                                    )
                                )}
                        </div>
                        <UserDetails
                            cssClassName={USER_DETAIL_DATA}
                            details={userDetails}>
                            {freeTrialSubscription && (
                                <ExtendFreeTrial
                                    updateFreeTrialExpDate={
                                        updateFreeTrialExpDate
                                    }
                                    supportUserSubscription={
                                        freeTrialSubscription
                                    }
                                />
                            )}
                        </UserDetails>

                        <SubscriptionTabs
                            subscriptions={supportUser.subscriptions}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default SupportUserDetailPage;

const BackButton = () => (
    <Anchor
        cssClassName={`c-button ${ButtonSizes.Small} ${ButtonStyles.Tertiary}`}
        to={RouteUtils.getUrl(siteMap.admin.support.users)}>
        <Icon size={IconSizes.Base} type={Icons.ChevronLeft} />
        All Users
    </Anchor>
);
