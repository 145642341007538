import { siteMap } from "internal-sitemap";
import FreeAccessPublication from "models/interfaces/free-access-publication";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import decipher from "utilities/decipher";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import FreeAccessService from "utilities/services/publications/free-access/free-access-service";

interface UseFreeAccessPublicationOptions {
    code: string;
    edition: string;
}

export default function useFreeAccessPublication(
    options: UseFreeAccessPublicationOptions
) {
    const { code, edition } = options;
    const { get: getFreeAccessPublication } = FreeAccessService.useGet();
    const history = useHistory();

    const { productBasePath } = useNfpaUrlPaths();

    const { result, setError, setInitial, setLoading, setSuccess } =
        useServiceHookResultState<FreeAccessPublicationRecord>(
            new FreeAccessPublicationRecord()
        );

    const loadFreeAccessPublication = useCallback(async () => {
        setLoading();

        try {
            const { resultObject: freeAccessPublication } =
                await getFreeAccessPublication({
                    code: code,
                    edition: edition,
                });

            if (!freeAccessPublication) {
                throw new Error();
            }
            const publication = decipher<FreeAccessPublication>(
                freeAccessPublication.toString()
            );

            if (publication != null) {
                setSuccess(new FreeAccessPublicationRecord(publication));
            } else {
                setInitial();
            }
        } catch (error) {
            console.log(error);
            history.push(siteMap.errors.notFound);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        code,
        edition,
        getFreeAccessPublication,
        setError,
        setInitial,
        setLoading,
        setSuccess,
    ]);

    useEffect(() => {
        loadFreeAccessPublication();
    }, [loadFreeAccessPublication]);

    const showContent = useMemo(
        () => result.loaded && result.resultObject != null,
        [result.loaded, result.resultObject]
    );

    const showTOC = CollectionUtils.hasValues(
        result.resultObject?.freeAccessPublicationTableOfContents
    );

    const buyPublicationUrl = `${productBasePath}/nfpa-${code}-code/p${code.padStart(4, "0")}code?Edition=${edition}`;

    return {
        pages: result.resultObject.freeAccessPublicationPages,
        totalPages: result.resultObject.totalPages,
        tableOfContents:
            result.resultObject.freeAccessPublicationTableOfContents,
        showContent,
        showTOC,
        title: result.resultObject?.title,
        buyPublicationUrl,
    };
}
