import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React from "react";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { AuthenticationLifeCycleStatus } from "utilities/enumerations/authorization";
import { useCurrentIdentityProvider } from "utilities/atoms/current-identity-provider-atom";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

const MultiTenantLoginPage = () => {
    const { authenticationLifeCycleStatus, logout } = useAuthentication();
    const { currentIdentityProvider } = useCurrentIdentityProvider();
    const { showSSODebugLogs } = useFeatureFlags();

    if (
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.FailedToLogin ||
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.FailedToVerifyLogin
    ) {
        if (showSSODebugLogs) {
            console.log(
                "Error while authenticating.  Logging out of Organization: ",
                currentIdentityProvider?.loginSlug
            );
        }
        // Note: We may need to logout even we failed to login.
        // This may happen with Authentication with MSAL Succeeded, but authentication with LiNK Failed.
        //  Example: (Organization Credentials are correct, but LiNK could NOT get an organizationId and does not create a LiNK session).
        // When this happens, there may be clean up we need to do that the logout method handles for us.
        //  Example: Clearing the OrganizationAccessToken
        logout(currentIdentityProvider?.loginSlug);
    }

    return <FullScreenTransition transitionText={""} />;
};

export default MultiTenantLoginPage;
