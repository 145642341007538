import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { AuthenticationStrategyFactoryHookResult } from "utilities/services/auth/authentication-strategy-factory-hook-result";
import { AuthenticationStrategyResult } from "utilities/services/auth/authentication-strategy-result";
import {
    Configuration,
    IPublicClientApplication,
    ServerResponseType,
} from "@azure/msal-browser";
import { MsalFactory } from "utilities/services/auth/msal-factory";
import { MultiTenantAuthenticationStrategy } from "./multi-tenant-authentication-strategy";
import { MultiTenantAuthenticationWrapperComponent } from "utilities/services/auth/azure-strategies/multi-tenant-authentication/multi-tenant-authentication-wrapper-component";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";
import React from "react";
import { useCurrentIdentityProvider } from "utilities/atoms/current-identity-provider-atom";
import IdentityProvider from "models/interfaces/identity-provider";

export function useMultiTenantAuthenticationStrategy(): AuthenticationStrategyFactoryHookResult {
    const { globalState } = useGlobalState();
    const currentCulture = globalState.getPreferredOrCurrentCulture();
    const isAuthenticated = useMemo(
        () => globalState.isAuthenticated(),
        [globalState]
    );
    const { showSSODebugLogs, useSsoSilent } = useFeatureFlags();
    const { callbackUrl } = useUrlCallback();

    //const [currentIdentityProvider, setCurrentIdentityProvider] = useAtom(
    //    CurrentIdentityProviderAtom
    //);
    const { currentIdentityProvider } = useCurrentIdentityProvider();

    const buildMsalConfiguration = (identityProvider: IdentityProvider) => {
        const serverResponseType =
            identityProvider.serverResponseType ?? ServerResponseType.FRAGMENT;

        const azureAuthority = `https://${identityProvider.domain}/${identityProvider.tenant}.onmicrosoft.com/${identityProvider.policy}`;
        const authority =
            identityProvider.overrideAuthority == null
                ? azureAuthority
                : identityProvider.overrideAuthority;

        const config: Configuration = {
            auth: {
                clientId: identityProvider.clientId,
                redirectUri: `${window.location.origin}/multitenant`,
                authority: authority,
                knownAuthorities: [identityProvider.domain],
                navigateToLoginRequestUrl: true,
                OIDCOptions: {
                    serverResponseType: serverResponseType,
                },
            },
        };

        return config;
    };

    const authStrategyFactory =
        async (): Promise<AuthenticationStrategyResult> => {
            const msalFactory = new MsalFactory();
            if (showSSODebugLogs) {
                console.log(
                    "*** Current Identity Provider:",
                    currentIdentityProvider
                );
            }

            const config = buildMsalConfiguration(currentIdentityProvider!);

            if (showSSODebugLogs) {
                console.log("*** CONFIG:", config);
            }

            const instance = await msalFactory.getOrCreateMsalInstance(config);
            /*
            instance.handleRedirectPromise().then(async (result) => {
                console.log("In handleRedirectPromise");
                const organizationIdClaim = result?.account?.idTokenClaims
                    ?.OrganizationId as string;
                if (organizationIdClaim == null) return;
                const organizationId = parseInt(organizationIdClaim.toString());
                if (organizationId == null) {
                    console.log("Error getting organization ID");
                    return;
                }
                //Look up the provider by the id:
                const identityProviderResult =
                    await IdentityProvidersService.get({
                        organizationId: organizationId,
                    });
                //to do:  Check for errors
                setCurrentIdentityProvider(identityProviderResult.resultObject);
            });
            */

            const azureAuthenticationStrategy =
                new MultiTenantAuthenticationStrategy(
                    isAuthenticated,
                    useSsoSilent,
                    currentCulture,
                    callbackUrl ?? "",
                    instance,
                    config,
                    currentIdentityProvider!.overrideTokenProperty,
                    currentIdentityProvider!.organizationId,
                    globalState.currentIdentity?.userLogin,
                    showSSODebugLogs
                );

            const authStrategyResult: AuthenticationStrategyResult = {
                authStrategy: azureAuthenticationStrategy,
                getAuthWrapperComponent: () => getWrapperComponent(instance),
            };
            return authStrategyResult;
        };
    const create = useCallback(authStrategyFactory, [
        callbackUrl,
        currentCulture,
        currentIdentityProvider,
        globalState.currentIdentity?.userLogin,
        isAuthenticated,
        showSSODebugLogs,
        useSsoSilent,
    ]);

    const getWrapperComponent = (instance: IPublicClientApplication) => {
        const WrapperComponent: React.FC<PropsWithChildren> = ({
            children,
        }) => {
            return (
                <MultiTenantAuthenticationWrapperComponent instance={instance}>
                    {children}
                </MultiTenantAuthenticationWrapperComponent>
            );
        };
        return WrapperComponent;
    };

    return { create };
}
