import { ServiceResponse } from "@rsm-hcd/javascript-core";
import { ServiceFactory as RsmServiceFactory } from "@rsm-hcd/javascript-react";
import axios, { AxiosError } from "axios";
import _ from "lodash";
import { PromiseUtils } from "utilities/promise-utils";
import { RouteUtils } from "utilities/route-utils";
import ServiceUtils from "utilities/service-utils";
import ServiceResponseFactory from "utilities/services/service-response-factory";
import { CollectionUtils } from "./../collection-utils";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type RecordId = string | number | null | undefined;

export interface RecordType {
    id?: RecordId;
    toJS: () => unknown;
}

/**
 * Type defining the service function to bulk creating the supplied resource type
 */
export type BulkCreateService<TRecord> = (
    records?: Array<TRecord>
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for bulk updating the supplied resource type
 */
export type BulkUpdateService<TRecord, TPathParams> = (
    records: Array<TRecord>,
    pathParams: TPathParams
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for creating the supplied resource type
 */
export type CreateService<TRecord> = (
    record?: TRecord
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for deleting the supplied resource
 */
export type DeleteService = (
    id: number,
    pathParams?: any
) => Promise<ServiceResponse<Boolean>>;

// TODO: Move to open source.
// See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.
/**
 * Type defining the service function for batch listing resources by supplied type
 */
export type BatchedListService<TRecord, TQueryParams> = (
    queryParams: TQueryParams,
    property: keyof TQueryParams,
    size: number
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for listing resources by supplied type
 *
 * @param queryParams Object with query parameters to be appended to the endpoint route
 * @param signal AbortSignal to be used for aborting the request
 */
export type BatchedListServiceWithSignal<TRecord, TQueryParams> = (
    queryParams: TQueryParams,
    property: keyof TQueryParams,
    size: number,
    signal?: AbortSignal
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for listing resources by supplied type
 */
export type ListService<TRecord, TQueryParams> = (
    queryParams?: TQueryParams
) => Promise<ServiceResponse<TRecord>>;

// TODO: Move to open source.
// See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.
/**
 * Type defining the service function for batch listing nested resources by supplied type
 */
export type NestedBatchedListService<TRecord, TPathParams, TQueryParams> = (
    pathParams: TPathParams,
    queryParams: TQueryParams,
    property: keyof TQueryParams,
    size: number
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for creating the supplied resource type when resource is nested
 */
export type NestedCreateService<TRecord, TPathParams> = (
    record: TRecord,
    pathParams: TPathParams
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for listing resources by supplied type when resource is nested
 */
export type NestedListService<TRecord, TPathParams, TQueryParams> = (
    pathParams: TPathParams,
    queryParams?: TQueryParams
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for updating the supplied resource type
 */
export type UpdateService<TRecord, TPathParams, TQueryParams = undefined> = (
    record: TRecord,
    pathParams?: TPathParams,
    queryParams?: TQueryParams
) => Promise<ServiceResponse<TRecord>>;

/**
 * Type defining the service function for updating the supplied resource type
 *
 * @param record Record to be created
 * @param pathParams Object with path parameters to be replaced in the endpoint route
 * @param queryParams Object with query parameters to be added to the endpoint route
 * @param signal AbortSignal to be used for aborting the request
 */
export type UpdateServiceWithSignal<
    TRecord,
    TPathParams,
    TQueryParams = undefined,
> = (
    record: TRecord,
    pathParams?: TPathParams,
    queryParams?: TQueryParams,
    signal?: AbortSignal
) => Promise<ServiceResponse<TRecord>>;

// #endregion Types

// ---------------------------------------------------------------------------------------------
// #region Public Functions
// ---------------------------------------------------------------------------------------------

/**
 * Factory to encapsulate common service function logic
 */
const ServiceFactory = {
    ...RsmServiceFactory,

    /**
     * Creates Bulk Service Create function for the supplied resource type
     *
     * @template TRecord
     * @param recordType
     * @param baseEndpoint
     * @returns
     */
    bulkCreate: <TRecord extends RecordType>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): BulkCreateService<TRecord> => {
        return async (records?: Array<TRecord>) =>
            await _bulkCreate<TRecord>(recordType, baseEndpoint, records);
    },

    // TODO: Move to open source.
    // See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.
    /**
     * Creates batch Service List function for the supplied resource type
     *
     * ### Recommendation
     * Use `nestedList` when route is nested!
     *
     * @param recordType
     * @param baseEndpoint
     * @param property array property for which to batch
     * @param size maximum size of each batched request
     */
    batchedList: <TRecord, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): BatchedListServiceWithSignal<TRecord, TQueryParams> => {
        return async (
            queryParams: TQueryParams,
            property: keyof TQueryParams,
            size: number,
            signal?: AbortSignal
        ) => {
            return _batchedList(
                recordType,
                baseEndpoint,
                property,
                size,
                queryParams,
                signal
            );
        };
    },

    /**
     * Creates conventional Service Delete function for the supplied resource type
     * @param recordType
     * @param resourceEndpoint
     */
    delete<TPathParams extends any>(resourceEndpoint: string): DeleteService {
        return async (id: number, pathParams?: TPathParams) =>
            await _delete<TPathParams>(id, resourceEndpoint, pathParams);
    },

    // TODO: Move to open source.
    // See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.
    /**
     * Creates batched Service List function for the supplied resource type
     * when the resource is nested
     *
     * @param recordType
     * @param baseEndpoint
     * @param property array property for which to batch
     * @param size maximum size of each batched request
     */
    nestedBatchedList: <TRecord, TPathParams, TQueryParams>(
        recordType: { new (): TRecord },
        baseEndpoint: string
    ): NestedBatchedListService<TRecord, TPathParams, TQueryParams> => {
        return async (
            pathParams: TPathParams,
            queryParams: TQueryParams,
            property: keyof TQueryParams,
            size: number
        ) => {
            return _batchedList(
                recordType,
                baseEndpoint,
                property,
                size,
                queryParams,
                pathParams
            );
        };
    },

    /**
     * Creates conventional Service Update function for the supplied resource type
     *
     * Should be swapped with the @rsm-hcd/javascript-react version when https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/46 is complete
     *
     * @param recordType
     * @param resourceEndpoint
     */
    update<
        TRecord extends RecordType,
        TPathParams extends any,
        TQueryParams = undefined,
    >(
        recordType: { new (): TRecord },
        resourceEndpoint: string
    ): UpdateServiceWithSignal<TRecord, TPathParams, TQueryParams> {
        return async (
            record: TRecord,
            pathParams?: TPathParams,
            queryParams?: TQueryParams,
            signal?: AbortSignal
        ) =>
            await _update<TRecord, TPathParams, TQueryParams>(
                recordType,
                record,
                resourceEndpoint,
                pathParams,
                queryParams,
                signal
            );
    },
};

// #endregion Public Functions

// ---------------------------------------------------------------------------------------------
// #region Private Functions
// ---------------------------------------------------------------------------------------------

const _bulkCreate = async function <TRecord extends RecordType>(
    recordType: { new (): TRecord },
    url: string,
    records?: Array<TRecord>
) {
    const requestData =
        records != null ? records.map((record) => record.toJS()) : null;

    return await axios
        .post(url, requestData)
        .then((r) => ServiceUtils.mapPagedAxiosResponse(recordType, r));
};

// TODO: Move to open source.
// See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.
const _batchedList = async function <
    TRecord,
    TQueryParams,
    TPathParams = undefined,
>(
    recordType: { new (): TRecord },
    baseEndpoint: string,
    property: keyof TQueryParams,
    size: number,
    queryParams: TQueryParams,
    pathParams?: TPathParams,
    signal?: AbortSignal
) {
    const values = queryParams[property];
    if (!(values instanceof Array) || CollectionUtils.isEmpty(values)) {
        return ServiceResponseFactory.successfulList<TRecord>();
    }

    // TODO: Swap for open source `chunk` method.
    // See https://github.com/AndcultureCode/AndcultureCode.JavaScript.Core/issues/86 for more information.
    const batches = _.chunk(values, size);

    const requests = batches.map((batchValues) => {
        const batchedParams = Object.assign({}, queryParams);
        batchedParams[property] = batchValues as any;

        // If this code is added to our open source efforts, then the calls to `nestedList`
        // and `list` can be removed to use the `_list` private method in
        // `AndcultureCodeServiceFactory`.
        if (pathParams != null) {
            return RsmServiceFactory.nestedList<
                TRecord,
                TPathParams,
                TQueryParams
            >(recordType, baseEndpoint)(pathParams, batchedParams, signal);
        }

        return RsmServiceFactory.list<TRecord, TQueryParams>(
            recordType,
            baseEndpoint
        )(batchedParams, signal);
    }) as Promise<ServiceResponse<TRecord>>[];

    const results = await PromiseUtils.toArray(requests);
    return ServiceResponseFactory.successfulList(results, values.length);
};

const _buildUrl = (
    id: RecordId,
    resourceEndpoint: string,
    pathParams?: any,
    queryParams?: any
) => {
    if (pathParams == null) {
        pathParams = {};
    }
    pathParams = Object.assign(pathParams, { id });
    return RouteUtils.getUrl(resourceEndpoint, pathParams, queryParams);
};

const _delete = async function <TPathParams extends any>(
    id: number,
    resourceEndpoint: string,
    pathParams?: TPathParams
) {
    const url = _buildUrl(id, resourceEndpoint, pathParams);
    return await axios
        .delete(url)
        .then((r) => ServiceUtils.mapAxiosResponse(Boolean, r))
        .catch((e: AxiosError) => {
            return { status: 401, resultObjects: [], rowCount: 0 };
        });
};

const _update = async function <
    TRecord extends RecordType,
    TPathParams extends any,
    TQueryParams extends any = any,
>(
    recordType: { new (): TRecord },
    record: TRecord,
    resourceEndpoint: string,
    pathParams?: TPathParams,
    queryParams?: TQueryParams,
    signal?: AbortSignal
) {
    const url = _buildUrl(record.id, resourceEndpoint, pathParams, queryParams);
    return await axios
        .put(url, record.toJS(), { signal })
        .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
};

// #endregion Private Functions

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ServiceFactory;

// #endregion Exports
