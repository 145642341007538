import "assets/scss/app.scss";
import "lazysizes";
import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AnnouncementContextProvider from "utilities/contexts/announcement-context-provider";
import { ToastDefaultPosition } from "utilities/toast/toast-manager";
import AppRoutes from "./app-routes";

import UserConfigurationRedirects from "./utilities/routing/user-configuration-redirects";
import SystemIssuesBanner from "organisms/banners/system-issues-banner";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { AuthenticationLifeCycleStatus } from "utilities/enumerations/authorization";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { t } from "utilities/localization-utils";

// At this point the authentication strategy has been selected and authentication status is available.
// Show a loader if it's in progress, otherwise show the app
const AuthenticatedApp = () => {
    const { authenticationLifeCycleStatus } = useAuthentication();

    return (
        <>
            {authenticationLifeCycleStatus ===
                AuthenticationLifeCycleStatus.Loading ||
            authenticationLifeCycleStatus ===
                AuthenticationLifeCycleStatus.Uninitialized ? (
                <FullScreenTransition transitionText={t("loadingSession")} />
            ) : (
                <AnnouncementContextProvider>
                    <SystemIssuesBanner />
                    <UserConfigurationRedirects />
                    <AppRoutes />
                    <ToastContainer
                        draggable={false}
                        position={ToastDefaultPosition}
                        autoClose={5000}
                        closeOnClick={true}
                        closeButton={false}
                        transition={Zoom}
                        toastClassName="c-toast"
                    />
                </AnnouncementContextProvider>
            )}
        </>
    );
};

export default AuthenticatedApp;
