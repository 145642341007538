import React from "react";

interface StepperStepProps {
    step: string;
    number: number;
    isActive: boolean;
}

const BASE_CLASS = "c-stepper";

const StepperStep: React.FC<StepperStepProps> = ({
    step,
    number,
    isActive,
}) => {
    return (
        <div className={`${BASE_CLASS}__step ${isActive ? "-active" : ""}`}>
            <div className={`${BASE_CLASS}__step__number`}>{number}</div>
            <div>{step}</div>
        </div>
    );
};

export default StepperStep;
