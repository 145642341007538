import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import { useLocalization } from "utilities/hooks/use-localization";

const BASE_CLASS = "c-contact-us-message";

const ContactUsMessage = () => {
    const { customerSupportUrl } = useNfpaUrlPaths();
    const { t } = useLocalization();

    return (
        <div className="contact-us-message">
            <Paragraph cssClassName="contact-us-message__description">
                {t("ifYouHaveAnyIssues")},{" "}
                <Anchor
                    cssClassName={`${BASE_CLASS}__anchor`}
                    external
                    target={AnchorTargetTypes.Blank}
                    to={customerSupportUrl}>
                    {t("contactUs").toLowerCase()}
                </Anchor>{" "}
                {t("forHelp").toLowerCase()}.
            </Paragraph>
        </div>
    );
};

export default ContactUsMessage;
