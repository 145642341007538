export const XmlChangeNotationConstants = {
    NEW_MATERIAL: "addition",
    CHANGED: "changed",
    DELETION: "deletion",
    NO_CHANGES: "no",
    HAS_CHANGES: "yes",
    MOVED: "moved",
    MOVED_WITH_CHANGES: "moved-with-changes",
    TIA_HAS_CHANGES: "yes",
    TIA_NO_CHANGES: "no",
    TIA_DELETION: "deletion",
};
