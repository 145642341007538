import { DataTestAttributes } from "interfaces/data-test-attributes";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import ExpandableMetaNavCollapsePanel, {
    ExpandableMetaNavLevels,
} from "./expandable-meta-nav-collapse-panel";
import ExpandableMetaNavSections from "./expandable-meta-nav-sections";
import { useLocation, useParams } from "react-router-dom";
import {
    AnnexParams,
    PublicationParams,
} from "interfaces/routing/route-params";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import {
    getAnnexIdFromRoute,
    getAnnexNfpaLabelFromRoute,
    getGroupByIdFromRoute,
} from "utilities/bookview-utils";
import ExpandableMetaNavAnnexGroup from "./expandable-meta-nav-annex-group";

interface ExpandableMetaNavAnnexesProps
    extends Pick<DataTestAttributes, "dataTestId"> {
    annexes: AnnexTableOfContentsRecord[];
    locale?: string;
}

const ExpandableMetaNavAnnexes: React.FunctionComponent<
    ExpandableMetaNavAnnexesProps
> = (props: ExpandableMetaNavAnnexesProps) => {
    const { annexes, locale } = props;

    const params = useParams<PublicationParams>();
    const { pathname } = useLocation();

    const { isAdminPreview } = useAdminPreview();

    const sortedAnnexes = annexes.sort(
        (a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0)
    );

    const currentAnnexInRoute = isAdminPreview
        ? getAnnexIdFromRoute(pathname)
        : getAnnexNfpaLabelFromRoute(pathname);

    const currentGroupById = getGroupByIdFromRoute(pathname);

    return (
        <>
            {sortedAnnexes.map((annex) => {
                const isCurrentActivePanel =
                    currentAnnexInRoute === annex.nfpaLabel ||
                    currentAnnexInRoute === annex.id;

                const hasGroups = CollectionUtils.hasValues(annex.annexGroups);
                const displaySections = annex.sections?.filter(
                    (s) => !s.isDeleted
                );
                const hasSections =
                    !hasGroups && CollectionUtils.hasValues(displaySections);

                const annexParams: AnnexParams = {
                    code: params.code,
                    edition: params.edition,
                    nfpaLabel: annex.nfpaLabel ?? "",
                    publicationId: params.id,
                    id: annex.id?.toString() ?? "",
                };

                return (
                    <ExpandableMetaNavCollapsePanel
                        key={annex.id}
                        isCurrentActivePanel={isCurrentActivePanel}
                        level={ExpandableMetaNavLevels.Parent}
                        headingText={annex.getDisplayTitle(locale) ?? ""}
                        headingRoute={annex.getRoute(
                            annexParams,
                            isAdminPreview
                        )}>
                        {hasGroups &&
                            annex.annexGroups.map((annexGroup, idx) => (
                                <ExpandableMetaNavAnnexGroup
                                    annexId={annex.id?.toString() ?? ""}
                                    annexGroup={annexGroup}
                                    annexParams={annexParams}
                                    currentGroupById={currentGroupById}
                                    key={`${annex.id}-${idx}`}
                                />
                            ))}
                        {hasSections && (
                            <ExpandableMetaNavSections
                                sections={displaySections}
                                isAdminPreview={isAdminPreview}
                                isCurrentActivePanel={isCurrentActivePanel}
                                parentType={SectionParentType.Annex}
                                routeParams={annexParams}
                            />
                        )}
                    </ExpandableMetaNavCollapsePanel>
                );
            })}
        </>
    );
};

export default ExpandableMetaNavAnnexes;
