import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { ClassNameConstants } from "constants/classname-constants";
import PublicationTypes from "constants/publication-types";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import PublicationRecord from "models/view-models/publication-record";
import EnhancedContentPanel from "organisms/enhanced-content/enhanced-content-panel";
import IndicatorIcons from "organisms/indicator-buttons/indicator-icons";
import ChangeIndicatorDiffPanel from "organisms/panels/change-indicator-diff/change-indicator-diff-panel";
import TiaBookviewHeading from "organisms/panels/tia-changes/tia-bookview-heading";
import TiaChangesPanel from "organisms/panels/tia-changes/tia-changes-panel";
import PublicationComponentActions from "organisms/publication-component-actions/publication-component-actions";
import { VirtualListRowProps } from "organisms/virtual-list/virtual-list";
import React, {
    forwardRef,
    ReactNode,
    Ref,
    useCallback,
    useState,
} from "react";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import CaapsRecordUtils from "utilities/caaps-record-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useComponentHeightChange from "utilities/hooks/use-component-height-change";
import CultureResources from "utilities/interfaces/culture-resources";
import EnhancedContentServiceFactory from "utilities/services/enhanced-content/enhanced-content-service-factory";

interface CaapsItemTitleAreaProps extends VirtualListRowProps {
    titleClassName: string;
    record: ChapterRecord | ArticleRecord | AnnexRecord;
    publication: PublicationRecord;
    publicationComponentType: PublicationComponentType;
    changes?: string;
    priorLocation?: string;
    doesHaveTitleOrBodyChanges: boolean;
    externalId: string;
    children?: ReactNode;
}

const CSS_BASE_CLASS = "c-publication-page-layout__content";

const CaapsItemTitleArea = forwardRef<HTMLElement, CaapsItemTitleAreaProps>(
    (props: CaapsItemTitleAreaProps, ref: Ref<HTMLElement>) => {
        const {
            titleClassName,
            record,
            publication,
            changes,
            priorLocation,
            doesHaveTitleOrBodyChanges,
            onHeightChanged,
            externalId,
            publicationComponentType,
            children,
        } = props;

        // -------------------------------------------------------------------------------------------------
        // #region State
        // -------------------------------------------------------------------------------------------------
        const { globalState } = useGlobalState();
        const { currentIdentity } = globalState;

        const [showEnhancedContentModal, setShowEnhancedContentModal] =
            useState(false);

        const [
            enhancedContentPanelCollapsed,
            setEnhancedContentPanelCollapsed,
        ] = useState(true);

        const caapsTypeAsString: keyof CultureResources =
            CaapsRecordUtils().getCaapsTypeString(publicationComponentType);

        // #endregion State

        // -------------------------------------------------------------------------------------------------
        // #region Enhanced Content
        // -------------------------------------------------------------------------------------------------
        const canAddOrEditEnhancedContent =
            currentIdentity?.isAdmin() ||
            currentIdentity?.isAuthorOrPublisher();

        const { delete: deleteCaapsItemEnhancedContent } =
            EnhancedContentServiceFactory.useDelete(publicationComponentType);

        const {
            enhancedContentForCurrentCaapsItem,
            enhancedContentResourcesForCurrentCaapsItem,
        } = useEnhancedContent({
            [`${caapsTypeAsString}NfpaLabel`]: record.nfpaLabel,
            externalId: externalId,
        });

        const handleEnhancedContentEditClick = useCallback(
            () => setShowEnhancedContentModal(true),
            [setShowEnhancedContentModal]
        );

        const handleEnhancedContentDelete = useCallback(
            (enhancedContentForCurrentCaapsItem) =>
                deleteCaapsItemEnhancedContent(
                    enhancedContentForCurrentCaapsItem.id!,
                    { [`${caapsTypeAsString}Id`]: record.id }
                ),
            [deleteCaapsItemEnhancedContent, caapsTypeAsString, record]
        );
        // #endregion Enhanced Content

        // -------------------------------------------------------------------------------------------------
        // #region Change Indicators and TIAs
        // -------------------------------------------------------------------------------------------------
        const {
            showDiffPanel,
            handleOpen,
            content: diffPanelContent,
        } = useChangeIndicatorDiffPanel();

        const {
            currentTia,
            isTiaPanelOpen,
            isTiaListPanelOpen,
            tias,
            handleClose: handleTiaPanelClose,
            currentExternalId: tiaExternalId,
            handleTiaClick,
            handleTiaListPanelOpen,
        } = useTias(externalId);

        const handleTitleTiaClick = () =>
            handleTiaListPanelOpen(
                record.id,
                record.externalId,
                publicationComponentType
            );

        const showChangesModifier = `${
            isTiaPanelOpen || showDiffPanel ? "-show-changes" : ""
        }`;

        const hasTiaOpen = externalId === tiaExternalId;

        const highlightSectionModifier =
            externalId === diffPanelContent.externalId || hasTiaOpen
                ? "-highlight-section"
                : "";
        // #endregion Change Indicators and TIAs

        // -------------------------------------------------------------------------------------------------
        // #region Text Elements
        // -------------------------------------------------------------------------------------------------

        // Determine type-specific title structure for regular bookview title and TIA panel title
        let titleText: string | ReactNode, tiaTitle: string | ReactNode;

        switch (publicationComponentType) {
            // Annex A - Title Here
            case PublicationComponentType.Annex:
                titleText = `${record.getDisplayLabel()} ${
                    UnicodeCharacterConstants.EmDash
                } ${record.getDisplayTitle()}`;
                tiaTitle = (
                    <>
                        {record.getDisplayLabel()}
                        &nbsp;{UnicodeCharacterConstants.EmDash}
                        &nbsp;
                        {currentTia.getTitle()}
                    </>
                );
                break;
            // Article 200 Title Here
            case PublicationComponentType.Article:
                titleText = `${record.getDisplayLabel()} ${record.getTitle()}`;
                tiaTitle = (
                    <>
                        {record.getDisplayLabel()}
                        &nbsp;
                        {currentTia.getTitle()}
                    </>
                );
                break;
            // Just a Title Here (used in chapters)
            default:
                titleText = record.getTitle();
                tiaTitle = currentTia.getTitle();
        }

        const renderTitle = (title: string | ReactNode) => (
            <section
                className={`${CSS_BASE_CLASS}__header -${caapsTypeAsString} -title`}
                ref={ref}
                tabIndex={-1}>
                <Heading
                    cssClassName={`${CSS_BASE_CLASS}__title`}
                    priority={HeadingPriority.Two}>
                    {title}
                </Heading>
            </section>
        );

        const tiaBody = (
            <div className={`${CSS_BASE_CLASS}__body`}>
                {currentTia.getBody()}
            </div>
        );

        const handleDiffPanelOpen = () => {
            handleOpen({
                externalId: externalId,
                titleClassName: titleClassName,
                title: renderTitle(titleText),
                body: "",
                changes: changes,
                priorLocation: priorLocation,
                label: record.getDisplayLabel(),
                contentType: publicationComponentType,
            });
        };

        // #endregion Text Elements

        // -------------------------------------------------------------------------------------------------
        // #region Style
        // -------------------------------------------------------------------------------------------------

        const canHover =
            (canAddOrEditEnhancedContent || doesHaveTitleOrBodyChanges) &&
            publication.type !== PublicationTypes.NEC;
        const hoverClassName = canHover ? ClassNameConstants.Hover : "";

        useComponentHeightChange(ref, 0, onHeightChanged, [
            enhancedContentPanelCollapsed,
            enhancedContentForCurrentCaapsItem,
        ]);
        // #endregion Hover and CSS Modifiers

        return (
            <section
                className={`${CSS_BASE_CLASS}__header ${showChangesModifier} -${caapsTypeAsString}`}
                tabIndex={-1}
                ref={ref}>
                <div
                    className={`${CSS_BASE_CLASS}__header__main ${hoverClassName} ${highlightSectionModifier}`}>
                    {(hasTiaOpen || isTiaListPanelOpen) && (
                        <TiaBookviewHeading tias={tias} />
                    )}
                    <div className={`${CSS_BASE_CLASS}__header__main__title`}>
                        {doesHaveTitleOrBodyChanges && (
                            <IndicatorIcons
                                tias={tias}
                                changes={changes}
                                handleDiffPanelOpen={handleDiffPanelOpen}
                                handleTiaClick={handleTitleTiaClick}
                            />
                        )}
                        {renderTitle(titleText)}
                        <PublicationComponentActions
                            code={publication.code}
                            edition={publication.edition}
                            enhancedContent={
                                enhancedContentForCurrentCaapsItem ?? undefined
                            }
                            handleTiaClick={handleTiaClick}
                            onToggleEnhancedContentModal={
                                setShowEnhancedContentModal
                            }
                            record={record}
                            recordLabel={caapsTypeAsString}
                            resources={
                                enhancedContentResourcesForCurrentCaapsItem
                            }
                            showEnhancedContentModal={showEnhancedContentModal}
                            tias={tias}
                            type={publicationComponentType}
                            visible={canHover}
                            handleDiffPanelOpen={handleDiffPanelOpen}
                        />
                    </div>
                    {enhancedContentForCurrentCaapsItem != null && (
                        <EnhancedContentPanel
                            enhancedContent={enhancedContentForCurrentCaapsItem}
                            onDeleteConfirm={handleEnhancedContentDelete}
                            onEditClick={handleEnhancedContentEditClick}
                            onToggle={setEnhancedContentPanelCollapsed}
                            resources={
                                enhancedContentResourcesForCurrentCaapsItem
                            }
                        />
                    )}
                    {children}
                </div>
                {diffPanelContent.externalId === externalId && (
                    <ChangeIndicatorDiffPanel />
                )}
                {externalId === tiaExternalId && (
                    <div>
                        <TiaChangesPanel
                            tias={tias}
                            handleClose={handleTiaPanelClose}
                            title={renderTitle(tiaTitle)}
                            body={tiaBody}
                        />
                    </div>
                )}
            </section>
        );
    }
);

export default CaapsItemTitleArea;
