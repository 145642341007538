import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StringUtils from "utilities/string-utils";

const BASE_CSS_CLASS = "c-profile-avatar";

const ProfileAvatar = () => {
    const { globalState } = useGlobalState();
    const initials = globalState.currentIdentity?.user?.getInitials();

    return (
        <div className={BASE_CSS_CLASS}>
            {StringUtils.isEmpty(initials) ? (
                <Icon type={Icons.DefaultAvatar} />
            ) : (
                <>{initials}</>
            )}
        </div>
    );
};

export default ProfileAvatar;
