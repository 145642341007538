import { useEffect, useState, useCallback } from "react";
import ExternalTopicService from "utilities/services/topics/external-topic-service";
import usePageErrors from "utilities/hooks/use-page-errors";
import useTopics from "utilities/hooks/domain/topics/use-topics";

const useSelectedTopics = () => {
    const [selectedTopicIds, setSelectedTopics] = useState<number[]>([]);
    const [externalTopicsLoaded, setExternalTopicsLoaded] =
        useState<boolean>(false);
    const [topicsUpdated, setTopicsUpdated] = useState<boolean>(false);
    const { loading, loaded, resultObject: topics } = useTopics();
    const { handlePageLoadError, pageErrors } = usePageErrors();
    const getExternalTopicsApi = ExternalTopicService.list;

    useEffect(() => {
        if (externalTopicsLoaded) {
            return;
        }

        const getUserTopics = async () => {
            try {
                const response = await getExternalTopicsApi();
                const externalTopics = response.resultObjects || [];
                setSelectedTopics(externalTopics.map((u) => u.id));
                setExternalTopicsLoaded(true);
            } catch (result) {
                handlePageLoadError(result);
            }
        };

        getUserTopics();
    }, [
        getExternalTopicsApi,
        externalTopicsLoaded,
        handlePageLoadError,
        pageErrors,
    ]);

    const updateUserTopics = async () => {
        try {
            const selectedTopics = topics.filter(
                (x) => x.id && selectedTopicIds.includes(x.id)
            );

            await ExternalTopicService.updateSelected(selectedTopics);
            setTopicsUpdated(true);
            return true;
        } catch (error) {
            handlePageLoadError(error);
            return false;
        }
    };

    const handleCheck = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, identifier?: number) => {
            const topicId = identifier ?? parseInt(e.currentTarget.value, 10);
            setSelectedTopics((prevSelected) =>
                prevSelected.includes(topicId)
                    ? prevSelected.filter((id) => id !== topicId)
                    : [...prevSelected, topicId]
            );
        },
        []
    );

    return {
        selectedTopicIds,
        updateUserTopics,
        handleCheck,
        loading,
        loaded,
        topics,
        topicsUpdated,
    };
};

export default useSelectedTopics;
