export enum Icons {
    Book = "Book",
    Bookmark = "Bookmark",
    BookmarkOutline = "BookmarkOutline",
    Campaign = "Campaign",
    ChangeIndicatorInfo = "ChangeIndicatorInfo",
    ChangeIndicatorText = "ChangeIndicatorText",
    Checkmark = "Checkmark",
    CheckmarkOvalFilled = "CheckmarkOvalFilled",
    CheckmarkOvalOutline = "CheckmarkOvalOutline",
    ChevronDown = "ChevronDown",
    ChevronFirst = "ChevronFirst",
    ChevronLast = "ChevronLast",
    ChevronLeft = "ChevronLeft",
    ChevronRight = "ChevronRight",
    ChevronUp = "ChevronUp",
    Close = "Close",
    CloseExpansion = "CloseExpansion",
    Collapse = "Collapse",
    Copy = "Copy",
    DefaultAvatar = "DefaultAvatar",
    Delta = "Delta",
    Document = "Document",
    Dot = "Dot",
    Download = "Download",
    DownloadComplete = "DownloadComplete",
    DownloadRemove = "DownloadRemove",
    DownloadSync = "DownloadSync",
    DragAndDrop = "DragAndDrop",
    Edit = "Edit",
    Enlarge = "Enlarge",
    Enterprise = "Enterprise",
    Expand = "Expand",
    Eye = "Eye",
    Folder = "Folder",
    Home = "Home",
    Individual = "Individual",
    Information = "Information",
    Lightbulb = "Lightbulb",
    List = "List",
    Logo = "Logo",
    MediaPlay = "MediaPlay",
    MediaPlayCircle = "MediaPlayCircle",
    Menu = "Menu",
    MetaPanel = "MetaPanel",
    MoreHorizontal = "MoreHorizontal",
    MoreVertical = "MoreVertical",
    NewMaterial = "NewMaterial",
    Note = "Note",
    NoteColorable = "NoteColorable",
    Moved = "Moved",
    Pin = "Pin",
    Placeholder = "Placeholder",
    Plus = "Plus",
    Preferences = "Preferences",
    Question = "Question",
    ReferenceLink = "ReferenceLink",
    ReferencePanel = "ReferencePanel",
    Scroll = "Scroll",
    Search = "Search",
    Settings = "Settings",
    Share = "Share",
    Sitnav = "Sitnav",
    StarFilled = "StarFilled",
    StarOutline = "StarOutline",
    Stopwatch = "Stopwatch",
    Team = "Team",
    TiaChange = "TiaChange",
    TiaAdd = "TiaAdd",
    TiaDelete = "TiaDelete",
    Trashcan = "Trashcan",
    Warning = "Warning",
    WifiOffline = "WifiOffline",
    ToggleCard = "ToggleCard",
    ToggleTable = "ToggleTable",
    ZoomIn = "ZoomIn",
    ZoomOut = "ZoomOut",
}
