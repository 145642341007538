import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { atom, useAtom } from "jotai";
import MenuButton from "molecules/menu-button/menu-button";
import React, { useEffect, useRef } from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ChangeIndicatorLegendProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-change-indicator-legend";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------
export const isOpenAtom = atom(false);

const ChangeIndicatorLegend: React.FC<ChangeIndicatorLegendProps> = (
    props: ChangeIndicatorLegendProps
) => {
    const { t } = useLocalization();
    const { showMovedOnChangeIndicatorMenu } = useFeatureFlags();

    const [isOpen, setIsOpen] = useAtom(isOpenAtom);

    const title = t("changeIndicatorLegend");
    const cssClassName = "c-change-indicator-legend";

    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current && isOpen) ref.current.focus();
    }, [isOpen]);

    return (
        <>
            <div className={BASE_CLASS_NAME}>
                <MenuButton
                    cssClassName={cssClassName}
                    buttonAccessibleText={title}
                    externalIsOpen={isOpen}
                    externalSetIsOpen={setIsOpen}
                    icon={Icons.ChangeIndicatorInfo}
                    iconSize={IconSizes.Base}
                    modalClassName={BASE_CLASS_NAME}
                    offlineAlertOptions={{
                        enabled: true,
                        tooltipDescription: t("changeIndicatorLegend"),
                        tooltipHeader: t("changeIndicatorLegend"),
                    }}
                    title={title}
                    triggerButtonStyle={ButtonStyles.TertiaryAlt}
                    triggerButtonSize={ButtonSizes.XSmall}>
                    <div className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__text-display`}>
                            <Icon
                                type={Icons.ChangeIndicatorText}
                                size={IconSizes.Large}
                            />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__menu-text`}>
                            {t("changeIndicatorLegend-Revisions")}
                        </div>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__icon-display`}>
                            <Icon type={Icons.TiaChange} />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__menu-text`}>
                            {t("changeIndicatorLegend-TIA")}
                        </div>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__icon-display`}>
                            <Icon type={Icons.NewMaterial} />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__menu-text`}>
                            {t("changeIndicatorLegend-NewMaterial")}
                        </div>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__icon-display`}>
                            <Icon type={Icons.Delta} />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__menu-text`}>
                            {t("changeIndicatorLegend-Delta")}
                        </div>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__icon-display`}>
                            <Icon type={Icons.Dot} />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__menu-text`}>
                            {t("changeIndicatorLegend-SectionDeletions")}
                        </div>
                    </div>
                    {showMovedOnChangeIndicatorMenu && (
                        <div
                            className={`${BASE_CLASS_NAME}__menu-item-wrapper`}>
                            <div className={`${BASE_CLASS_NAME}__icon-display`}>
                                <Icon type={Icons.Moved} />
                            </div>
                            <div className={`${BASE_CLASS_NAME}__menu-text`}>
                                {t("changeIndicatorLegend-Moved")}
                            </div>
                        </div>
                    )}
                </MenuButton>
            </div>
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ChangeIndicatorLegend;

// #endregion Exports
