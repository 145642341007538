import ChangeIndicatorDiffContent from "./change-indicator-diff-content";
import Modal from "molecules/modals/modal";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import React from "react";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { StringUtils } from "@rsm-hcd/javascript-core";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { useLocalization } from "utilities/hooks/use-localization";

export interface ChangeIndicatorDiffModalProps {
    isTable?: boolean;
    isPart?: boolean;
}

const cssBaseClass: string = "c-change-indicator-diff-panel";

const ChangeIndicatorDiffPanel: React.FC<ChangeIndicatorDiffModalProps> = (
    props
) => {
    const { isTable, isPart } = props;
    const { showDiffPanel, content, handleClose, setChangeSummaries } =
        useChangeIndicatorDiffPanel();
    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
    const { t } = useLocalization();
    const { publication } = useCurrentPublication();
    const isJurisdictional = StringUtils.hasValue(publication.jurisdiction);

    const CHANGED_SUBHEADER = isJurisdictional
        ? t("textFromNFPACodePriorToJurisdictionalAmendments")
        : t("diffPanelSubHeader");
    const NEW_SUBHEADER = isJurisdictional
        ? t("thisContentWasNotInTheNFPAVersion")
        : t("diffPanelSubHeaderNew");
    const PREVIOUS_VERSION_SUBHEADER = t("diffPanelSubHeaderPriorEdition");
    const DELETED_CONTENT_SUBHEADER = t("diffPanelHeaderDeleted");

    const showPriorEdition = content.body == null;

    const isADeletion = content.changes === XmlChangeNotationConstants.DELETION;
    const isANewItem =
        content.changes === XmlChangeNotationConstants.NEW_MATERIAL;
    const isAMove = content.changes === XmlChangeNotationConstants.MOVED;

    const isMovedWithChanges =
        content.changes === XmlChangeNotationConstants.MOVED_WITH_CHANGES;
    const showBody =
        content.body != null && !(isANewItem || isAMove || showPriorEdition);

    const getMovedSubHeadingByContentType = () => {
        if (content.contentType === PublicationComponentType.Chapter) {
            return t("movedChangedIndicatorLocationChapter", {
                priorLocation: content.priorLocation,
            });
        }
        if (content.contentType === PublicationComponentType.Annex) {
            return t("movedChangedIndicatorLocationAnnex", {
                priorLocation: content.priorLocation,
            });
        }
        return t("movedChangedIndicatorLocation", {
            priorLocation: content.priorLocation,
        });
    };

    const getMovedWithChangeSubHeadingByContentType = () => {
        if (content.contentType === PublicationComponentType.Chapter) {
            if (isJurisdictional) {
                return t(
                    "movedWithChangesIndicatorLocationChapterJurisdictional",
                    {
                        priorLocation: content.priorLocation,
                    }
                );
            }
            return t("movedWithChangesIndicatorLocationChapter", {
                priorLocation: content.priorLocation,
            });
        }
        if (content.contentType === PublicationComponentType.Annex) {
            if (isJurisdictional) {
                return t(
                    "movedWithChangesIndicatorLocationAnnexJurisdictional",
                    {
                        priorLocation: content.priorLocation,
                    }
                );
            }
            return t("movedWithChangesIndicatorLocationAnnex", {
                priorLocation: content.priorLocation,
            });
        }
        if (isJurisdictional) {
            return t("movedWithChangesIndicatorLocationJurisdictional", {
                priorLocation: content.priorLocation,
            });
        }

        return t("movedWithChangesIndicatorLocation", {
            priorLocation: content.priorLocation,
        });
    };

    const getSubHeader = () => {
        if (isAMove) {
            return getMovedSubHeadingByContentType();
        }
        if (isMovedWithChanges) {
            return getMovedWithChangeSubHeadingByContentType();
        }
        if (isANewItem) return NEW_SUBHEADER;
        if (showPriorEdition) return PREVIOUS_VERSION_SUBHEADER;
        if (isADeletion) return DELETED_CONTENT_SUBHEADER;
        return CHANGED_SUBHEADER;
    };

    const showDesktopView = isDesktop && showDiffPanel;
    const showMobileView = !isDesktop && showDiffPanel;

    return (
        <React.Fragment>
            {showDesktopView && (
                <div
                    className={`${cssBaseClass} ${isPart ? "-part" : ""} ${
                        isTable ? "-is-table" : ""
                    }`}
                    id={"diff-panel"}>
                    <div className={`${cssBaseClass}__container`}>
                        <ChangeIndicatorDiffContent
                            content={content}
                            showBody={showBody}
                            subHeader={getSubHeader()}
                            setChangeSummaries={setChangeSummaries}
                        />
                    </div>
                </div>
            )}
            {showMobileView && (
                <Modal
                    useDialogOverlay={true}
                    cssClassName={"c-codes-modal"}
                    closeDialog={handleClose}
                    isVisible={true}
                    label={t("viewChanges")}
                    transition={ModalTransitions.SlideUp}
                    type={ModalTypes.Fullscreen}>
                    <ChangeIndicatorDiffContent
                        content={content}
                        showBody={showBody}
                        subHeader={getSubHeader()}
                        setChangeSummaries={setChangeSummaries}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default ChangeIndicatorDiffPanel;
