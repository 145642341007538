import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import SelectSizes from "atoms/constants/select-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SelectProps<T = any, V extends string | number = string> {
    cssClassName?: string;
    disabled?: boolean;
    id?: string;
    label?: string;
    onChange: (selectedOption?: SelectOption<T, V>) => void;
    name?: string;
    options: SelectOption<T, V>[];
    size?: SelectSizes;
    style?: SelectStyles;
    /**
     * On mobile screens, show a bottom modal instead of the native select
     * @default true
     */
    useModalOnMobile?: boolean;
    value?: V;
}

export interface SelectOption<
    TData = any,
    TValue extends string | number = string,
> {
    data?: TData;
    disabled?: boolean;
    isPlaceholder?: boolean;
    label: string;
    value: TValue;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum SelectStyles {
    Button = "button",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Select = <
    TData extends any = any,
    TValue extends string | number = string,
>(
    props: SelectProps<TData, TValue>
) => {
    // stateful so it doesn't recompute on every render
    const [defaultId] = useState(`select-${uuid.v4()}`);
    const { useModalOnMobile = true } = props;

    const CSS_CLASS_NAME = "c-select";
    const classNames = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    if (props.size != null) {
        classNames.push(props.size);
    }

    switch (props.style) {
        case SelectStyles.Button:
            classNames.push("-button");
            break;
        default:
            break;
    }

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const [showModal, setShowModal] = useState(false);

    const selectOptions = props.options.map((option) => {
        return (
            <option
                disabled={option.disabled || option.isPlaceholder}
                hidden={option.isPlaceholder}
                key={option.value}
                value={option.value}>
                {option.label}
            </option>
        );
    });

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const option = props.options.find(
            (o: SelectOption<TData, TValue>) => `${o.value}` === e.target.value
        );
        props.onChange(option);
    };

    const handleModalOptionClick = (
        selectedOption: SelectOption<TData, TValue>
    ) => {
        if (props.value === selectedOption.value) {
            props.onChange(undefined);
            setShowModal(false);
            return;
        }

        props.onChange(selectedOption);
        setShowModal(false);
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (useModalOnMobile && isMobile) {
            e.preventDefault();
            e.stopPropagation();
            setShowModal(true);
        }
    };

    return (
        <React.Fragment>
            <div className={classNames.join(" ")} onClick={handleClick}>
                <select
                    aria-label={props.label}
                    disabled={props.disabled}
                    id={props.id ?? defaultId}
                    onChange={handleChange}
                    value={props.value}
                    name={props.name}
                    // To prevent Safari on iPhone from focusing and opening the native select if
                    // select is first element.
                    tabIndex={isMobile ? -1 : 0}>
                    {selectOptions}
                </select>
                <Icon type={Icons.ChevronDown} />
            </div>
            {isMobile && useModalOnMobile && (
                <Modal
                    cssClassName={`${CSS_CLASS_NAME}__modal`}
                    closeButtonStyle={ModalCloseButtonStyle.Hidden}
                    closeDialog={() => setShowModal(false)}
                    isVisible={!props.disabled && showModal}
                    label={props.label ?? "Select"}
                    transition={ModalTransitions.SlideUp}
                    type={ModalTypes.Bottom}>
                    <div className={`${CSS_CLASS_NAME}__modal__top`}>
                        <Paragraph>
                            {props.label ?? "Select an Option"}
                        </Paragraph>
                        <Button
                            onClick={() => setShowModal(false)}
                            size={ButtonSizes.Small}
                            style={ButtonStyles.Secondary}>
                            Close
                        </Button>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__modal__bottom`}>
                        {props.options
                            .filter((o) => !o.isPlaceholder)
                            .map((option: SelectOption<TData, TValue>) => {
                                return (
                                    <Button
                                        cssClassName={
                                            props.value === option.value
                                                ? "-selected"
                                                : ""
                                        }
                                        key={option.value}
                                        onClick={() =>
                                            handleModalOptionClick(option)
                                        }
                                        style={ButtonStyles.Anchor}>
                                        {option.label}
                                    </Button>
                                );
                            })}
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

// #endregion Component

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default Select;
