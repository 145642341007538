import * as React from "react";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ChangeIndicatorIconProps {
    changes: string | undefined;
    cssClassName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ChangeIndicatorIcon: React.FunctionComponent<ChangeIndicatorIconProps> = (
    props: ChangeIndicatorIconProps
) => {
    const { cssClassName = "c-tia-indicator__icon", changes } = props;
    switch (changes) {
        case XmlChangeNotationConstants.MOVED:
            return <Icon cssClassName={cssClassName} type={Icons.Moved} />;
        case XmlChangeNotationConstants.DELETION:
            return <Icon cssClassName={cssClassName} type={Icons.Dot} />;
        case XmlChangeNotationConstants.NEW_MATERIAL:
            return (
                <Icon cssClassName={cssClassName} type={Icons.NewMaterial} />
            );
        case XmlChangeNotationConstants.HAS_CHANGES:
            return <Icon cssClassName={cssClassName} type={Icons.Delta} />;
        case XmlChangeNotationConstants.MOVED_WITH_CHANGES:
            return <Icon cssClassName={cssClassName} type={Icons.Delta} />;
        default:
            return null;
    }
};

// #endregion Component

export default ChangeIndicatorIcon;
