import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import BookviewContentWidthToggleButton from "molecules/bookview-content-expansion/bookview-content-width-toggle-button";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import ReferencePanelButton from "molecules/reference-panel/reference-panel-button";
import BreadcrumbNavigation from "organisms/breadcrumbs/breadcrumb-navigation";
import BreadcrumbsModal from "organisms/breadcrumbs/breadcrumbs-modal";
import ChangeIndicatorLegend from "organisms/change-indicator-legend/change-indicator-legend";
import BookViewUserPreferences from "organisms/user-preferences/book-view/book-view-user-preferences";
import React from "react";
import { Options } from "use-react-router-breadcrumbs";
import useMetaNav from "utilities/atoms/metanav-visible-atom";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import { CULTURE_PARAM } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface BreadcrumbsMenuProps {
    fullWidth?: boolean;
    showOverflowMenu?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const BREADCRUMB_OPTIONS: Options = {
    // Disable default behavior of making API calls based on route
    disableDefaults: true,
    // Publication path will match twice unless excluded
    excludePaths: [`${CULTURE_PARAM}/publications/:id`],
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BreadcrumbsMenu: React.FC<BreadcrumbsMenuProps> = (
    props: BreadcrumbsMenuProps
) => {
    const cssBaseClassName = "c-breadcrumbs-menu";
    const { isOpen, handleToggle, handleClose } = useModalActions();
    const { t } = useLocalization();
    const openNavigation = t("openNavigation");
    const fullWidthModifier = props.fullWidth === true ? "-full-width" : "";

    const { routeIsBookView } = useIsNestedRoute();

    const { toggle } = useMetaNav();

    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    return (
        <div className={`${cssBaseClassName} ${fullWidthModifier}`}>
            {isMobile && (
                <>
                    <div
                        className={`${cssBaseClassName}__metapanel-trigger`}
                        onClick={toggle}>
                        <button type="button" name={openNavigation}>
                            <Icon
                                type={Icons.MetaPanel}
                                size={IconSizes.Large}
                            />
                        </button>
                    </div>
                    <Button
                        accessibleText={openNavigation}
                        cssClassName={`${cssBaseClassName}__mobile-menu`}
                        onClick={handleToggle}
                        style={ButtonStyles.None}></Button>
                </>
            )}
            <BreadcrumbNavigation options={BREADCRUMB_OPTIONS} />
            {routeIsBookView && (
                <div className={`${cssBaseClassName}__controls`}>
                    {!isMobile && <BookviewContentWidthToggleButton />}
                    <BookViewUserPreferences />
                    <ReferencePanelButton />
                    <ChangeIndicatorLegend />
                </div>
            )}

            <Modal
                closeDialog={handleClose}
                isVisible={isOpen}
                label={t("breadcrumbs")}
                transition={ModalTransitions.SlideUp}
                type={ModalTypes.Bottom}>
                <BreadcrumbsModal
                    onBreadcrumbClick={handleClose}
                    options={BREADCRUMB_OPTIONS}
                />
            </Modal>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BreadcrumbsMenu;

// #endregion Exports
