import Loader from "atoms/loaders/loader";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import { AriaRole } from "utilities/enumerations/aria-role";
import NaturalLanguageSearchResultRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-result-record";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Anchor from "atoms/anchors/anchor";

export interface NaturalLanguageSearchModalResultsProps {
    loading: boolean;
    onResultClick: () => void;
    results?: Array<NaturalLanguageSearchResultRecord>;
}

const BASE_CLASS = "c-natural-language-search-modal-results";

const NaturalLanguageSearchModalResults: React.FC<NaturalLanguageSearchModalResultsProps> =
    React.memo((props: NaturalLanguageSearchModalResultsProps) => {
        const { results, loading, onResultClick } = props;
        const { t } = useLocalization();

        return (
            <div className={BASE_CLASS}>
                <div
                    aria-label={t("searchModal-SearchResults")}
                    className={`${BASE_CLASS}__list`}
                    role={AriaRole.List}>
                    {loading && <Loader />}
                    {!loading &&
                        results?.map(
                            (
                                nlsr: NaturalLanguageSearchResultRecord,
                                index: number
                            ) => (
                                <Anchor
                                    cssClassName={`${BASE_CLASS}__item`}
                                    key={index}
                                    onClick={onResultClick}
                                    to={nlsr.getPermalink()}>
                                    <Paragraph
                                        cssClassName={`${BASE_CLASS}__item__title`}
                                        dangerouslySetInnerHTML={{
                                            __html: nlsr.title,
                                        }}
                                        size={ParagraphSizes.Base}
                                    />
                                    <Paragraph
                                        cssClassName={`${BASE_CLASS}__item__body`}
                                        dangerouslySetInnerHTML={{
                                            __html: nlsr.text,
                                        }}
                                        size={ParagraphSizes.Small}
                                    />
                                    <Paragraph
                                        cssClassName={`${BASE_CLASS}__item__breadcrumbs`}
                                        size={ParagraphSizes.XSmall}>
                                        {"Relevance Score: " +
                                            nlsr.getFormattedRelevanceScore()}
                                    </Paragraph>
                                </Anchor>
                            )
                        )}
                </div>
            </div>
        );
    });

export default NaturalLanguageSearchModalResults;
