import { createContext, useContext } from "react";
import {
    AuthenticationLifeCycleStatus,
    AuthenticationStrategyType,
} from "utilities/enumerations/authorization";

export interface AuthenticationContextType {
    logout: (loginSlug?: string) => Promise<void>;
    login: (
        authenticationStrategyType: AuthenticationStrategyType,
        redirectStartPage?: string
    ) => Promise<void>;
    authenticationLifeCycleStatus: AuthenticationLifeCycleStatus;
    errorMessage: string | null;
    endEmulationSession: () => Promise<boolean>;
    signUpRedirect: (
        authenticationStrategyType: AuthenticationStrategyType,
        redirectStartPage: string
    ) => Promise<void>;
    forgotPasswordRedirect: (
        authenticationStrategyType: AuthenticationStrategyType,
        isComingFromLogin: boolean
    ) => Promise<void>;
}

const defaultContextValue: AuthenticationContextType = {
    logout: async (loginSlug?: string) => {
        throw new Error("Authentication context not initialized");
    },
    login: async () => {
        throw new Error("Authentication context not initialized");
    },
    authenticationLifeCycleStatus: AuthenticationLifeCycleStatus.LoggedOut,
    errorMessage: null,
    endEmulationSession: async () => false,
    signUpRedirect: async () => {},
    forgotPasswordRedirect: async () => {},
};

export const AuthenticationContext =
    createContext<AuthenticationContextType>(defaultContextValue);

export const useAuthenticationContext = () => {
    const context = useContext(AuthenticationContext);

    if (context === undefined) {
        throw new Error(
            "useAuthentication must be used within an AuthenticationProvider"
        );
    }

    return context;
};
