import { ClassNameConstants } from "constants/classname-constants";
import { PartRecord } from "internal";
import IndicatorIcons from "organisms/indicator-buttons/indicator-icons";
import ChangeIndicatorDiffPanel from "organisms/panels/change-indicator-diff/change-indicator-diff-panel";
import TiaBookviewHeading from "organisms/panels/tia-changes/tia-bookview-heading";
import TiaChangesPanel from "organisms/panels/tia-changes/tia-changes-panel";
import PublicationComponentActions from "organisms/publication-component-actions/publication-component-actions";
import * as React from "react";
import { PropsWithChildren, ReactNode } from "react";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useBreakpoint from "utilities/hooks/use-breakpoint";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PartSeparatorProps {
    part: PartRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------
const className = "c-part-separator";

const PartSeparator: React.FunctionComponent<
    PropsWithChildren<PartSeparatorProps>
> = (props: PropsWithChildren<PartSeparatorProps>) => {
    const { part } = props;

    const { content, handleOpen, showDiffPanel } =
        useChangeIndicatorDiffPanel();

    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);

    const {
        currentTia,
        isTiaPanelOpen,
        tias,
        handleTiaClick,
        currentExternalId: tiaExternalId,
        handleClose: handleTiaPanelClose,
    } = useTias(part.externalId);

    const hoverClassName = part.doesTitleOrBodyHaveChanges()
        ? ClassNameConstants.Hover
        : "";

    const showChangesModifier = `${
        (isTiaPanelOpen || showDiffPanel) && isDesktop ? "-show-changes" : ""
    }`;

    const handleDiffPanelOpen = () => {
        handleOpen({
            externalId: part.externalId,
            titleClassName: "",
            title: <PartTitleArea label={part.label} title={part.getTitle()} />,
            body: part.getBody(),
            changes: part.changes,
            label: part.label,
            priorLocation: part.priorLocation,
            contentType: PublicationComponentType.Part,
        });
    };

    const hasTiaOpen = part.externalId === tiaExternalId;

    const highlightSectionModifier =
        part.externalId === content.externalId || hasTiaOpen
            ? "-highlight-section"
            : "";
    return (
        <div className={`${className} ${showChangesModifier}`}>
            <section
                className={`${className}__part-title ${showChangesModifier} ${hoverClassName} ${highlightSectionModifier}`}
                data-test-part-id={part.id}
                id={part.externalId}>
                <IndicatorIcons
                    tias={tias}
                    changes={part.changes}
                    handleDiffPanelOpen={handleDiffPanelOpen}
                    handleTiaClick={handleTiaClick}
                />
                <PublicationComponentActions
                    handleTiaClick={handleTiaClick}
                    record={part}
                    recordLabel={"part"}
                    showEnhancedContentModal={false}
                    tias={tias}
                    //type is used for enhanced content. Parts are not available for enhanced content yet.
                    type={PublicationComponentType.Section}
                    visible={part.doesTitleOrBodyHaveChanges()}
                    handleDiffPanelOpen={handleDiffPanelOpen}
                />
                <div className={`${className}__part-title__part-heading`}>
                    {hasTiaOpen && <TiaBookviewHeading tias={tias} />}
                    <div className={`${className}__hr `}>
                        <span className={`${className}__hr__line`}></span>
                        <div className={`${className}__title`}>
                            {part.label + " "}
                            {part.getTitle()}
                        </div>
                        <span className={`${className}__hr__line`}></span>
                    </div>
                </div>
                {props.children}
            </section>
            {content.externalId === part.externalId && (
                <ChangeIndicatorDiffPanel isPart={true} />
            )}
            {part.externalId === tiaExternalId && (
                <TiaChangesPanel
                    tias={tias}
                    handleClose={handleTiaPanelClose}
                    title={
                        <PartTitleArea
                            label={part.label}
                            title={currentTia.getTitle()}
                        />
                    }
                    body={currentTia.getBody()}
                />
            )}
        </div>
    );
};

interface PartTitleAreaProps {
    label: string;
    title: string | ReactNode;
}

const PartTitleArea = ({ label, title }: PartTitleAreaProps) => {
    return (
        <div className={`${className}__hr `}>
            <span className={`${className}__hr__line`}></span>
            <div className={`${className}__title`}>
                {label + " "}
                {title}
            </div>
            <span className={`${className}__hr__line`}></span>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PartSeparator;

// #endregion Exports
