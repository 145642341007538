import SearchForm from "molecules/forms/search-form";
import React from "react";
import { AriaRole } from "utilities/enumerations/aria-role";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface NaturalLanguageSearchModalHeaderProps {
    hintText?: string;
    onSearchClear: () => void;
    onSearchTextChange: (text: string) => void;
    onSubmit: () => void;
    searchText: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-natural-language-search-modal-header";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const NaturalLanguageSearchModalHeader: React.FunctionComponent<NaturalLanguageSearchModalHeaderProps> =
    React.memo((props) => {
        const {
            hintText,
            onSearchClear,
            onSearchTextChange,
            onSubmit,
            searchText,
        } = props;
        const { t } = useLocalization();

        const handleSearchFieldChange = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            onSearchTextChange(e.target.value);
        };

        return (
            <div className={BASE_CLASS_NAME}>
                <SearchForm
                    aria-label={t("search")}
                    ariaRole={AriaRole.Search}
                    hintText={hintText}
                    onClear={onSearchClear}
                    onSearchClick={onSubmit}
                    onSearchTextChange={handleSearchFieldChange}
                    placeholder={t("searchPlaceholder")}
                    searchText={searchText}
                />
            </div>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default NaturalLanguageSearchModalHeader;

// #endregion Export
