import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React, { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMetaNav from "utilities/atoms/metanav-visible-atom";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import ExpandableMetaNav from "./expandable-meta-nav";
import MetaNavSwitch from "./metanav-switch";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";

interface BookViewMetaNavProps {}

const CSS_BASE_CLASS = "c-publication-page-layout__metadata";

const BookViewMetaNav: React.FunctionComponent<
    PropsWithChildren<BookViewMetaNavProps>
> = (props: PropsWithChildren<BookViewMetaNavProps>) => {
    const {
        isMetaNavVisible,
        toggle,
        closeMetaNav,
        isMetaNavCollapsed,
        toggleCollapse,
    } = useMetaNav();

    const location = useLocation();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    // Closes ToC if on mobile and in new route
    useEffect(() => {
        if (isMobile) {
            closeMetaNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <Button
                style={ButtonStyles.Tertiary}
                cssClassName={`${CSS_BASE_CLASS}__toggle ${isMetaNavCollapsed ? "-collapse" : ""}`}
                title="Toggle"
                onClick={toggleCollapse}>
                <Icon
                    type={
                        isMetaNavCollapsed
                            ? Icons.ChevronRight
                            : Icons.ChevronLeft
                    }
                />
            </Button>
            <nav
                className={`${CSS_BASE_CLASS} ${isMetaNavVisible ? "-open" : ""} ${isMetaNavCollapsed ? "-collapse" : ""}`}>
                <div className={`${CSS_BASE_CLASS}__left`}>
                    {!isMobile ? <ExpandableMetaNav /> : <MetaNavSwitch />}
                </div>
                <div className={`${CSS_BASE_CLASS}__right`} onClick={toggle}>
                    <div className={`${CSS_BASE_CLASS}__right__close-button`}>
                        <button>
                            <Icon type={Icons.Close} size={IconSizes.Large} />
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default BookViewMetaNav;
