import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { CloseDialogEvent, ModalProps } from "molecules/modals/modal";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import React, { useCallback, useEffect, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { useLocalization } from "utilities/hooks/use-localization";
import usePagedResults from "utilities/hooks/use-paged-results";
import NaturalLanguageSearchModalHeader from "organisms/modals/natural-language-search-modal/natural-language-search-modal-header";
import NaturalLanguageSearchModalResults from "organisms/modals/natural-language-search-modal/natural-language-search-modal-results";
import NaturalLanguageSearchSessionRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-session-record";
import { NaturalLanguageSearchService } from "utilities/services/natural-language-search/natural-language-search-service";
import { NaturalLanguageSearchSessionService } from "utilities/services/natural-language-search/natural-language-search-session-service";
import NaturalLanguageSearchRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-record";
import NaturalLanguageSearchResultRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-result-record";

interface NaturalLanguageSearchModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible" | "onCloseComplete"> {}

const BASE_CLASS_NAME = "c-natural-language-search-modal";
const DEFAULT_ONLINE_PAGE_SIZES = [10, 25, 50, 100];
const DEFAULT_TAKE_SIZE = 10;

const NaturalLanguageSearchModal: React.FunctionComponent<
    NaturalLanguageSearchModalProps
> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState<
        NaturalLanguageSearchResultRecord[]
    >([]);
    const {
        currentPage,
        numberOfPages,
        rowCount,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        resetPagination,
        setRowCount,
        skip,
        take,
    } = usePagedResults(DEFAULT_TAKE_SIZE);
    const { closeDialog, isVisible, onCloseComplete } = props;
    const { t } = useLocalization();
    const transition =
        window.innerWidth < Breakpoints.Phone
            ? ModalTransitions.SlideUp
            : ModalTransitions.SlideRight;

    const searchService = NaturalLanguageSearchSessionService.useCreate();

    const getOrCreateSession = useCallback(async () => {
        // A searchSession already exists, return the sessionId
        if (sessionId != null) return sessionId;

        // Create the new searchSession
        const response = await NaturalLanguageSearchService.create(
            new NaturalLanguageSearchSessionRecord()
        );
        if (response?.resultObject?.id == null) {
            throw new Error(
                "Something went wrong. Unable to create a new searchSession."
            );
        }

        // Set the sessionId and return it
        setSessionId(response.resultObject.id);
        return response.resultObject.id;
    }, [sessionId]);

    const search = useCallback(
        async (searchText: string, skip: number, take: number) => {
            // If the search text is empty, don't search
            if (searchText.trim().length === 0) return;

            setIsLoading(true);
            try {
                // Perform the search
                const sessionId = await getOrCreateSession();
                const searchResponse = await searchService.create(
                    new NaturalLanguageSearchRecord({
                        id: sessionId,
                        search: searchText,
                        skip: skip,
                        take: take,
                    }),
                    { id: sessionId }
                );

                if (searchResponse?.resultObject?.results) {
                    const recordResults =
                        searchResponse.resultObject.results.map(
                            (result) =>
                                new NaturalLanguageSearchResultRecord(result)
                        );
                    const totalCount =
                        searchResponse.resultObject.totalCount ?? 0;

                    setSearchResults(recordResults);
                    setRowCount(totalCount);
                }
            } catch {
                console.error("Throw up a toast");
            } finally {
                setIsLoading(false);
            }
        },
        [searchService, getOrCreateSession, setRowCount]
    );

    const handleSearchSubmit = async () => {
        await search(searchText, skip, take);
    };

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);
    };

    const handleResultClick = () => {
        closeDialog();
    };

    const handleSearchClear = () => {
        setSessionId(null);
        setSearchText("");
        setSearchResults([]);
        resetPagination();
    };

    const handleClose = (event?: CloseDialogEvent) => {
        handleSearchClear();
        closeDialog(event);
    };

    // Anytime the skip or take change, perform a search
    useEffect(() => {
        search(searchText, skip, take);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, take]);

    return (
        <Modal
            closeDialog={handleClose}
            isVisible={isVisible}
            label={t("searchItem", { item: t("menu") })}
            onCloseComplete={onCloseComplete}
            showAppSidebar={true}
            transition={transition}
            type={ModalTypes.Search}>
            <div className={BASE_CLASS_NAME}>
                <NaturalLanguageSearchModalHeader
                    onSearchClear={handleSearchClear}
                    onSearchTextChange={handleSearchTextChange}
                    onSubmit={handleSearchSubmit}
                    searchText={searchText}
                />
                <div className={`${BASE_CLASS_NAME}__content`}>
                    <NaturalLanguageSearchModalResults
                        loading={isLoading}
                        onResultClick={handleResultClick}
                        results={searchResults}
                    />
                </div>
                {rowCount > 0 && (
                    <div className={`${BASE_CLASS_NAME}__footer`}>
                        <PageNavigationMenu
                            currentPage={currentPage}
                            numberOfPages={numberOfPages}
                            onNavigateBack={onPageLast}
                            onNavigateForward={onPageNext}
                            onPageSizeChange={onPageSizeChange}
                            onSelectPage={onSelectPage}
                            pageSize={take}
                            pageSizes={DEFAULT_ONLINE_PAGE_SIZES}
                            resultTotal={rowCount}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default NaturalLanguageSearchModal;
