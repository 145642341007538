import GlobalStateRecord from "models/view-models/global-state-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

/**
 * Returns the magic link URL template for group invitations
 * @param {GlobalStateRecord} globalState
 * @param {UserRoleGroupRecord} currentUserRoleGroup
 * @returns {string} Returns the magic link URL template for group invitations
 */

const getMagicLinkUrlTemplate = (
    globalState: GlobalStateRecord,
    currentUserRoleGroup: UserRoleGroupRecord
) => {
    return `${globalState.systemSettings?.groupInvitiationsBaseUrl}/group/${
        currentUserRoleGroup?.group?.id
    }/invitation/:token?invitedBy=${globalState.currentIdentity?.user?.getFirstAndLastName()}&teamName=${
        currentUserRoleGroup?.group?.name
    }`;
};
// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const MagicLinkUrlUtils = {
    getMagicLinkUrlTemplate,
};

export { MagicLinkUrlUtils };

// #endregion Exports
