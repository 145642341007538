import EmulationTokenRecord from "models/view-models/emulation-token-record";

import useLocalStorage from "utilities/hooks/use-local-storage";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";
import { CookieUtils } from "utilities/cookie-utils";
import { EmulationModeContext } from "utilities/contexts/emulation/emulation-mode-context-provider";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { siteMap } from "internal-sitemap";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { useAuthenticationFactory } from "utilities/contexts/authentication/use-authentication.factory";
import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

const UNABLE_TO_CREATE_TOKEN = "Unable to create emulation token.";
const ACCESS_TOKEN = "AccessToken";

export default function useEmulationMode() {
    const {
        setLoadingEmulationSession,
        token,
        setToken,
        userEmail,
        endEmulationMode,
    } = useContext(EmulationModeContext);
    const { authenticationFactory } = useAuthenticationFactory();
    const { login } = useAuthentication();
    const history = useHistory();
    const { getValue } = useLocalStorage<EmulationTokenRecord | null>(
        "token",
        null
    );

    const createToken = async (guestId: number | undefined) => {
        if (guestId == null) {
            return ToastManager.error(UNABLE_TO_CREATE_TOKEN);
        }

        const accessToken = CookieUtils.get(ACCESS_TOKEN);

        if (!accessToken) {
            const authStrategyResult = await authenticationFactory(
                AuthenticationStrategyType.AzureB2C
            );

            await authStrategyResult.authStrategy.verifyLogin();
        }
        setLoadingEmulationSession(true);

        await login(AuthenticationStrategyType.Emulation);

        history.push(RouteUtils.getUrl(siteMap.dashboards.user));
        setLoadingEmulationSession(false);
    };

    const createOrgMemberToken = async (
        guestId: number | undefined,
        userRoleOrganizationId: number | undefined
    ) => {
        if (guestId == null || userRoleOrganizationId == null) {
            return ToastManager.error(UNABLE_TO_CREATE_TOKEN);
        }
        setLoadingEmulationSession(true);

        await login(AuthenticationStrategyType.Emulation);

        history.push(RouteUtils.getUrl(siteMap.dashboards.user));
        setLoadingEmulationSession(false);
    };

    const isInEmulationMode = useMemo(() => {
        return getValue() != null;
    }, [getValue]);

    return {
        isInEmulationMode,
        token,
        setToken,
        userEmail,
        createToken,
        createOrgMemberToken,
        login: login,
        endEmulationMode,
    };
}
