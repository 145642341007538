import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import React, { useEffect, useRef, useState } from "react";
import StringUtils from "utilities/string-utils";

interface ShowMoreProps {
    condensed?: boolean;
    showLessButtonText?: string;
    showMoreButtonText?: string;
    cssClassName?: string;
    children?: React.ReactNode;
}

const ShowMore: React.FC<ShowMoreProps> = (props: ShowMoreProps) => {
    const {
        condensed = true,
        showLessButtonText = "Read less",
        showMoreButtonText = "Read more",
    } = props;
    const [isCondensed, setCondensed] = useState<boolean>(condensed!);
    const [buttonText, setButtonText] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [initialHeight, setInitialHeight] = useState<number>(0);
    const hasMeasuredInitialHeight = useRef<boolean>(false);
    const showMoreContainer = useRef<HTMLDivElement>(null);
    const innerContentContainer = useRef<HTMLDivElement>(null);
    const CSS_BASE_CLASS = "c-show-more";
    const cssClassNames = [CSS_BASE_CLASS];

    if (!condensed || !isCondensed) {
        cssClassNames.push("-expanded");
    }

    if (!StringUtils.isEmpty(props.cssClassName)) {
        cssClassNames.push(props.cssClassName!);
    }

    const handleShowMore = () => {
        setCondensed(!isCondensed);
    };

    useEffect(() => {
        const innerContainerRef = innerContentContainer.current;
        const showMoreContainerRef = showMoreContainer.current;

        if (
            innerContainerRef &&
            showMoreContainerRef &&
            !hasMeasuredInitialHeight.current
        ) {
            const observer = new ResizeObserver(() => {
                const innerHeight =
                    innerContainerRef.getBoundingClientRect().height;
                const containerHeight =
                    showMoreContainerRef.getBoundingClientRect().height;

                if (innerHeight > containerHeight) {
                    setShowMore(true);
                }

                setInitialHeight(innerHeight);
                if (initialHeight !== 0) {
                    hasMeasuredInitialHeight.current = true;
                }
            });

            observer.observe(innerContainerRef);
            observer.observe(showMoreContainerRef);

            return () => {
                observer.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setButtonText(isCondensed ? showMoreButtonText : showLessButtonText);
    }, [showLessButtonText, showMoreButtonText, isCondensed]);

    return (
        <section>
            <div ref={showMoreContainer} className={cssClassNames.join(" ")}>
                <div
                    ref={innerContentContainer}
                    className={`${CSS_BASE_CLASS}__inner-content`}>
                    {props.children}
                </div>
            </div>

            <div
                className={`${CSS_BASE_CLASS}__button-container`}
                id={"show-more-button-container"}>
                {showMore && (
                    <Button onClick={handleShowMore} size={ButtonSizes.Small}>
                        {buttonText}
                    </Button>
                )}
            </div>
        </section>
    );
};

export default ShowMore;
