import { Record } from "immutable";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import type UserRole from "models/interfaces/user-role";
import RoleRecord from "models/view-models/role-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import RoleType from "utilities/enumerations/role-type";
import UserRecord from "models/view-models/user-record";
import moment from "moment";
import StringUtils from "utilities/string-utils";
import { UnitOfTime } from "@rsm-hcd/javascript-core";

const defaultValues: UserRole =
    RecordUtils.auditableDefaultValuesFactory<UserRole>({
        cancelledReason: undefined,
        cancelledOn: undefined,
        eulaAccepted: false,
        expiresOn: undefined,
        role: undefined,
        roleId: 0,
        subscriptionId: undefined,
        userId: 0,
        userRoleGroup: undefined,
        user: undefined,
    });

export default class UserRoleRecord
    extends Record(defaultValues)
    implements UserRole
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserRole) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.role != null) {
            params.role = RecordUtils.ensureRecord(params.role, RoleRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.userRoleGroup != null) {
            params.userRoleGroup = RecordUtils.ensureRecord(
                params.userRoleGroup,
                UserRoleGroupRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Calculates the number of days from now until a subscription expires.
     *
     * Returns undefined if this.expiresOn is null.
     *
     */
    public daysUntilSubscriptionExpiration(): number | undefined {
        if (!this.hasExpiresOn()) {
            return undefined;
        }

        const subscriptionExpirationDate = moment(this.expiresOn);

        const daysUntilSubscriptionExpiration = subscriptionExpirationDate.diff(
            moment(),
            UnitOfTime.Days
        );

        return daysUntilSubscriptionExpiration;
    }

    /**
     * Convenience method for returning the name of the role object
     * @returns string
     */
    public getName(): string {
        if (!this.hasRole()) {
            return "";
        }
        return this.role!.getName();
    }

    /**
     * Returns whether or not the 'expiresOn' field has a value & can be converted to a Moment
     */
    public hasExpiresOn(): boolean {
        return (
            StringUtils.hasValue(this.expiresOn) &&
            moment(this.expiresOn).isValid()
        );
    }

    /**
     * Convenience method for checking if the role is null or undefined
     * @returns boolean
     */
    public hasRole(): boolean {
        return this.role != null;
    }

    /**
     * Returns whether or not the underlying RoleRecord corresponds to an Enterprise RoleType
     *
     * @param {RoleType} type
     */
    public isEnterpriseRole(): boolean {
        return this.isRole(RoleType.ENTERPRISE);
    }

    /**
     * Returns whether or not the role has expired (if it has an 'expiresOn' value)
     */
    public isExpired(): boolean {
        if (!this.hasExpiresOn()) {
            return false;
        }

        const expiresOn = moment(this.expiresOn);
        const now = moment();

        return expiresOn.isSameOrBefore(now);
    }

    /**
     * Returns whether or not the underlying RoleRecord corresponds to a FreeTrial RoleType
     *
     * @param {RoleType} type
     */
    public isFreeTrialRole(): boolean {
        return this.isRole(RoleType.FREE_TRIAL);
    }

    /**
     * Returns whether or not the underlying RoleRecord corresponds to an Individual RoleType
     *
     * @param {RoleType} type
     */
    public isIndividualRole(): boolean {
        return this.isRole(RoleType.INDIVIDUAL);
    }

    /**
     * Returns whether or not the underlying RoleRecord corresponds to a Team RoleType
     *
     * @param {RoleType} type
     */
    public isTeamRole(): boolean {
        return this.isRole(RoleType.TEAM);
    }

    /**
     * Returns whether or not the underlying RoleRecord corresponds to the given RoleType
     *
     * @param {RoleType} type
     */
    public isRole(type: RoleType): boolean {
        return this.hasRole() && this.role!.is(type);
    }

    /**
     * Returns whether or not this UserRole is Subscription based
     */
    public isSubscription(): boolean {
        return this.subscriptionId != null;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserRole>} values
     */
    public with(values: Partial<UserRole>): UserRoleRecord {
        return new UserRoleRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds Role relationship to UserRole from a list of Roles.
     *
     * @param {Array<RoleRecord>} [roles]
     */
    public withRole(roles?: Array<RoleRecord>): UserRoleRecord {
        if (roles == null) {
            return this.with({});
        }

        var role = roles.find((role: RoleRecord) => role.id === this.roleId);

        return this.with({ role: role });
    }

    /**
     * Adds UserRoleGroup relationship to UserRole from a list of UserRoleGroups.
     *
     * @param {Array<UserRoleGroupRecord>} [userRoleGroups]
     */
    public withUserRoleGroup(
        userRoleGroups?: Array<UserRoleGroupRecord>
    ): UserRoleRecord {
        if (userRoleGroups == null) {
            return this.with({});
        }

        const userRoleGroup = userRoleGroups.find(
            (userRoleGroup: UserRoleGroupRecord) =>
                userRoleGroup.userRoleId === this.id
        );

        return this.with({ userRoleGroup: userRoleGroup });
    }

    // #endregion Public Methods
}
