import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import TeamsAdminTable from "organisms/admin/teams/teams-admin-table";
import React, { useEffect, useState } from "react";
import useTeamsAdmin from "utilities/hooks/domain/admin/teams/use-teams-admin";
import useDebounce from "utilities/hooks/use-debounce";
import { t } from "utilities/localization-utils";

const CSS_BASE_CLASS = "c-support-admin-dashboard-page";

const TeamsAdminDashboardPage: React.FC<any> = () => {
    const {
        loadingTeams,
        teams,
        loadTeams,
        rowCount,
        take,
        skip,
        currentPage,
        numberOfPages,
        onPageNext,
        onPageLast,
        onPageSizeChange,
        onSelectPage,
        resetPage,
    } = useTeamsAdmin();

    const [searchText, setSearchText] = useState("");
    const debouncedSearchText = useDebounce(searchText);

    useEffect(() => {
        loadTeams({ searchText: debouncedSearchText, skip, take });
    }, [loadTeams, debouncedSearchText, skip, take]);

    const handleSearchFormSubmit = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        resetPage();
        loadTeams({ searchText, take });
        return false;
    };

    const handleSearchFormCleared = () => {
        resetPage();
        setSearchText("");
    };

    const handleSearchTextChanged = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchText(e.target.value);
    };

    return (
        <div className={CSS_BASE_CLASS}>
            <div className={`${CSS_BASE_CLASS}__content`}>
                <div className={`${CSS_BASE_CLASS}__content__controls`}>
                    <SearchForm
                        onClear={handleSearchFormCleared}
                        onSearchClick={handleSearchFormSubmit}
                        onSearchTextChange={handleSearchTextChanged}
                        onSubmit={handleSearchFormSubmit}
                        placeholder={t(
                            "teamsAdminDashboardPage-searchFormPlaceholder"
                        )}
                        searchText={searchText}
                        size={SearchFormSizes.Small}
                        style={SearchFormStyles.Tertiary}
                    />
                </div>
            </div>

            <div className={`${CSS_BASE_CLASS}__table`}>
                <TeamsAdminTable teams={teams} loading={loadingTeams} />
            </div>

            <div className={`${CSS_BASE_CLASS}__footer`}>
                <PageNavigationMenu
                    resultTotal={rowCount}
                    pageSize={take}
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    onPageSizeChange={onPageSizeChange}
                    onNavigateBack={onPageLast}
                    onNavigateForward={onPageNext}
                    onSelectPage={onSelectPage}
                />
            </div>
        </div>
    );
};

export default TeamsAdminDashboardPage;
