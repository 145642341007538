import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { NfpaUrlPathsV2 } from "constants/nfpa-urls/nfpa-url-paths-v2";

interface UseNfpaUrlPathsHook {
    privacyUrl: string;
    termsUrl: string;
    forgotPasswordUrl: string;
    nfpaMyProfileUrl: string;
    customerSupportUrl: string;
    licenseUrl: string;
    linkUrl: string;
    loginUrl: string;
    requestQuote: string;
    accountSubscriptions: string;
    planOptions: string;
    productBasePath: string;
}

export default function useNfpaUrlPaths(): UseNfpaUrlPathsHook {
    const { globalState } = useGlobalState();

    const getUrl = (url: NfpaUrlPathsV2) =>
        globalState.getSystemSettings().getNfpaUrlV2(url);

    const customerSupportUrl = getUrl(NfpaUrlPathsV2.CustomerSupport);
    const forgotPasswordUrl = getUrl(NfpaUrlPathsV2.ForgottenPassword);
    const licenseUrl = getUrl(NfpaUrlPathsV2.LicenseAgreement);
    const linkUrl = getUrl(NfpaUrlPathsV2.LiNK);
    const nfpaMyProfileUrl = getUrl(NfpaUrlPathsV2.MyProfile);
    const privacyUrl = getUrl(NfpaUrlPathsV2.PrivacyPolicy);
    const termsUrl = getUrl(NfpaUrlPathsV2.TermsOfUse);
    const loginUrl = getUrl(NfpaUrlPathsV2.Login);
    const requestQuote = getUrl(NfpaUrlPathsV2.RequestQuote);
    const accountSubscriptions = getUrl(NfpaUrlPathsV2.AccountSubscriptions);
    const planOptions = getUrl(NfpaUrlPathsV2.PlanOptions);
    const productBasePath = getUrl(NfpaUrlPathsV2.ProductBasePath);

    return {
        privacyUrl,
        termsUrl,
        forgotPasswordUrl,
        nfpaMyProfileUrl,
        customerSupportUrl,
        licenseUrl,
        linkUrl,
        loginUrl,
        requestQuote,
        accountSubscriptions,
        planOptions,
        productBasePath,
    };
}
