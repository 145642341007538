import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import { t } from "utilities/localization-utils";
import useAdminTeamDetails from "./use-admin-team-details";
import { useParams } from "react-router-dom";
import TeamManagementArea from "pages/account/team-management/team-management-area";

const BASE_CSS_CLASSNAME = "c-teams-admin-detail-page";

interface Params {
    id: string;
}
const TeamsAdminDetailPage: React.FC<any> = () => {
    const { id: paramId } = useParams<Params>();

    const {
        team,
        subscription,
        subscriptionOwner,
        cycle,
        autoRenew,
        expirationDate,
        userRoleGroup,
    } = useAdminTeamDetails({ teamId: Number(paramId) });

    return (
        <section className={`${BASE_CSS_CLASSNAME}__section`}>
            <div className={`${BASE_CSS_CLASSNAME}__teamDetails`}>
                <Heading
                    priority={HeadingPriority.Three}
                    cssClassName={`${BASE_CSS_CLASSNAME}__sectionHeading`}>
                    {t("team")}
                </Heading>
                <Entry heading={t("team")} value={team?.name} />
                <Entry
                    heading={t("externalId")}
                    value={team?.externalIdentifier}
                />

                <Entry
                    heading={t("ownedBy")}
                    value={
                        <>
                            {subscriptionOwner?.fullName}
                            <br />
                            {subscriptionOwner?.email}
                        </>
                    }
                />
                <Entry
                    heading={t("subscription")}
                    value={subscription?.description}
                />
                <Entry heading={t("sku")} value={subscription?.sku} />
                <Entry heading={t("billing")} value={cycle} />
                <Entry heading={t("auto-renew")} value={autoRenew} />
                <Entry heading={t("expiration-date")} value={expirationDate} />
            </div>
            <div className={`${BASE_CSS_CLASSNAME}__subscriberDetails`}>
                <Heading
                    priority={HeadingPriority.Three}
                    cssClassName={`${BASE_CSS_CLASSNAME}__sectionHeading`}>
                    {t("team-member_plural")}
                </Heading>
                <TeamManagementArea
                    userRoleGroupToUse={userRoleGroup}
                    showInviteArea={false}
                    allowEdits={false}
                />
            </div>
        </section>
    );
};

const Entry = ({ heading, value }): JSX.Element => {
    return (
        <div className={`${BASE_CSS_CLASSNAME}__entry`}>
            <Heading
                priority={HeadingPriority.Three}
                cssClassName={`${BASE_CSS_CLASSNAME}__heading`}>
                {heading}
            </Heading>
            <Paragraph cssClassName={`${BASE_CSS_CLASSNAME}__value`}>
                {value}
            </Paragraph>
        </div>
    );
};

export default TeamsAdminDetailPage;
