import { BaseEnglishUnitedStates, Culture } from "@rsm-hcd/javascript-core";
import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";

const EnglishUnitedStates: Culture<CultureResources> = LocalizationUtils.cultureFactory<CultureResources>(BaseEnglishUnitedStates, {
    resources: {
        "aboutApp": "About {{appName}}",
        "acceptGroupInvitation-invitedByNameToJoin": "{{byName}} invited you {{toJoin}}",
        "acceptGroupInvitation-invitedToJoin": "You've been invited {{toJoin}}",
        "acceptGroupInvitation-join": "Join Team",
        "acceptGroupInvitation-mismatch": "This invitation does not match the email address you are currently signed in with. If you have multiple accounts with NFPA, you will need to first sign out before joining the new team.",
        "acceptGroupInvitation-switchProfiles": "You are using {{appNameTm}} as a member of {{teamName}}. Click on the account settings icon to switch profiles.",
        "acceptGroupInvitation-toJoin": "to join a team on {{appNameTm}}",
        "acceptGroupInvitation-unavailableTeamname": "Team name unavailable",
        "accepting": "accepting",
        "accessCodeIsInvalid": "Access code is invalid",
        "accessCodeIsRequired": "Access code is required.",
        "accessLink": "Access {{appNameTm}} Now",
        "account": "account",
        "accountDashboard-header": "Account Settings",
        "accountDashboard-manageAccount": "Manage your NFPA account information, change your password, or update payment options.",
        "accountDashboard-manageProfile": "Manage your profile on {{address}}",
        "accountDashboard-offline": "Offline",
        "accountDashboard-subscriptionDetails": "Subscription Details",
        "accountDashboard-teamManagement": "Team Management",
        "accountDashboard-workGroup": "Manage Workgroups",
        "accountInformationTab-header": "Account Information",
        "accountSettings": "Account Settings",
        "action-searchByFields": "Search by {{fields}}...",
        "actionBy": "{{action}} by ",
        "actionItem": "{{action}} {{item}}",
        "actionsForItem": "Actions for {{item}}",
        "activateTrial": "Activate Your Free Trial",
        "add": "Add",
        "add-change-summary": "Add/Edit Change Summary",
        "addBookmarkButton": "add bookmark button",
        "added": "Added",
        "addedJustNow": "Added just now",
        "addItem": "Add {{item}}",
        "addTo": "Add to {{container}}",
        "addItemTo": "Add {{item}} to {{container}}",
        "additionalPublications": "Additional Publications",
        "addNoteHere": "Add note here...",
        "addUserCollectionModalTeamMessage": "This collection will be visible to <1>{{teamSize}}</1> people on your team.",
        "addUserCollectionModalWorkGroupMessage": "This collection will be visible to the Workgroups you select below.",
        "addWorkGroupsShare": "Add Workgroups to share...",
        "admin": "Admin",
        "all": "all",
        "allBookmarks": "All Bookmarks",
        "allItem": "All {{item}}",
        "allOfItem": "All of {{item}}",
        "altService": "I have an alternative service",
        "and": "and",
        "annex_plural": "Annexes",
        "annex": "Annex",
        "announcements-whatsNew": "What's New",
        "annual": "Annual",
        "annually": "Annually",
        "article_plural": "Articles",
        "article": "Article",
        "auto-renew": "Auto-Renew",
        "automaticallyRenew": "automatically renew",
        "autoRenew": "I don’t want it to auto-renew",
        "available": "Available",
        "backToDashboard": "Back to Dashboard",
        "back": "Back",
        "backToLocation": "Back to {{location}}",
        "beginAddingBookmarks": "Begin bookmarking content in your publications.",
        "bestValue": "best value",
        "billing": "Billing",
        "billingCycle-goToNfpaToPurchase": "Complete Registration and Purchase",
        "billingCycle-individual-annual-autorenew": "Annual with auto-renewal",
        "billingCycle-individual-annual": "Annual",
        "billingCycle-individual-monthly-autorenew": "Monthly with auto-renewal",
        "billingCycle-planDetails": "{{subscriptionType}} Plan Details",
        "billingCycle-selectABillingCycle": "Select {{subscriptionType}} Billing Cycle",
        "billingCycle-selectABillingCycleToContinue": "Select a billing cycle to continue.",
        "billingCycle-team-annual-autorenew": "Annual with auto-renewal",
        "billingCycle-team-annual": "Annual",
        "black": "Black",
        "blue": "Blue",
        "book_plural": "Books",
        "book_update_error": "There was an error updating the book.",
        "book": "Book",
        "bookmark_plural": "Bookmarks",
        "bookmark": "Bookmark",
        "bookmarkColorFilterSelect-multiSelect-placeholder": "All Colors",
        "bookmarkModifiedText": "{{modifiedDate}} in {{publication}}",
        "bookmarkWithCount_plural": "{{count}} bookmarks",
        "bookmarkWithCount": "{{count}} bookmark",
        "bookviewContentWidthToggle_accessibleText": "Bookview content width expansion toggle",
        "both": "Both",
        "breadcrumbs-myLink-myBookmarks": "My Bookmarks",
        "breadcrumbs": "Breadcrumbs",
        "byUser": "by {{user}}",
        "browsePublications": "Browse Publications",
        "cancel": "Cancel",
        "cancelled": "Cancelled",
        "bypassHermes-feature-unavailable-tooltip": "This action is currently unavailable. Please try again later.",
        "cancelEnterpriseMessage": "To cancel your Enterprise Subscription please contact your NFPA sales representative.",
        "cancelItem": "Cancel {{item}}",
        "cancelInvite": "Cancel Invite",
        "cancellationTerm1": "If you opt to cancel today, it will take 3-5 business days for the cancellation of your subscription to take effect.",
        "cancellationTerm2": "If you cancel during a trial period, you may lose access to content immediately.",
        "cancellationTerm3": "If you cancel a monthly subscription, you can continue using the subscription until the next billing date.",
        "cancellationTerm4": "If you’ve pre-paid for an annual subscription, we cannot refund you for the unused portion of your subscription.",
        "cancellationTerm5": "Upon termination of your subscription, your notes and other personalized content will no longer be available.",
        "cancellationTermNotes": "Please note the following cancellation terms before proceeding to cancel your NFPA LiNK subscription:",
        "cancelMySubscription": "Cancel My Subscription",
        "cancelOrConfirmWithoutSaving": "Are you sure you want to continue without saving?",
        "category_plural": "Categories",
        "category": "Category",
        "changeEdition": "Change Edition",
        "changeEditionModal-selectPublicationEdition": "Select Publication Edition",
        "changeIndicatorLegend": "Legend",
        "changeIndicatorLegend-Revisions": "Shaded revisions",
        "changeIndicatorLegend-TIA": "TIA (Tentative Interim Amendment)",
        "changeIndicatorLegend-NewMaterial": "New material",
        "changeIndicatorLegend-Delta": "Text deletions and figure/table revisions",
        "changeIndicatorLegend-DeletedMaterial": "Deleted Material",
        "changeIndicatorLegend-SectionDeletions": "Section deletions",
        "changeIndicatorLegend-Moved": "Moved",
        "changesSuccessfullySaved": "Changes have been successfully saved.",
        "changeSummaryAction": "Change Summary {{action}} button",
        "changeSummaryHeader": "CHANGE SUMMARY",
        "changeTeamName": "Change Team Name",
        "chapter_plural": "Chapters",
        "chapter": "Chapter",
        "chapterNumber": "Chapter {{number}}",
        "checkOutFaq-link-label": "See answers to common questions.",
        "checkBackSoon": "Check back soon!",
        "checkOutFaq": "Check out our FAQs",
        "clearAll": "Clear All",
        "clearAllReferences": "Clear All References",
        "clearAllReferences-confirmationMessage": "Clearing all references will remove all your references from your reference panel.",
        "clearAllReferences_question": "Clear All?",
        "close": "Close",
        "closeItem": "Close {{item}}",
        "code": "Code",
        "collapse": "Collapse",
        "collapseAll": "Collapse All",
        "collection_plural": "Collections",
        "collection": "Collection",
        "collections-helpText": "To create collections go to MyLiNK > Collections",
        "collections": "Collections",
        "collectionVisibility": "Collection Visibility",
        "collectionTitle": "Collection Title",
        "collectionTitlePlaceholder": "Enter a collection title...",
        "collectionWithCount_plural": "{{count}} Collections",
        "collectionWithCount": "{{count}} Collection",
        "color_plural": "Colors",
        "color": "Color",
        "committeePersonnel": "Committee Personnel",
        "confirm": "Confirm",
        "completeRegistration": "Complete Registration",
        "confirmationMessage": "Are you sure you want to {{action}} this {{item}}?",
        "confirmItem": "Confirm {{item}}",
        "confirmRemoveUserFromTeam": "Are you sure you want to remove <2>{{user}}</2> from your team?",
        "contactModal-heading": "Submit a Question",
        "contactModal-preferredContact": "Preferred contact method",
        "contactModal-reasonLabel": "Reason for reaching out",
        "contactReasons-accountHelp": "I need help with my account",
        "contactPerson": "Contact Person",
        "contactMyOrganization": "Contact My Organization",
        "contactReasons-billing": "Pricing, billing & licensing help",
        "contactReasons-feedback": "General Feedback",
        "contactReasons-modifySubscription": "Modify my subscription",
        "contactReasons-other": "Something else",
        "contactReasons-renewEnterpriseSubscription": "Renew Enterprise Subscription",
        "contactReasons-suggestions": "Feature request or suggestion",
        "contactReasons-technical": "Technical issue",
        "contactSalesToRenew": "Contact Sales to Renew",
        "contactUs": "Contact Us",
        "content": "Content",
        "continue": "Continue",
        "createAnAccount": "Create An Account",
        "createANewTeamToStartYourSubscription": "Create a new team to start your subscription.",
        "createNewTeam": "Create New Team",
        "continueToPayment": "Continue to Payment",
        "continueToPurchase": "Continue to Purchase",
        "copy": "Copy",
        "creating": "creating",
        "customerService": "I had poor customer service",
        "customizeExperience": "Customize Experience",
        "customizeExperience-selectFavorites-subheader": "We found some publications based on your topics of interest. Favorite the ones that you like and we will add them to your dashboard. Don’t worry you can remove or add more publications at any time on your dashboard.",
        "createdBy": "Created by",
        "day": "Day",
        "delete": "Delete",
        "deletedChangedIndicatorHeading": "Deleted",
        "deleteItem": "Delete {{item}}",
        "deletingItem": "Deleting {{item}}",
        "deletedSectionBookmark": "This section has been removed since you bookmarked it.",
        "deviceStorageUsed": "{{amount}} of device storage used",
        "dialog": "Dialog",
        "directBackButton-backToResults": "Back to Results",
        "diffPanelHeaderChanged": "Changed Content",
        "diffPanelHeaderDeleted": "Deleted Content",
        "diffPanelHeaderNew": "New Content",
        "diffPanelSubHeader": "Text from previous edition:",
        "diffPanelSubHeaderNew": "This content was not in the previous edition.",
        "diffPanelSubHeaderPriorEdition": "Please see the prior edition for these changes.",
        "disclaimerText-disclaimer": "Disclaimer",
        "disclaimerText-disclaimerBodySection1": "You acknowledge and agree that regardless of how information may be labeled or displayed, NFPA is not endeavoring to provide professional engineering services or advice through the service or the information.",
        "disclaimerText-disclaimerBodySection2": "Each NFPA code, standard, or other document contained within the information has its own disclaimers and statements dealing with limitation of liability. The terms of each such statement shall remain in effect and be in addition to anything set forth herein.",
        "done": "Done",
        "download": "Download",
        "download_plural": "Downloads",
        "downloading": "Downloading",
        "duplicateUserCollectionError": "There is already a collection with this name. Please try a different name.",
        "earlierVersion": "Earlier Version",
        "ecommerceRedirectionModal-shortenedDescription": "You are being redirected to NFPA.org",
        "ecommerceRedirectionModal-defaultDescription": "You are being redirected to NFPA.org to finish your purchase.",
        "edit": "Edit",
        "edited": "Edited",
        "edition": "Edition",
        "editItem": "Edit {{item}}",
        "eligibleForTrialBody": "Expert commentary, visual aids, robust search, bookmarks, and notations - online or offline. Sign up for a free 2-week trial to see how {{AppNameWithHtml}} can change the way you work.",
        "effective": "Effective",
        "effectiveBefore": "Effective before",
        "email-multi-input-placeholder": "Enter email addresses",
        "email-required-field": "Email is required.",
        "email-template": "Email Template",
        "email": "Email",
        "enhancedContent": "Enhanced Content",
        "organizationSupportWebsite": "{{organizationName}} Support Website",
        "orVisit": "or visit",
        "enterADescription": "Enter a Description (optional)...",
        "enhancedcontent_plural": "Enhanced Content",
        "enterAField_possessive": "Enter your {{fieldName}}",
        "enterAField": "Enter a {{fieldName}}",
        "enterAMessage": "Enter a Message",
        "enterTeamName": "Enter Team Name",
        "entireTeam": "Entire team",
        "enterYourAccessCode": "Please enter your access code to get started.",
        "enterpriseConfirmationModal-heading": "Your request has been submitted.",
        "equation": "Equation",
        "errors-actionResource_plural": "There was an issue {{action}} {{resource}}.",
        "error-loading-bookmarks": "There was an issue loading user bookmarks.",
        "error-loading-identity-provider": "There was an issue loading identity provider details.",
        "error-loading-organization": "There was an issue loading organization details.",
        "errors-actionResource_possessive": "There was an issue {{action}} your {{resource}}.",
        "errors-actionResource": "There was an issue {{action}} this {{resource}}.",
        "exceedsCharacterLimit": "Exceeds {{limit}} character limit",
        "exception": "Exception",
        "expand": "Expand",
        "expandAll": "Expand All",
        "expandItem": "Expand {{item}}",
        "expiration-date": "Expiration Date",
        "expire": "expire",
        "expired-offline-session-alert-description": "You have not logged in for 21 days. You need to re-establish an internet connection and log in to access offline content.",
        "expired-offline-session-alert-title": "Your offline session has expired",
        "expired-offline-session-warning-description": "You have not logged in for 21 days. If you do not re-establish an internet connection within the next 24 hours, you will not be able to access offline content.",
        "expired-offline-session-warning-title": "Your offline session will expire in 24 hours",
        "expiredLink": "Expired Link",
        "faq": "Frequently Asked Questions",
        "externalId": "external id",
        "extend": "Extend",
        "favorite_plural": "Favorites",
        "favorite": "Favorite",
        "favorited": "Favorited",
        "favoriteItem": "Favorite a {{item}}",
        "featuredBook-admin-checkbox": "Feature on Dashboard",
        "featuredPublications-availableNow": "<0>Available Now</0> in {{appName}}",
        "featuredPublications-browseAll": "Not seeing what you're looking for? <0>Browse All Available Publications</0> in {{appNameTm}}",
        "featuredPublications-c2a-buttonText": "Select a plan",
        "featuredPublications-c2a-text": "Ready to get started?",
        "featuredPublications-comingSoon": "Coming Soon",
        "featuredPublications-header": "All New and Upcoming Content Included with Your Subscription",
        "featuredPublications-notifications": "<0>Sign up to receive notifications</0> when new content is available.",
        "featuredPublications-subText": "As an {{appName}} subscriber, you will have access to all the content as it is added during your subscription term, at <0>no additional charge.</0>",
        "featuredPublications": "Featured Publications",
        "field-description": "Description",
        "field-email_plural": "Email addresses",
        "field-email": "Email address",
        "field-firstName": "First name",
        "field-fullName": "Name",
        "field-invalid": "{{name}} is invalid",
        "field-lastName": "Last name",
        "field-notAvailable": "{{field}} not available",
        "field-notes": "Notes",
        "field-optional": "(optional)",
        "field-phoneNumber": "Phone number",
        "field-title": "Title",
        "figure": "Figure",
        "filter_plural": "Filters",
        "filter": "Filter",
        "filterBy": "$t(filter) by {{item}}",
        "filteredByWithCount": "Filtered by {{count}} {{filter}}",
        "filterLabel-clearWithCount_plural": "Clear {{count}} Filters",
        "filterLabel-clearWithCount": "Clear {{count}} Filter",
        "filterLabelWithCount_plural": '$t(bookmarkWithCount_plural, {"count": {{count}} }) filtered by',
        "filterLabelWithCount": "{{count}} bookmark filtered by",
        "firstResultsPage": "First Results Page",
        "forgotPassword": "Forgot Password",
        "forgotYourPassword": "Forgot Your Password",
        "forHelp": "For help",
        "freeTrialBillingCycle-goToNfpaToPurchase": "Complete Registration",
        "free-access-nav-tooltip-direct-description": "Navigate to real-life situations built by NFPA experts. Get aggregated information with visual aids & links to relevant code sections.",
        "free-access-nav-tooltip-direct-title": "NFPA DiRECT<sup>®</sup>",
        "free-access-nav-tooltip-mylink-description": "Quickly access customized bookmarks, notes, and curated collections. Collaborate and share notes with your peers with a team subscription.",
        "free-access-nav-tooltip-search-description": "Search across publications and expert commentary using keywords and filtering options.",
        "free-access-nav-tooltip-search-title": "Search NFPA LiNK<sup>®</sup>",
        "freeAccessPrintTitle": "Free Access - NFPA {{code}}: {{title}}",
        "freeTrialCTA": "Your Free Trial ends in <2>{{endingDate}}</2>. <5><1>Upgrade your plan</1></5> to continue using content on demand.",
        "freeTrialTerms": "No payment information is required to enroll in a free trial. Access will expire after 14 days unless you choose to subscribe to a paid plan. By signing up for the {{appNameWithHtml}} free trial, you agree to be contacted about product features and purchase options.",
        "freeTrialTermsAndConditions-mustAgreeToContinue": "You must accept the free trial agreement to continue.",
        "freeTrialAgreementCheckBoxLabel": "Agree to Free Trial Agreement",
        "freeTrialAgreementLabel": "Free Trial Agreement",
        "freeTrialUpgradeMessage": "Upgrade your plan to continue using content on demand.",
        "freeTrialNewUserLoginFormSubmitButtonText": "Sign In and Register",
        "gettingStarted": "Getting started with NFPA LiNK",
        "freezeAccountCreationAndChangesMessage": "Account registration and subscription purchases are temporarily unavailable, but if you have an existing NFPA LiNK® subscription you can access your account below.",
        "freezeSubscriptionsMessageForTooltip": "Account registration and subscription purchases are temporarily unavailable.",
        "get-users-error": "There was an error getting users",
        "getTeamsError": "Error Getting teams",
        "gotIt": "Got it!",
        "goToItem": "Go to Item",
        "goToMyAccount": "Go to My Account",
        "gotoResultPage": "Go to Result Page {{pageNumber}}",
        "green": "Green",
        "groupInvite-cancelingInvitationError": "There was an issue canceling the invitation.",
        "groupInviteHasReachedMaxLicenses": "Group has reached the maximum number of licenses: {{totalLicenses}} licenses. Please remove some pending invites to add more.",
        "group": "Group",
        "groupInvite-deletePendingInvitationError": "There was an issue deleting pending invitations from the old team.",
        "groupInvite-cancelingInvitationSuccess": "The invitation was successfully cancelled.",
        "groupInvite-invitationInputHelperText": "To invite multiple team members at once, separate email addresses with a comma (e.g. user@domain.com, user@domain.com)",
        "groupInvite-sendInvitationError": "There was an issue sending the invitation, refresh the page and try again.",
        "groupInvite-sentToRecipient_plural": "Team invitations have been sent to all recipients",
        "groupInvite-sentToRecipient": "Team invitation has been sent to {{recipient}}",
        "header": "Header",
        "haveSubscription": "You already have a subscription.",
        "hide": "Hide",
        "hermes-off-banner-default": "Some functionality might be limited currently. Please try again later.",
        "hiddenTiaContent": "This {{item}} may have hidden deleted content.",
        "hiddenTiaAndEnhancedContent": "This {{item}} may have hidden deleted and enhanced content.",
        "hiddenEnhancedContent": "This {{item}} may have hidden enhanced content.",
        "hide-earlier-editions": "Hide Earlier Editions",
        "home": "Home",
        "ifYouHaveAnyIssues": "If you have any issues",
        "includeEnhancedContent": "Include Enhanced Content",
        "individual": "Individual",
        "individualizedExperience": "Individualized Experience",
        "information": "Information",
        "informationalNote": "Informational Note",
        "informativeAnnex_plural": "Informative Annexes",
        "informativeAnnex": "Informative Annex",
        "inputHintText": "Hint: {{text}}",
        "introduction": "Introduction",
        "invalid-email-field": "Please enter a valid email address",
        "invalid-emails-field": "The following email addresses are invalid: {{emails}}",
        "invalidShareLink": "It looks like your share link has expired or is invalid. Share links only stay active for 14 days.",
        "invitation": "invitation",
        "invite": "Invite",
        "invited": "Invited",
        "inviteTeamMembers": "Invite Team Members",
        "issueSyncingGroup": "There was an issue syncing this Group.",
        "itemCount": "{{count}} {{item}}",
        "itemNotAvailable": "{{item}} Not Available",
        "itemView": "{{item}} View",
        "justYou": "Just you",
        "job_plural": "Jobs",
        "landingPageContent": "<0>Whether you’re an individual, a team, or a large enterprise, NFPA LiNK® provides instant access — via your favorite device — to NFPA® codes and standards, plus exclusive expert commentary, visual aids, and more.</0>",
        "landingPageTitle": "<0>NFPA LiNK® puts our trusted knowledge base at your fingertips.</0>",
        "language_plural": "Languages",
        "language": "Language",
        "languagePreferences": "Language Preferences",
        "lastResultsPage": "Last Results Page",
        "laterVersion": "Later version",
        "less": "Less",
        "learnEvenMore": "Learn Even More",
        "learnMore": "Learn More",
        "readonlyPublicationDismiss": "Dismiss",
        "readonlyPublicationAbout": "LiNK provides read-only access to older editions of certain NFPA publications. These publications are displayed in an image viewer that does not offer the same interactive features that are available in current publications.",
        "readonlyPublicationAboutTitle": "Read-Only Publications",
        "limitedTime": "I only needed it for a limited time",
        "loadingSession": "Loading session...",
        "linkWillExpireWarning": "This link will expire in {{expiration}}.",
        "logIn": "Log In",
        "logInToLink": "Log in to LiNK",
        "loading": "loading",
        "manageAccount": "Manage Account",
        "manageTeam": "Manage Team",
        "logOut": "Log Out",
        "loadingItem": "Loading {{item}}...",
        "login-offline-warning": "Connect to a data or wifi signal to log in",
        "manageTeamsRemoveMember": "Remove Member",
        "manageTeamsErrorUpdatingTeam": "There was an isue saving team details",
        "manageTeamsErrorUpdatingMembers": "There was an issue updating team members",
        "manageWorkGroups-heading": "Manage Workgroups",
        "manageWorkGroups-newWorkGroupModal-button": "New Workgroup",
        "manageWorkGroups-searchForm-accessibleText": "Search for WorkGroups",
        "manageWorkGroups-searchForm-placeholder": "Search Workgroups",
        "manageTeamSettings": "Manage Team Settings",
        "menu": "Menu",
        "message": "Message",
        "minute_plural": "Minutes",
        "minute": "Minute",
        "minuteWithCount_plural": "{{count}} Minutes",
        "minuteWithCount": "{{count}} Minute",
        "modal": "Modal",
        "modify": "Modify",
        "monthAbbreviation": "mo",
        "moreQuestions": "<0>Have more questions? Visit the <1><0>{{appName}} support page</0></1> to view more detailed FAQs.</0>",
        "my-collections": "My Collections",
        "monthly": "Monthly",
        "more": "More",
        "movedChangedIndicatorHeading": "Moved",
        "movedChangedIndicatorLocation": "This text has been moved from {{priorLocation}}",
        "movedChangedIndicatorLocationAnnex": "This text has been moved from Annex {{priorLocation}}",
        "movedChangedIndicatorLocationChapter": "This text has been moved from Chapter {{priorLocation}}",
        "movedWithChangesIndicatorLocation": "Text from previous edition (moved from {{priorLocation}}):",
        "movedWithChangesIndicatorLocationAnnex": "Text from previous edition (moved from Annex {{priorLocation}}):",
        "movedWithChangesIndicatorLocationChapter": "Text from previous edition (moved from Chapter {{priorLocation}}):",
        "movedWithChangesIndicatorLocationJurisdictional": "Text from NFPA code, prior to jurisdictional amendments (originally {{priorLocation}}):",
        "movedWithChangesIndicatorLocationAnnexJurisdictional": "Text from NFPA code, prior to jurisdictional amendments (originally Annex {{priorLocation}}):",
        "movedWithChangesIndicatorLocationChapterJurisdictional": "Text from NFPA code, prior to jurisdictional amendments (originally Chapter {{priorLocation}}):",
        "my-current-plan": "My Current Plan",
        "myBookmarksTab-metaTags-description": "{{appName}}: My Bookmarks",
        "myBookmarksTab-noBookmarksResultsBanner-subTitle": "Start adding bookmarks from publications.",
        "myBookmarksTab-noBookmarksResultsBanner-title": "No Bookmarks Added",
        "myBookmarksTab-success-removeBookmark": "Bookmark has been removed.",
        "myBookmarksTab-success-removeBookmarkFromCollection": "Bookmark has been removed from collection.",
        "myBookmarksTabFilters-searchForm-accessibleText": "Search bookmarks",
        "myBookmarksTabFilters-searchForm-placeholder": "Search by title",
        "mylinkLayout-tabs-label-myBookmarks": "My Bookmarks",
        "narrowView": "Narrow View",
        "name": "Name",
        "nav-aria-direct-link": "Go to DiRECT",
        "nav-aria-mylink-link": "Go to MyLiNK",
        "nav-aria-search-link": "Open Search Menu",
        "nav-aria-user-menu-link": "User Menu",
        "nav-tooltip-direct-description": "Find codes and standards based on your situation",
        "nav-tooltip-direct-title": "DiRECT",
        "nav-tooltip-home-description": "Publications and recent bookmarks",
        "nav-tooltip-home-title": "Home",
        "nav-tooltip-mylink-description": "Manage your bookmarks and collections",
        "nav-tooltip-mylink-title": "MyLiNK",
        "nav-tooltip-search-description": "Find codes and standards by keyword",
        "nav-tooltip-search-title": "Search",
        "nav-tooltip-sparky-search-title": "Sparky Search",
        "nav-tooltip-sparky-search-description": "Find your answers with the help of Sparky!",
        "needHelpSigningIn": "Need Help Signing In?",
        "nevermindKepSubscription": "Nevermind, Keep Subscription",
        "newCollection": "New collection has been created",
        "newItem": "New {{item}}",
        "newUserLoginFormSubmitButtonText": "Sign In and Purchase",
        "newUserLoginForm-errors-currentRoleExpired": "Your NFPA LiNK subscription has expired and you will lose access soon. Please contact customer service.",
        "newUserLoginForm-errors-loginFailed": "Login failed. Please provide a valid email and password.",
        "newUserLoginForm-errors-loginGeneral": "There was a problem logging you in. Please try again.",
        "newUserLoginForm-loader-accessibleText": "Signing in...",
        "newUserLoginForm-loggedInAs-memberOf": "You are logged in as a member of {{teamName}}",
        "newUserLoginForm-loggedInAs-user": "You are logged in as {{username}}",
        "newUserLoginPage-callToAction": "Sign up for {{appName}} to gain access to these features:",
        "newUserLoginPage-errors-loggingOut": "There was an error logging out. Please refresh the page.",
        "newUserLoginPage-errors-selectAnotherRole": "Your current role has expired or been removed. Please sign in again to be assigned a role.",
        "newUserLoginPage-meta-description": "Sign up for {{appName}} to gain access to NFPA Codes & Standards and enhanced & related content.",
        "newWorkgroupPlaceholder": "Type to name workgroup",
        "nextItem": "Next {{item}}",
        "no-invites-available": "No licenses available.",
        "nextResultsPage": "Next Results Page",
        "nfpaLogo": "NFPA Logo",
        "nfpaVersion": "NFPA Version",
        "noCollections": "No Collections",
        "none": "None",
        "noOptionsAvailable": "No options available.",
        "noOtherCollections": "No Other Collections",
        "noSearchResultsBanner-subTitle": "Try another search or remove filters.",
        "noSearchResultsBanner-title": "0 Results",
        "notEligibleForTrialBody": "Expert commentary, visual aids, robust search, bookmarks, and notations - online or offline.",
        "not-found-resource": "The {{resource}} requested was not found",
        "not-found-page-button-text": "Go to Dashboard",
        "notEnoughSeatsForCurrentSubscription": "Not enough seats for current subscription.",
        "notAddedWorkgroups": "You have not been added to any workgroups yet.",
        "not-found-page-description": "Sorry, we couldn't find the page you were looking for.",
        "not-found-page-title": "Not Found",
        "noteTextTooLong": "Note text is too long",
        "notFavorited": "Not favorited",
        "notUseful": "I don't think the product is useful",
        "now": "now",
        "off": "Off",
        "offline-banner-default": "Some functionality might be limited while in offline mode. You must establish connectivity to use all features of {{appName}}.",
        "offline-books-empty-banner-title": "You have nothing downloaded on this device yet",
        "offline-dashboard-banner-message": "You are offline. Only downloaded publications are being displayed.",
        "offline-dashboard-empty-banner-subtitle": "Connect to a Wi-Fi network to store publications to your device for offline viewing. You can download publications by selecting the 'offline settings' option from the menu displayed on each of the book covers.",
        "offline-dashboard-recent-bookmarks-disabled-description": "You must establish connectivity to use all features of {{appName}}.",
        "offline-dashboard-recent-bookmarks-disabled-title": "Recent bookmarks are disabled while offline.",
        "offline-data-informational-message": "This content will be stored for offline access with the current account and device/browser. Logging in with a different account or device/browser will require re-downloading this content, including any bookmarks and enhanced content.",
        "offline-download-browser-warning": "Do not close the browser until the download has completed.",
        "offline-fallback-description": "You must connect to a Wi-Fi network to use this feature.",
        "offline-fallback-title": "You Are Currently Offline",
        "offline-loading-text": "Preparing your offline search experience",
        "offline-removeOfflineBookError": "An error occurred when trying to remove an offline book.",
        "offline-settings-bookmarks-label": "Include bookmarks and notes",
        "offline-settings-bookmarks-tooltip": "Save all your bookmarks and notes from this publication for offline access.",
        "offline-settings-enhanced-content-label": "Include enhanced content",
        "offline-settings-enhanced-content-tooltip": "In addition to the code content, save any expert commentary included in this publication for offline access.",
        "offline-settings-header": "Offline Settings",
        "offline-settings-store-description": "We'll cache this to your device in the background while you continue using LiNK normally",
        "offline-settings-store-label": "Store for Offline Use",
        "offline-storage-limit": "There is not enough space to continue downloading {{publication}}. Please remove another offline publication, or free up space on your device.",
        "offline-syncErrorMessage": "An error occurred when trying to sync an offline book.",
        "offline-tab-empty-banner-subtitle": "Connect to a Wi-Fi network to store publications to your device for offline viewing. You can download publications by selecting the 'offline settings' option from the menu displayed on each of the book covers.",
        "offline-unsupported-browser": "We've detected that your browser does not support features necessary for offline access. If possible, please upgrade to a modern browser.",
        "offlineBookDownloadStatus-downloaded": "Downloaded To Device",
        "offlineBookDownloadStatus-updateAvailable": "Update Available",
        "offlineTab-buildOfflineSearchIndex": "Build Offline Search Index",
        "offlineTab-buildSearchIndex": "Build Search Index",
        "okay": "Okay",
        "offlineTab-buildingSearchIndex": "Building Search Index...",
        "on-demand-already-on-team": "You're already part of this team.",
        "on-demand-already-on-team-1": "It looks like you already have an",
        "on-demand-already-on-team-2": "account associated with this team.",
        "on-demand-contact-admin": "Didn't receive an email? Please contact your team administrator.",
        "on-demand-contact-admin-1": "If you believe you are receiving this message in error, please contact your team administrator.",
        "on-demand-enter-email": "Please enter your email address to get started.",
        "on-demand-login": "Sign in to your account.",
        "on-demand-no-invites-1": "We're sorry, but it looks like your team has reached maximum capacity. Please contact your team administrator for more information.",
        "on-demand-no-invites-contact-admin": "If you believe you've received this message in error, please contact your team administrator.",
        "on-demand-receive-email-1": "You'll receive an email with further instructions for setting up your",
        "on-demand-receive-email-2": "account.",
        "on-demand-sign-in": "It looks like you have already submitted your email address to enroll in",
        "on-demand-sign-in-1": ". Please check your inbox to continue activating your account.",
        "on-demand-sign-up-1": "Your request was successfully submitted. Please check your inbox to activate and set up your",
        "on-demand-team-header-1": "Sign up for",
        "on-demand-team-header-2": "with your organization",
        "on-demand-thank-you": "Thank you.",
        "on-demand-unrecognized-email": "We're sorry, but it looks like this email address doesn't match the team you're trying to join. Please",
        "on-demand-unrecognized-email-1": "Email address not recognized.",
        "offlineTab-downloadFavorites": "Download Favorites For Offline Use",
        "on": "On",
        "oopsErrorOccurred": "Oops, an error occurred!",
        "open": "Open",
        "openInBookView": "Open in Book View",
        "openItem": "Open {{item}}",
        "openNavigation": "Open Navigation",
        "option": "option",
        "orgAccountSettingsSupportText": "If you are part of an organization account and have any issues, contact our specialist below.",
        "orgLoginHeading": "Accessing NFPA LiNK® requires your organization credentials",
        "orgLoginHelpText": "For issues logging in, please <2>contact your organization for help</2>.",
        "orgLoginSubheading": "Please login through your organization and you will be redirected to our website.",
        "organization": "Organization",
        "organizationNameUnavailable": "Organization Name Unavailable.",
        "accountSupport": "Account Support",
        "option_plural": "options",
        "or": "or",
        "ownTheEntirePrintPublication": "Own the entire print publication",
        "ownedBy": "Owned by",
        "owner": "Owner",
        "ownerEmail": "Owner Email",
        "ownerName": "Owner Name",
        "origins": "Origins",
        "other": "Other",
        "pageNavigation-noResults-label": "Showing 0 Results",
        "pageNavigation-pageSize-label": "Page Size",
        "pageNavigation-results-label": "{{startIndex}}-{{endIndex}} of {{resultTotal}}",
        "part": "Part",
        "part_plural": "Parts",
        "password": "Password",
        "passwordMismatch": "Passwords do not match",
        "passwordRequirements": "Must be at least 6 characters long and contain 3 of the 4 following character types: 'UpperCase', 'LowerCase', 'Number', or 'Special Character'.",
        "pendingInvitations": "pending invitations",
        "pendingInvitiationsMessage_plural": "You have {{count}} $t(pendingInvitations)",
        "pendingInvitiationsMessage": "You have {{count}} pending invitation",
        "phone": "Phone",
        "plansPage-browseAllPublications": " <0>Browse All Available Publications</0> in {{appName}}.",
        "plansPage-exploreAllPublications": "Explore a list of <1>available publications</1> to find out what codes and standards are included with a subscription to {{appName}}.",
        "pin": "Pin",
        "pink": "Pink",
        "plansPage-freeTrial-callToAction": "Try for 14 days free!",
        "plansPage-freeTrialExpired-subtitle": "Thanks for trying {{appName}}. Please purchase a subscription to continue using the product.",
        "plansPage-freeTrialExpired-title": "Your {{appName}} 14 day free trial has ended.",
        "plansPage-interestedInTryingLink": "Interested in trying {{appName}}?",
        "plansPage-pageTitle": "{{appName}} Plan Selection",
        "plansPage-subscriptionExpired-content": "{{appName}} is a breakthrough digital platform providing instant access to NFPA codes and standards, plus exclusive expert commentary, visual aids, and more. Explore a list of <2>available publications</2> to find out what codes and standards are included with a subscription to {{appName}}.",
        "plansPage-seeUpcomingPublications": "See the list of upcoming publications.",
        "plansPage-selectPlanTitle": "Select an {{appName}} Plan to Get Started",
        "playVideo": "Play video",
        "plansPage-subscriptionExpired-subtitle": "Purchase a subscription below to continue using the product.",
        "plansPage-subscriptionExpired-title": "Your {{appName}} subscription has expired.",
        "preference_plural": "Preferences",
        "preference": "Preference",
        "preferences": "Preferences",
        "preferredLanguage": "Preferred Language",
        "previousItem": "Previous {{item}}",
        "previousResultsPage": "Previous Results Page",
        "price": "Price",
        "primary": "Primary",
        "privacyPolicy": "Privacy Policy",
        "providedBy": "Provided  By",
        "priorText": "Prior Text",
        "propertyIsRequired": "{{name}} is required.",
        "publication_plural": "Publications",
        "publication-admin-sectionTitleHasLabel-checkbox": "Section Titles Have Labels",
        "publication-batch_start-job-later": "Schedule Job",
        "publication-batch_start-job-later-description": "Scheduling non-rush jobs saves server resources and reduces expenses. Your job will be scheduled for tonight and will be done by the start of your day tomorrow.",
        "publication-batch_start-job-now": "Start Job Now",
        "publication-batch_start-job-now-description": "Your processs will start immediately. You will receive an alert when the queue has completed.",
        "publication": "Publication",
        "publicationCover": "publication cover",
        "publicationPage": "publication page",
        "publicationFavorites-errors-addingToFavorites": "There was an issue adding {{codeAndEdition}} to favorites.",
        "publicationOptionsMenu": "Publication Options Menu",
        "publicationFavorites-errors-removingFromFavorites": "There was an issue removing {{codeAndEdition}} from favorites.",
        "publicationPage-addedToFavorites": "{{codeAndEdition}} added to your dashboard favorites",
        "publicationPage-addToFavorites": "Add to your dashboard favorites",
        "publicationPage-removedFromFavorites": "{{codeAndEdition}} removed from your dashboard favorites",
        "publicationPreview-callToAction-public": "Sign up to gain full access to all of our codes and standards.",
        "publicationPreview-callToAction-signedIn": "You have access to the publication’s full content in your LiNK account.",
        "publicationPreview-previewMode": "You are in Preview Mode",
        "publicationPage-removeFromFavorites": "Remove from your dashboard favorites",
        "publicationSelectionModal-searchPlaceholder": "Search by title or number",
        "publish": "Publish",
        "publishChanges": "Publish Changes",
        "published": "Published",
        "publishStatusMenu-deleteConfirmation-message": "Are you sure you want to delete this {{item}}?",
        "publishStatusMenu-publishChangesConfirmation-message": "Are you sure you want to publish changes to this {{item}}?",
        "publishStatusMenu-publishConfirmation-message": "Are you sure you want to publish to this {{item}}?",
        "publishStatusMenu-statusText-changesSaved": "Changes Saved",
        "publishStatusMenu-statusText-unpublishedChangesSaved": "Unpublished Changes Saved",
        "publishStatusMenu-unpublishConfirmation-message": "Are you sure you want to unpublish this {{item}}?",
        "purple": "Purple",
        "reachOutToAccountAdministratorsMessage": "Please reach out to your account administrator(s) with questions regarding your subscription:",
        "quickStartGuide-link-label": "Learn how to use NFPA LiNK core features.",
        "quickStartGuide": "Quick Start Guide",
        "readLess": "Read less",
        "readMore": "Read more",
        "readOnly": "Read-Only",
        "reason": "Reason",
        "reasonForCancellation": "Reason for cancellation*",
        "recentBookmarks-emptyState": "No Recent Bookmarks",
        "recentBookmarks": "Recent Bookmarks",
        "red": "Red",
        "redirecting": "Redirecting",
        "reference_plural": "References",
        "reference": "Reference",
        "reference-panel-add-to": "Add to Reference Panel",
        "reference-panel-empty-action-text": "Select '{{action}}' on reference links.",
        "reference-panel-empty-references": "0 References Added",
        "reference-panel-menu": "{{action}} References",
        "reference-panel-open-in": "Open in Reference Panel",
        "refresh": "Refresh",
        "registerAccountPageMetaTagDescription": "Sign up for NFPA LiNK®  to gain access to NFPA Codes & Standards, expert commentary & visual aids, bookmarking & custom collections, situation-based code navigation, sharing, and more.",
        "refreshing": "Refreshing...",
        "refreshingInNSeconds": "Refreshing in {{timeInSeconds}} seconds",
        "registerAccountPageMetaTagTitle": "NFPA LiNK® : Create an NFPA Account",
        "registerAccountPageTitle": "NFPA LiNK® Account Registration",
        "registerHeading": "Register for {{appNameWithHtml}}",
        "registerHeading-freeTrial": "Try {{appNameWithHtml}} for 14 days free!",
        "registerOrLogin-loginHeader": "Log in with your existing NFPA.org account to register.",
        "registerOrLogin-loginQuestion": "Don't have an NFPA account?",
        "registerOrLogin-signUpHeader": "Create an NFPA.org account to register for {{appNameWithHtml}}",
        "registerOrLogin-signUpQuestion": "Already have an NFPA.org account?",
        "registerOrLogin-signinHeader": "Sign in with your existing NFPA.org account to register.",
        "relatedResource_plural": "Related Resources",
        "registrationEmail": "Please create an account using the invitation email:",
        "relatedResource": "Related Resource",
        "remainingInvitesMessage_plural": "You have {{count}} invites remaining ({{pending}})",
        "remainingInvitesMessage": "You have {{count}} invite remaining ({{pending}})",
        "rememberMe": "Remember Me",
        "remove": "Remove",
        "removeFrom": "Remove from {{container}}",
        "removeFromTeam": "Remove from Team",
        "removeItemFrom": "Remove {{item}} from {{container}}",
        "removing": "removing",
        "removingItemFrom": "Removing {{item}} from {{container}}...",
        "renameItem": "Rename {{item}}",
        "renew": "renew",
        "renewal-date": "Renewal Date",
        "resend": "Resend",
        "reset": "Reset",
        "resourceSection-resourceCard-documents-accessibleText": "Download document {{title}}",
        "resetItHere": "Reset it here",
        "resourceSection-resourceCard-externalVideos-accessibleText": "View {{title}}",
        "resourceSection-resourceCard-files-accessibleText": "Download file {{title}}",
        "resourceSection-resourceCard-image-accessibleText": "Download image {{title}}",
        "resourceSection-resourceCard-video-accessibleText": "View video {{title}}",
        "resourceType-Document_plural": "Documents",
        "resourceType-Document": "Document",
        "resourceType-File_plural": "Files",
        "resourceType-File": "File",
        "resourceType-Image_plural": "Images",
        "resourceType-Image": "Image",
        "resourceType-Link_plural": "External Videos",
        "resourceType-Link": "External Video",
        "resourceType-Video_plural": "Videos",
        "resourceType-Video": "Video",
        "result_plural": "{{count}} Results",
        "result": "{{count}} Result",
        "resultsPerPage": "Results per page:",
        "save": "Save",
        "saveChanges": "Save Changes",
        "saving": "Saving",
        "savingChanges": "Saving Changes...",
        "scope": "Scope",
        "search": "Search",
        "searchHintText": "Use quotation marks “ “ for an exact match.",
        "searchItem": "Search {{item}}",
        "searchModal-AllCurrentEditions": "All Current Editions",
        "searchModal-AllOfflineEditions": "All Offline Editions",
        "searchModal-CodesAndStandards": "Codes and Standards",
        "searchModal-FilterByOfflinePublications": "Filter by Offline Publications",
        "searchModal-FilterByPublication": "Filter by Publication",
        "searchModal-MenuLabel": "Search Menu",
        "searchModal-OfflineWarning": "Search functionality may be limited while in offline mode. Connect to wifi or a data signal for all search features.",
        "searchModal-ResultFilters": "Search Result Filters",
        "searchModal-SearchResults": "Search Results",
        "searchModal-FindMatchingPublications": "Find Matching Publications",
        "searchModal-MatchingCodesAndStandards": "Matching Codes & Standards",
        "searchModal-SearchResultsFound": "{{count}} Results Found for {{key}}",
        "searchPlaceholder": "Search by keyword, section, article, chapter",
        "searchby-nameOrEmail": "Search by Name or Email",
        "searchUsers": "Search to add Users",
        "second_plural": "Seconds",
        "second": "Second",
        "secondary": "Secondary",
        "secondWithCount_plural": "{{count}} Seconds",
        "secondWithCount": "{{count}} Second",
        "section_plural": "Sections",
        "section-detail-menu-feature-name": "Bookmarks and Sharing",
        "section": "Section",
        "sectionLinkCardList-error-loadingChildSections": "There was an issue reading child sections",
        "sectionShared": "Section shared.",
        "sections-include-subsection-loading-error": "Error loading section preview.",
        "sections-include-subsections-not-found": "No section or publication found.",
        "select": "Select",
        "select-plan": "Select Plan",
        "selectAnOption": "Select an option",
        "selected": "Selected",
        "selectAPlanToGetStarted": "Select a Plan to Get Started",
        "selectItem": "Select {{item}}",
        "selectFavoritesPage-banner": "Don't see the publication you need? Continue to your dashboard to view the full list of publications.",
        "selectFavoritesPage-description": "We found some publications based on your topics of interest. Favorite the ones you use most and they will appear on your personalized dashboard. You can add and remove Favorites at any time from your dashboard.",
        "selectFavoritesPage-description-featuredPublications": "These are some of our most popular titles. Favorite the ones you use most and they will appear on your personalized dashboard. You can add and remove Favorites at any time from your dashboard.",
        "selectFavoritesPage-header": "Select Favorites (optional)",
        "selectFavoritesPage-nextButton": "Continue to Dashboard",
        "selectTopicsPage-dashboardLink": "Go to Dashboard",
        "selectYourTopicsOfInterest": "Select Your Topics of Interest",
        "selectYourTopics-subHeader": "Share a little information with us and we will personalize the LiNK experience for you. If you have previously chosen topics of interest through your NFPA profile, we have preselected those for you below.",
        "selectAPlanShortDescription": "NFPA LiNK® offers pricing to fit your team and your budget. Multiple pricing and access options mean you get exactly what you need.",
        "selectTopicsPage-description": "Share a little information with us and we’ll tailor the content we share with you. If you have previously chosen topics of interest through your NFPA profile, we have preselected those for you below.",
        "selectTopicsPage-errors-topicValidation": "You must select at least one topic",
        "selectTopicsPage-errors-updateTeam": "There was an issue updating the Team Name.",
        "selectTopicsPage-errors-updateTopics": "There was an issue updating topics",
        "selectTopicsPage-heading": "Select Your Topics of Interest",
        "selectTopicsPage-inputs-teamName-placeholder": "Enter a name for your team...",
        "selectTopicsPage-inputs-teamName": "Team Name",
        "selectTopicsPage-loader-accessible-text": "Saving topics",
        "selectTopicsPage-meta-title": "{{appNameTm}}: Topics of Interest",
        "selectTopicsPage-teamNameHeading": "Select a Team Name",
        "selectTopicsPage-teamTitle": "Onboarding page",
        "selectTopicsPage-title": "{{appNameTm}} Account Onboarding",
        "sendEmail": "Send Email",
        "selectYourBillingCycle": "Select Your Billing Cycle",
        "selectYourTeamSize": "Select Your Team Size",
        "serviceWorkerStatus-disabled": "Inactive. Please refresh the page",
        "serviceWorkerStatus-enabled": "Active",
        "serviceWorkerStatus-unsupported": "You are using an unsupported browser",
        "serviceWorkerStatus-waiting": "Refresh page to activate",
        "setAsRole": "Set as {{role}}",
        "share": "Share",
        "shareTo": "Share to",
        "sharedWith": "Shared with",
        "shared-collections": "Shared Collections",
        "shareWith": "Share with",
        "sharing": "Sharing",
        "show": "Show",
        "show-earlier-editions": "Show Earlier Editions",
        "showLess": "Show Less",
        "showMore": "Show More",
        "signedInAs": "Signed in as",
        "signIn": "Sign In",
        "signingIn": "Signing In",
        "signInOrCreateAccount": "Sign In or Create an Account",
        "signOut": "Sign Out",
        "signUp": "Sign Up",
        "signUpCTAMessage": "Sign up for a free trial or log in to <1> NFPA LiNK® </1> to continue viewing the code.",
        "sitnav-about-buttonText": "About",
        "sitnav-about-modalHeading": "About {{sitnavTitle}}",
        "sitnav-about": "{{sitnavTitle}} is a step-by-step navigation tool that will present results based upon occupancy, system, space, or equipment type.",
        "sitnav-categories-additionalHelpText": "Continue adding filters from additional categories to filter solutions.",
        "sitnav-categories-helpText": "In order to locate the content applicable to a specific situation, select from the categories below to begin filtering for solutions.",
        "sitnav-categorySelectModal-filter-label": "Showing <1>{{filteredCategoriesSize}} of {{allCategoriesSize}}</1> {{labelsPlural}} filtered by <4>{{selectedOptionsLabel}}</4>",
        "sitnav-categorySelectModal-helptext": "Select <1>one</1> of the filters below that best describes your situation",
        "sitnav-results-message_plural": "<0>{{total}}</0> Results Filtered by <2>{{categoryFilters}}</2>",
        "sitnav-results-message": "<0>{{total}}</0> Result Filtered by <2>{{categoryFilters}}</2>",
        "sitnav-results-placeholder": "Start applying filters to generate results.",
        "situation_plural": "Situations",
        "situation": "Situation",
        "situationPage-additionalInfo": "Additional Info",
        "situationPage-covers": "This situation covers",
        "skipToMainContent": "Skip to main content",
        "solution_plural": "Solutions",
        "skip": "Skip",
        "solution": "Solution",
        "sku": "sku",
        "sort": "Sort",
        "sortBy": "$t(sort) by",
        "sortOptionNewest": "Date Added (Newest First)",
        "sortOptionOldest": "Date Added (Oldest First)",
        "stored-publication": "stored publication",
        "subject": "Subject",
        "spotlightsNewPublicationsBody": "<0>NFPA LiNK® subscribers have access to over 1,500 NFPA codes and standards. This includes all current editions, select titles in Spanish, as well as a library of legacy titles going back five editions. Subscribers also get early access to newly released editions before the printed book is available for purchase.</0>",
        "spotlightsNewPublicationsTitle": "Early Access to Newly Released Publications",
        "spotlightsOfflineModeBody": "<0>Now you can get the job done even if you don't have internet access. The Offline Mode feature in NFPA LiNK® lets you access your content wherever you are. Simply store relevant publications to your device so they're available when you need them, anytime, anywhere. This new feature allows you to store your most used publications to your device, including your bookmarks and notes.</0>",
        "spotlightsOfflineModeTitle": "Introducing Offline Mode",
        "submissionConfirmation": "Submission Confirmation",
        "submit": "Submit",
        "subscription": "Subscription",
        "subscription-choice-card-buttontext": "Upgrade Now",
        "subscription-choice-card-header": "Sign up now!",
        "subscribeLink": "Subscribe to {{appNameTm}}",
        "subscription-choice-card-plans-linktext": "View All Plans",
        "subscriptionCard-changePlan": "Change Plan",
        "subscriptionCard-enterprise-billingText": "Billed annually",
        "subscriptionCard-enterprise-buttonText": "Request Quote",
        "subscriptionCard-enterprise-pricingVaries": "Pricing Varies",
        "subscriptionCard-individual-billingText": "Billed monthly with auto-renewal",
        "subscriptionCard-individual-buttonText": "Select Plan",
        "subscriptionCard-mustSelectBillingCycle": "Must select a billing cycle to proceed",
        "subscriptionCard-mustSelectTeamSize": "Must select a team size to proceed",
        "subscriptionCard-selectTeamSize": "Select a team size",
        "subscriptionCard-selectedPlan": "Selected Plan",
        "subscriptionCard-smallTeam-billingText": "Billed annually, starting price",
        "subscriptionCard-team-billingText": "Billed annually with auto-renewal",
        "subscriptionCard-team-buttonText": "Select Plan",
        "subscriptionCard-teamSize": "Team Size",
        "subscriptionDetails": "Subscription Details",
        "subscriptionDetailsHelp": "To update or modify your subscription plan, please email us at <2>{{email}}</2>, or call us at <5>{{phone}}</5>; if you are calling from outside of the U.S. <8>{{internationalPhone}}</8>. Our customer support team is available to assist you {{hoo}}.",
        "subscriptionFeatures-allIndividualPlanFeatures": "All Individual Plan Features",
        "subscriptionFeatures-bookmarking": "Bookmarking",
        "subscriptionFeatures-customCollections": "Custom Collections",
        "subscriptionFeatures-digitalAccess": "Online and offline digital access",
        "subscriptionFeatures-enterpriseCollaboration": "Enterprise Collaboration",
        "subscriptionFeatures-enterpriseTeamNotesSharing": "Enterprise Team Notes & Sharing",
        "subscriptionFeatures-expertCommentaryVisualAids": "Expert Commentary & Visual Aids",
        "subscriptionFeatures-personalNotations": "Personal Notations",
        "subscriptionFeatures-referencePanel": "Reference Panel",
        "subscriptionFeatures-search": "Search",
        "subscriptionFeatures-sharing": "Sharing",
        "subscriptionFeatures-situationalNavigation": "Situational Navigation",
        "subscriptionFeatures-teamCollaboration": "Team Collaboration",
        "subscriptionFeatures-teamNotesSharing": "Team Notes & Sharing",
        "subscriptionFeatures-userManagement": "User Management",
        "subscriptionNotice": "Your subscription will {{subscriptionExpirationText}} on <4>{{date}}</4>.",
        "subscriptionType-Enterprise": "Enterprise",
        "subscriptionType-Free Trial": "Free Trial",
        "subscriptionType-Individual": "Individual",
        "subscriptionType-Team": "Team",
        "subscribe-now": "Subscribe Now",
        "successfullyRemovedFromTeam": "Succesfully removed {{user}} from this team.",
        "successfullySavedToMyLiNK": "Successfully saved to MyLiNK.",
        "subscriptionType-Training": "Training",
        "support-accountSettings_answer": "<0>You may update your account information, notification preferences, payment details, and change your password in your <1>NFPA account</1>.</0>",
        "support-accountSettings": "How do I modify my personal information or account settings?",
        "support-billing_answer": "<0><0>To update your payment information for auto-renewals, please email us at <1></1>, or call us at <3></3>; if you are calling from outside of the U.S. or Canada, dial <5></5>. Our customer support team is available to assist you {{supportHours}}.</0></0>",
        "support-billing": "How do I update my payment information?",
        "support-browserRequirements_answer": "NFPA LiNK® is compatible with the following web browsers: Chrome 109+, Safari 15+, Edge 126+, and Firefox 115+. Some features may not function or behave as intended if used with other browsers.",
        "support-browserRequirements": "Does {{appName}} have specific browser requirements?",
        "support-cancelSubscription_answer_prePricingUpdate": `<0>Cancellation steps can differ depending on your subscription tier. Please note the following cancellation terms before proceeding to cancel </0><1><0>If you opt to cancel today, it will take 3-5 business days for the cancellation of your subscription to take effect.</0><1>If you cancel during a trial period, you may lose access to content immediately.</1><2>If you cancel a monthly subscription, you can continue using the subscription until the next billing date.</2><3>If you’ve prepaid for an annual subscription, we cannot refund you for the unused portion of your subscription.</3><4><strong>Cancelling Individual Subscriptions:</strong><1><0><0>Log in</0> to your account from any browser.</0><1>Visit Account Settings and select "SUBSCRIPTION DETAILS".</1><2>Click on the “CANCEL MY SUBSCRIPTION” button.</2><3>Fill out the form and submit.</3><4>If you have any issues, please send an email to <1></1>.</4></1></4><5><strong>Cancelling Team Subscriptions:</strong><1><0>As the Team account administrator, <2>log in</2> to your account from any browser.</0><1>Visit Account Settings and select "SUBSCRIPTION DETAILS".</1><2>Click on “CANCEL SUBSCRIPTION” button.</2><3>Fill out the form and submit.</3><4>If you have any issues, please send an email to <1></1>.</4></1></5><6><strong>Cancelling Enterprise Subscriptions:</strong><1><0>Please contact your account manager.</0><1>If you have any issues, please send an email to <1></1>.</1></1></6></1>`,
        "support-cancelSubscription": "How do I cancel my subscription?",
        "support-changeIndicators_answer": "<0>NFPA codes and standards include revision symbols identifying changes from the previous edition. The following indicators are meant to be a guide. [Note: Rearrangement of sections may not be captured in the revision symbols.]</0><1><0>Revisions to text are <2><strong>shaded</strong></2>.</0><1><0></0> before a section number indicates that words within that section were deleted.</1><2><0></0> to the left of a table or figure number indicates a revision to an existing table or figure.</2><3>When a chapter was heavily revised, the entire chapter is marked throughout with the <2></2> symbol.</3><4>Where one or more sections were deleted, a <1></1> is placed between the remaining sections.</4><5>Chapters, annexes, sections, figures, and tables that are new are indicated with an <2><0></0>.</2></5></1>",
        "support-changeIndicators": "Is there a way for me to easily identify changes from the previous edition?",
        "support-cancelSubscription_answer": "<0>If you have a non-auto-renewing NFPA LiNK subscription, it will expire at the conclusion of your term. NFPA LiNK is non-refundable and non-transferrable. For more information, please see our refund and cancellation policy.</0><1>If you are enrolled in NFPA LiNK auto-renew, you may cancel at any time by visiting the <1>subscriptions tab</1> in your account, then click on the “Cancel Auto-Renew” button. You must cancel prior to your renewal date if you do not wish to be charged.</1>",
        "support-forgottenPassword_answer": "<0><0>If you have forgotten your password you may <1>reset it here</1>. Enter your e-mail address and you will receive an e-mail that will provide a link for you to reset your password. If you do not receive an e-mail within 24 hours, please email us at <3></3>, or call us at <5></5>; if you are calling from outside of the U.S.<7></7>. Our customer support team is available to assist you {{supportHours}}.</0></0>",
        "support-concernedWithSecurity": "What if my company is concerned with security/ downloading content?",
        "support-concernedWithSecurity_answer": "Users are not required to install anything to use offline mode. From a security perspective, this progressive web app is much safer than other options because all the content and data is contained within the browser storage and only accessible by the NFPA LiNK® application itself. Our application does not have the ability to reach outside of the nfpa.org domain for anything in the browser storage, which is very similar to how website cookies work.",
        "support-forgottenPassword": "What if I have forgotten my NFPA password?",
        "support-freeTrial_answer": "<0>{{appName}} offers a 14-day free trial. This option can be found below the <2>plan options</2> on the log in page, or you may <4>enroll here</4>. In order to participate in the free trial, you must have an NFPA account and agree to the terms of the trial.</0><1>If you have an existing NFPA account, please choose the 'Log In' option on <1>the enrollment page</1> and enter your NFPA credentials to proceed with registering for the trial. If you do not have an NFPA account, select the 'Create an Account' option on <1>the enrollment page</1> to register.</1>",
        "support-didntYouJustIncreaseThePrice": "Didn’t you just increase the price?",
        "support-didntYouJustIncreaseThePrice_answer": "We did not see a price increase for LiNK subscriptions in 2024.",
        "support-foundInAppStore": "Can I install a mobile application found in my app store?",
        "support-foundInAppStore_answer": "No, NFPA LiNK® is not a native application so it is not available for download in mobile app stores. If you wish to download the application on your desktop, you can do so using Chrome or Edge. See how to access my offline content for more details.",
        "support-freeTrial": "Can I try out {{appName}} before purchasing?",
        "support-hours": "Mon-Fri, 8:30 am - 5:00pm EST",
        "support-howDoIAccess_answer": "{{appName}} can be accessed through any web browser on your favorite devices. With internet connectivity and an active subscription, you will have the power to access all the information and features available. If you do not have access to the internet at your jobsite, see the sections below related to offline access.",
        "support-howDoIAccess": "How do I access {{appName}}?",
        "support-howDoIAccessOffline": "How do I access my offline content?",
        "support-howDoIAccessOffline_answer": "<0>Your offline content can be accessed through the device, browser, or app it was stored to. Once losing connectivity, go to <2>link.nfpa.org</2> or the installed application and your offline publications will be shown on your main dashboard. Any bookmarks, notes and enhanced content you have saved for offline use will be shown inline when viewing those publications. You can also search these publications while in offline mode.</0><1>Here are a few optional steps that offer streamlined access and a more optimal experience:<1><0>When using a computer, you have the option to install the NFPA LiNK® web application. You can do this by clicking the install icon in the far right side of your browser bar.</0><1>On mobile devices, you can add NFPA LiNK® to your home screen by selecting the ‘Add to Home Screen’ option. This will add an application icon for NFPA LiNK® to your phone to help streamline access.</1></1></1>",
        "support-howDoISignIn_answer": "<0><0><0><strong>If you already have an existing NFPA account</strong>, <3>sign in</3> using your NFPA log in credentials and select the {{appName}} plan and billing options of your choice. Once you’ve selected the plan of your choice, you will be brought to NFPA’s online catalog to complete your purchase. Forget your NFPA password? <10>Reset it here</10>.</0></0><1><0><strong>If you need to create an NFPA account</strong>, select the {{appName}} plan and billing options of your choice <6>here</6> and you will be prompted to register a new account. Once you’ve created an account, you will be brought to NFPA’s online catalog to complete your purchase.</0></1></0>",
        "support-howDoIDeleteContent": "How can I delete the content I have stored for offline use to free up storage space on my device?",
        "support-howDoIDeleteContent_answer": "To remove stored content and free up device storage, users should login into NFPA LiNK® and go to Account Settings > Offline tab to delete downloaded publications and the content associated with those.",
        "support-howDoISignIn": "How do I sign in to {{appName}}",
        "support-howToHelp": "Let us know how we can help.",
        "support-howDoILogIn": "How do I log in to {{appName}}?",
        "support-howDoILogIn_answer": "<0><0><0><0><strong>If you already have an existing NFPA account</strong>, <3>log in</3> using your NFPA account credentials and select the {{appName}} plan of your choice. Once you’ve selected the plan of your choice, you will be brought to NFPA to complete your transaction. Once you’ve completed your purchase, you will be returned to NFPA LiNK to begin using your subscription. Your NFPA account credentials are the same credentials you will use to log in to your NFPA LiNK subscriptions. If you have forgotten your NFPA password, you can <8>reset it here</8>.</0></0></0><1><0><strong> If you need to create an NFPA account</strong>, <2>select the {{appName}} plan of your choice.</2> You will then be brought to NFPA to complete your transaction. During the checkout process you will be prompted to create an account. Once you’ve completed your purchase, you will be returned to NFPA LiNK to begin using your subscription. Your NFPA account credentials are the  same credentials you will use to log in to your NFPA LiNK subscriptions.</0></1></0>",
        "support-howMuchContent": "How much content can I store for offline use?",
        "support-howMuchContent_answer": "The amount of content you can store for offline use will depend on your device storage limitations. The NFPA LiNK® application allows you to use up to 50% of your available device storage for our content, with a maximum of 2GB supported.",
        "support-licenseAgreement_answer": "<0><0>In order to use {{appName}}, you must read and agree to the <4>End User License Agreement</4>. Please read the entire agreement carefully. If you do not agree with the terms, you will not be able to access the site.</0>",
        "support-licenseAgreement": "Where can I review the {{appName}} license agreement?",
        "support-otherFormsOfContact_answer": "<0><0>Our customer support team is available to assist you {{supportHours}}. If it is outside our business hours, please email us at <3></3> and we will respond to you on our next business day.</0></0><1><strong>Telephone</strong></1><2><0></0> (U.S. & Canada)</2><3><0>{{altPhone}}</0> (U.S. & Canada)</3><4><0></0> (International)</4><5><0>{{mxPhone}}</0> (Mexico)</5>",
        "support-newBookmarksWhileOnline": "What happens if I create new bookmarks and notes while online?",
        "support-newBookmarksWhileOnline_answer": "New bookmarks/ notes created in publications you have already saved for offline use will not automatically be synced to your offline content. If you go to Account Settings > Offline your stored publications will have an indicator if an update is available. From there, you can hit the ‘Download’ button to refresh the version you have stored.",
        "support-offline-use": "Can I use {{appName}} while offline?",
        "support-offline-use_answer": "<0>While connected to the internet, you can store frequently used publications, bookmarks, notes and enhanced content to your device for offline use with supported browsers (see browser requirements below for more details).</0><0>Any content you store for offline access will be available when using the same account, device, browser or app. Logging in with a different account, device or browser will require you to re-download the content to that device.</0><0>To access your offline content, you need to have an active session running.  We recommend logging into NFPA LiNK® prior to going offline to confirm the content you need is stored on that device and to ensure you have an active session running.</0>",
        "support-offlineLimitations": "Are there any limitations to offline access?",
        "support-offlineLimitations_answer": "<0><0>Users must be logged into NFPA LiNK® to access their offline content. We recommend logging in prior to going offline to confirm the content you need is stored on that device/browser or app, and to ensure you have an active session running.</0><1>After 20 days without a connectivity, NFPA LiNK® will display a warning that your session will expire in 1 day.</1><2>After 21 days without a connectivity, NFPA LiNK® will end your offline session and prompt you to re-connect to validate that an active subscription still exists.</2><3>Offline access is compatible with the following browsers: Chrome v45+, Safari v11.1+ / iOS 13+, Edge v17+, Firefox v44+.</3></0>",
        "support-otherFormsOfContact": "Other forms of contact",
        "support-plans_answer": "<0>We understand that not every professional and team needs the same solution. {{appName}} offers a <3>variety of plans and billing options</3>. We offer monthly and annual billing options, as well as individual, team and enterprise plans. Take advantage of the auto-renewal option for ease of payment and cost savings.</0>",
        "support-plans": "What types of plans and billing options do you offer?",
        "support-refundPolicy_answer": `<0>Digital products are non-returnable and non-refundable, but NFPA takes pride in the quality of the products and services we offer. If you are not completely satisfied with your order, we would like to hear from you. Please <2>contact us</2> to provide feedback.</0>`,
        "support-refundPolicy": "What is NFPA’s refund policy?",
        "support-renewSubscription_answer": "<0>If you selected auto-renewal at point of purchase, you don’t have to do anything! You will receive the benefit of never having to worry about loss of access and will automatically be charged on your renewal date.</0><1>If you did not elect to auto-renewal upon purchase, you can go to our <1>plans selection page</1> to renew your subscription if you are within 30 days of your expiration date. If your subscription is not expiring in the next 30 days, we recommend using the ‘Contact Support’ button at the top of this page to have our customer service team help process your renewal.</1>",
        "support-renewSubscription": "How do I renew my subscription?",
        "support-updateSubscription_answer": "<0><0>To update or modify your subscription plan, please email us at <1></1>, or call us at <3></3>; if you are calling from outside of the U.S. or Canada, dial <5></5>. Our customer support team is available to assist you {{supportHours}}.</0></0>",
        "support-updateSubscription": "How do I modify or upgrade my subscription?",
        "support-whatIsAppName_answer": "<0>{{appName}} is a subscription-based application that delivers NFPA codes and standards and supporting content. This new tool allows users to easily search and navigate a variety of different content types based on real-life scenarios. {{appName}} allows you to curate and share information relevant to your work and educate yourself and your team to better prepare for your day-to-day work. Users will have access to:</0><1><0>Online and offline access to NFPA Codes & Standards</0><0>Expert Commentary & Visual Aids</0><0>Situational Navigation</0><0>Custom Collections</0><0>Personal Notations</0><0>Reference Panel</0><0>Bookmarking</0><0>Sharing</0><0>Team Collaboration</0></1>",
        "support-videoDemos": "Do you have demo videos to help me learn how to use the features of NFPA LiNK®?",
        "support-videoDemos_answer": "<0>Yes! Not only have we developed a <2>Quick Start Guide</2> to help you familiarize yourself with NFPA LiNK®, but we have also developed these short companion videos:<5><0>Dashboard</0><1>Book View</1><2>DiRECT</2><3>MyLiNK</3><4>Offline Access</4><5><0>Reference Panel</0></5><6>Search</6></5></0>",
        "support-whatHappensClearCookies": "What happens if I clear my cookies / browser cache?",
        "support-whatHappensClearCookies_answer": "<0><0>On a computer, clearing browser cache should not delete any of the content you have stored for offline use on that device, browser, or installed app.</0><1>On a mobile device, clearing browser cache will delete all content you have stored for offline use on that device, browser, or home screen app.</1></0>",
        "support-whatIsAppName": "What is {{appName}}?",
        "support-whatsInApp_answer": "<0>NFPA LiNK®, a breakthrough digital platform providing instant access to NFPA® codes and standards, plus exclusive expert commentary, visual aids, and more. NFPA LiNK® subscribers now have access to over 1,500 NFPA codes and standards. This includes all current editions, as well as a library of legacy titles going back five editions. Subscribers also get early access to newly released editions before the printed book is available for purchase. <1><0>Browse available publications.</0></1></0>",
        "support-whatsInApp": "What information and content will I have access to with {{appName}}?",
        "support": "Support",
        "support-whatNewFeaturesToExpect": "What new features can I expect with this price increase?",
        "support-whatNewFeaturesToExpect_answer": "<0><0>Over the last year, we’ve added new content mediums and functions including new expert commentary, interactive change indicators, change summaries, TIA notifications, e-forms, checklists, videos, and more.</0><1><0>Over 65 new codes and standards have been added in LiNK over the last year, including Spanish language, and that library will only continue to grow as new editions are released. <1>See the expanded list of current codes.</1></0>  <1>Dedicated YouTube channel provides subscribers with knowledge and tips to support your business and includes tutorials on a range of topics related to electrical, fire, and life safety.</1>  <2>New expert commentary and enhanced content, such as videos, e-forms, and checklists are being created and added on an ongoing basis.</2>  <3>Change Summaries offer expert insight into revisions that have been made since the prior edition.</3>  <4>Interactive Change Indicators make it easier to identify and understand changes and deletions from edition to edition.</4>  <5>TIA Change Indicators inform you of any code amendments that have been made since the publication was released.</5></1></0>",
        "supportPage-furtherAssistance": "<0><0><strong>Need Further Assistance?</strong></0><1>Send us a message through the contact support option below.</1><2><0>Contact Support</0></2></0>",
        "supportPage-messageConfirmation": "Thank you for submitting your inquiry to NFPA. Your question is important to us and will be responded to within one (1) business day.",
        "support-whyIsThePriceIncreasing": "Why is the price increasing?",
        "support-whyIsThePriceIncreasing_answer": "<0>Like many businesses, we are adjusting our pricing to align with the economic environment, ensuring we can continue to provide value to our users by continually improving the LiNK platform. A small price increase is necessary to sustain and enhance our platform.</0><1>This price increase helps fund updates to collaboration features, personal notes and bookmark capabilities, and increased functionality within the platform, as well as ongoing investments in reliability, security, and performance.</1><2><0>See “What new features to expect?” below for more.</0></2>",
        "supportPage-metaDescription": "If you need support with {{appName}}, our customer support team is available to assist you Monday through Friday, {{supportHours}}. Contact Customer Support: {{supportPhone}}. If it is outside our business hours, please email us at {{supportEmail}} and we will respond to you on our next business day.",
        "supportPage-metaKeywords": "help, contact us, support, faqs",
        "supportPage-metaTitle": "{{appName}} Support: Questions & Answers",
        "supportPage-title": "{{appName}} Support",
        "switchProfile-header": "Select a Profile to Use",
        "switchProfile-loadingMessage": "Switching profiles...",
        "switchProfile": "Switch Profile",
        "table": "Table",
        "tableOfContents": "Table of Contents",
        "switchToLink": "Switch to your {{appNameWithHtml}} subscription for access to more content, features and an interactive experience. From {{appNameWithHtml}} you can view expert commentary, create bookmarks and notes, curate content, share, and collaborate.",
        "system-issues-banner-text": "Some functionality might be limited currently.",
        "tables": "Tables",
        "teal": "Teal",
        "team-member_plural": "Team Members",
        "takeToLink": "Take me to {{appNameWithHtml}}",
        "team-member": "Team Member",
        "team-name": "Team Name",
        "team-size": "Team Size",
        "team": "Team",
        "teamInvitePlaceholder": "Enter email address to send team invite…",
        "teamManagement": "Team Management",
        "teamAdministrators": "Team Administrators",
        "teamName": "Team Name",
        "teamNameBlank": "Team name cannot be blank",
        "teamNameMaxCharacters": "Team name has a maximum of {{length}} characters.",
        "teamsAdminDashboardPage-searchFormPlaceholder": "Filter by purchaser/admin name, email address, or team name.",
        "techicalProblems": "I had technical problems",
        "termsAndConditions-acceptButtonText": "I Accept the User License Agreement",
        "termsAndConditions-contactCustomerServiceText": "If you have questions, please contact Customer Service at {{usPhoneNumber}}; if you are calling from outside of the U.S. {{itnlPhoneNumber}}. Our customer support team is available to assist you {{businessHours}}.",
        "termsAndConditions-documentTitle": "User License Agreement",
        "termsAndConditions-IAgreeText": "I agree to the User License Agreement.",
        "termsAndConditions-meta-title": "End User License Agreement",
        "termsAndConditions-mustAgreeToContinue": "You must accept the User License Agreement to continue.",
        "termsAndConditions-pageTitle": "Account Onboarding",
        "termsOfUse": "Terms of Use",
        "thankYouForBeingASubscriber": "Thank you for being a subscriber of NFPA LiNK®. Your subscription will be cancelled in the next 3-5 business days.",
        "to": "to",
        "textCopied": "Text copied!",
        "textFromNFPACodePriorToJurisdictionalAmendments": "Text from NFPA code, prior to jurisdictional amendments.",
        "thank-you": "Thank You",
        "toastHeader": "{{appNameWithHtml}} - So much more than codes & standards",
        "thisContentWasNotInTheNFPAVersion": "This content was not in the NFPA version.",
        "tiaPanelNewAddition": "This content was not in the previous version.",
        "tooExpensive": "It is too expensive",
        "topic_plural": "topics",
        "topic-of-interest_plural": "Topics of Interest",
        "topic-of-interest": "Topic of Interest",
        "topic": "topic",
        "topicsOfInterest-description": "This will help us provide you with content that matters to you most.",
        "tryAgain": "Try again",
        "unfavorite": "Unfavorite",
        "training-contract-role-already-exists": "Your training access has expired. To continue utilizing {{appName}}, please <4>enroll in a new subscription</4>.",
        "trainingContractRegistrationHeader": "Register for your {{appNameTm}} Training Access for {{durationDays}} days!",
        "trainingContractAccessCodeError": "You have entered an invalid access code. Please try again.",
        "translationInfo": "Translation Information",
        "unavailableOffline": "Unavailable Offline",
        "tryLinkFree": "Try {{appNameTm}} for Free",
        "unHappy": "I am not happy with the quality of content",
        "unassignedTeam": "Unassigned Team",
        "unpin": "Unpin",
        "undoDelete": "Undo Delete",
        "unpublish": "Unpublish",
        "unpublished": "Unpublished",
        "updating": "updating",
        "updatedItem": "Updated {{item}}.",
        "unrecognized-email": "Unrecognized Email Address",
        "upToItem": "up to {{item}}",
        "updateViewingPreferences": "Update your viewing preferences",
        "upgrade": "Upgrade",
        "useNfpaDirect-link-label": "Try this step-by-step navigation tool to find your solution quicker.",
        "useNfpaDirect": "Use NFPA DiRECT™",
        "urlLanguagePath": "en",
        "user_plural": "users",
        "user": "user",
        "userCollectionMenu": "User Collection Menu",
        "userbookmarks-deleteLabel": "Are you sure you want to delete this bookmark?",
        "userbookmarks-deleteMessage": "Deleting this bookmark will remove it from My Bookmarks and all collections.",
        "userbookmarks-removeFromCollectionConfirmation": "Remove from Collection?",
        "userbookmarks-removeFromCollectionLabel": "Remove from Collection",
        "userbookmarks-removeFromCollectionMessage": "This bookmark will still be saved in My Bookmarks.",
        "userRole": "user role",
        "userCollectionPage-deleteConformationModal-confirmButtonText": "Yes, Delete",
        "userCollectionPage-deleteConformationModal-text": "Bookmarks in this collection will still be saved in My Bookmarks.",
        "userSupportPage-searchFormPlaceholder": "Filter by name, email address, or NFPA ID.",
        "userCollectionPage-deleteConformationModal-title": "Are you sure you want to delete this collection?",
        "userCollectionPage-error-loadingCollection": "There was an issue loading this collection.",
        "userCollectionPage-teamCollection": "Team Collection",
        "userCollectionsTabFilters-searchForm-placeholder": "Search by collection title",
        "usersettings-enhancedContentHidden": "Enhanced Content is hidden.",
        "usersettings-expandByDefault": "Expand by default",
        "usersettings-expandContentDefault": "Expand Content by Default",
        "usersettings-deleteTiasHidden": "Deleted TIA Content is hidden",
        "usersettings-noPreferencesFound": "No preferences found",
        "usersettings-showEnhancedContent": "Show Enhanced Content",
        "usersettings-viewingPreferences": "Viewing Preferences",
        "usersettings-showDeletedTIAs": "Show Deleted TIA Content",
        "usersettings-showTableContentDefault": "Show Table Content",
        "validation-atLeastOne": "At least one {{type}} is required.",
        "validation-multipleInvalid": "One or more {{type}} are invalid",
        "validation-notAValid": "This is not a valid {{type}}",
        "verifyTeamInformation": "Verify Team Information",
        "view": "View",
        "viewInPublication": "View In Publication",
        "view-bookmarks": "View Bookmarks",
        "viewItem": "View {{item}}",
        "viewChanges": "View Changes",
        "viewInLink": "View this in {{appNameWithHtml}}",
        "browse-all-available-publications": "Browse all available Publications",
        "sectionDetailLabel": "Section Detail Difference",
        "warnings-thisActionCannotBeUndone": "This action cannot be undone.",
        "watchVideo": "Watch the Video",
        "weHaveEncounteredAnIssuePleaseTryAgainOrContactYourOrganizationForAssistance": "We have encountered an issue. Please try again, or contact your organization for assistance.",
        "welcomeToLiNK": "Welcome to NFPA LiNK!",
        "where": "where",
        "wideView": "Wide View",
        "withAutoRenewal": "with auto-renewal",
        "workgroup": "Workgroup",
        "workgroups": "Workgroups",
        "workGroupVisibility": "The following Workgroups can view this collection:",
        "workgroup-create-success": "WorkGroup successfully created",
        "workgroup-create-failure": "There was an error creating the WorkGroup",
        "workgroup-delete": "Delete Workgroup",
        "workgroup-delete-cancel": "No, Back to Workgroups",
        "workgroup-delete-message": "Deleting this means users and collections will no longer be associated with this workgroup. Users, bookmarks, or collections will NOT be removed from LiNK®.",
        "workgroup-delete-confirmation-label": "Delete Workgroup?",
        "workgroup-delete-member-button": "Workgroup members delete button",
        "workgroup-delete-success": "Workgroup successfully deleted",
        "workgroup-delete-failure": "Workgroup failed to delete",
        "workGroup-groupMembers": "Group Members",
        "workgroup-groups": "Groups",
        "workGroup-manage-group-members": "Manage Workgroup members",
        "workgroup-manage-workgroups": "Manage Workgroups",
        "workgroup-members": "Members",
        "workgroup-update-failure": "There was an error updating the Workgroup",
        "workgroup-update-success": "Workgroup updated successfully",
        "workgroups-information-interest": "These workgroups will determine which shared collections you can access and help your team stay organized.",
        "workgroups-information-label": "Your Workgroups",
        "yellow": "Yellow",
        "yesAction": "Yes, {{action}}",
        "you": "You",
        "your-free-trial": "Your Free Trial",
        "your-plan": "Your Plan",
        "yourItem": "Your {{item}}",
        "yourProfile": "Your Profile",
        "zoomIn": "Zoom in",
        "zoomOut": "Zoom out",
    },
});

export default EnglishUnitedStates;
