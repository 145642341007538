import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import UnorderedList from "molecules/lists/unordered-list";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import ExpandableRichTextArea from "molecules/rich-text-area/expandable-rich-text-area";
import BookmarkCollectionsBadge from "organisms/my-link/my-bookmarks/bookmark-collections-badge";
import UpdateBookmarkModal from "organisms/my-link/my-bookmarks/update-bookmark-modal";
import React, { useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useDeleteBookmark from "utilities/hooks/domain/my-link/use-delete-bookmark";
import useDeleteUserCollectionBookmark from "utilities/hooks/domain/my-link/use-delete-user-collection-bookmark";
import useTableBookmark from "utilities/hooks/domain/tables/use-table-bookmark";
import useUser from "utilities/hooks/domain/users/use-user";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookmarkCardProps {
    bookmark: UserBookmarkRecord;
    collections: Array<UserCollectionRecord>;
    /**
     * when in the collection view, show "Remove from Collection" instead of "Delete Bookmark"
     * in context menu.
     */
    inCollectionView: boolean;
    onBookmarkDeleted: (bookmark: UserBookmarkRecord) => void;
    onBookmarkUpdated: (bookmark: UserBookmarkRecord) => void;
    onClick: () => void;
    publication?: PublicationRecord;
    showUpdateBookmarkModal?: (isOpen: boolean) => void;
    userCollectionBookmark?: UserCollectionBookmarkRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BookmarkCard: React.FC<BookmarkCardProps> = (
    props: BookmarkCardProps
) => {
    const CSS_CLASS_NAME = "c-bookmark-card";
    const {
        bookmark,
        collections,
        onBookmarkDeleted,
        onBookmarkUpdated,
        onClick,
        showUpdateBookmarkModal,
        userCollectionBookmark,
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [hasExpandableContent, setHasExpandableContent] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [moreLessAnchorHovered, setMoreLessAnchorHovered] = useState(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const {
        errors,
        loaded,
        loading,
        resultObject: table,
    } = useTableBookmark(
        bookmark.createdOnPublicationId,
        bookmark.externalId,
        bookmark.publicationCode,
        bookmark.publicationEdition,
        bookmark.userBookmarkType
    );
    useErrorBoundary(errors);

    const { globalState } = useGlobalState();
    const { t } = useLocalization();
    const { user } = useUser(bookmark.createdById);
    const currentUser = globalState.currentIdentity?.userId();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const { deleteBookmark, loading: deletingBookmark } = useDeleteBookmark(
        bookmark,
        onBookmarkDeleted
    );

    const { deleteUserCollectionBookmark, deletingUserCollectionBookmark } =
        useDeleteUserCollectionBookmark(userCollectionBookmark?.id, () =>
            onBookmarkDeleted(bookmark)
        );

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleDeleteConfirmed = () => {
        setShowDeleteConfirmModal(false);
        if (userCollectionBookmark == null) {
            deleteBookmark();
            return;
        }

        deleteUserCollectionBookmark();
    };

    const handleUpdateBookmarkModalClick = (value: boolean) => {
        setShowUpdateModal(value);
        showUpdateBookmarkModal?.(value);
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------
    const createdByOtherTeamUser = bookmark.userId !== currentUser;

    const hasCollectionsClass = useMemo(
        () =>
            CollectionUtils.hasValues(collections) ? "-with-collections" : "",
        [collections]
    );

    const createdByText = useMemo(() => {
        if (user == null || user.id === currentUser) {
            return t("you").toLocaleLowerCase();
        }

        return user.getFirstAndLastName();
    }, [currentUser, t, user]);

    const actionBy = t("actionBy", {
        action: bookmark.getModifiedDateFromNowText(),
    });

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    if (deletingBookmark) {
        return (
            <div className={CSS_CLASS_NAME}>
                <Loader
                    accessibleText={t("deletingItem", { item: t("bookmark") })}
                />
            </div>
        );
    }

    if (deletingUserCollectionBookmark) {
        return (
            <div className={CSS_CLASS_NAME}>
                <Loader
                    accessibleText={t("removingItemFrom", {
                        item: t("bookmark"),
                        container: t("collection"),
                    })}
                />
            </div>
        );
    }

    const bookmarkModifier = bookmark.getColorClassModifier();

    const rootClassNames = [CSS_CLASS_NAME];
    if (moreLessAnchorHovered) {
        rootClassNames.push("-no-underline");
    }

    return (
        <Button
            cssClassName={rootClassNames.join(" ")}
            onClick={onClick}
            style={ButtonStyles.None}>
            <div className={`${CSS_CLASS_NAME}__top`}>
                <div className={`${CSS_CLASS_NAME}__top__left`}>
                    <div
                        className={`${CSS_CLASS_NAME}__top__left__icon-container`}>
                        <Icon
                            cssClassName={bookmarkModifier}
                            type={Icons.Bookmark}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__top__left__labels`}>
                        <Paragraph
                            cssClassName={`${CSS_CLASS_NAME}__top__left__labels__section`}>
                            {bookmark.getFullyQualifiedDisplayTitle() ?? "..."}
                        </Paragraph>
                        <label
                            className={`${CSS_CLASS_NAME}__top__left__labels__publication`}>
                            {bookmark.getFullyQualifiedDisplaySubtitle() ??
                                "..."}
                        </label>
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__top__right`}>
                    {!isMobile && (
                        <label
                            className={`${CSS_CLASS_NAME}__top__right__timestamp`}>
                            {actionBy} <strong>{createdByText}</strong>
                        </label>
                    )}
                    <div
                        className={`${CSS_CLASS_NAME}__top__right__actions`}
                        onClick={(e) => e.stopPropagation()}>
                        {!createdByOtherTeamUser && (
                            <MenuButton
                                buttonAccessibleText="Bookmark actions"
                                iconSize={IconSizes.Medium}>
                                <Button
                                    dataTestId="edit-bookmark"
                                    onClick={() =>
                                        handleUpdateBookmarkModalClick(true)
                                    }>
                                    {t("editItem", {
                                        item: t("bookmark"),
                                    })}
                                </Button>
                                <Button
                                    onClick={() =>
                                        setShowDeleteConfirmModal(true)
                                    }>
                                    {userCollectionBookmark == null &&
                                        t("deleteItem", {
                                            item: t("bookmark"),
                                        })}
                                    {userCollectionBookmark != null &&
                                        t(
                                            "userbookmarks-removeFromCollectionLabel"
                                        )}
                                </Button>
                            </MenuButton>
                        )}
                    </div>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                {StringUtils.hasValue(bookmark.descriptionAsPlainText) && (
                    <ExpandableRichTextArea
                        cssClassName={`${CSS_CLASS_NAME}__body__description ${bookmarkModifier}`}
                        content={bookmark.description}
                        collapsedMaxHeight={40}
                        hasExpandableContent={hasExpandableContent}
                        setHasExpandableContent={setHasExpandableContent}
                        onAnchorMouseEnter={() =>
                            setMoreLessAnchorHovered(true)
                        }
                        onAnchorMouseOut={() => setMoreLessAnchorHovered(false)}
                    />
                )}
                {CollectionUtils.hasValues(errors) && (
                    <UnorderedList listItems={errors} />
                )}
                {loading && <Loader />}
                {loaded && table.getBody()}
                <div className={`${CSS_CLASS_NAME}__body__footer`}>
                    {isMobile && (
                        <label
                            className={`${CSS_CLASS_NAME}__body__footer__timestamp ${hasCollectionsClass}`}>
                            {actionBy} <strong>{createdByText}</strong>
                        </label>
                    )}
                    {CollectionUtils.hasValues(collections) && (
                        <BookmarkCollectionsBadge collections={collections} />
                    )}
                </div>
            </div>
            {showUpdateModal && (
                // Conditionally rendering this instead of using isVisible
                // because the modal loads a bunch of data
                <UpdateBookmarkModal
                    bookmark={bookmark}
                    closeDialog={() => handleUpdateBookmarkModalClick(false)}
                    isVisible={showUpdateModal}
                    onBookmarkUpdated={onBookmarkUpdated}
                    publication={props.publication}
                />
            )}
            {userCollectionBookmark == null && (
                <ConfirmationModal
                    confirmButtonText={t("yesAction", { action: t("delete") })}
                    isVisible={showDeleteConfirmModal}
                    label={t("userbookmarks-deleteLabel")}
                    message={t("userbookmarks-deleteMessage")}
                    onCancel={() => setShowDeleteConfirmModal(false)}
                    onConfirm={handleDeleteConfirmed}
                />
            )}
            {userCollectionBookmark != null && (
                <ConfirmationModal
                    confirmButtonText={t("yesAction", { action: t("remove") })}
                    isVisible={showDeleteConfirmModal}
                    label={t("userbookmarks-removeFromCollectionConfirmation")}
                    message={t("userbookmarks-removeFromCollectionMessage")}
                    onCancel={() => setShowDeleteConfirmModal(false)}
                    onConfirm={handleDeleteConfirmed}
                />
            )}
        </Button>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkCard;

// #endregion Exports
