/**
 * Error keys returned by the `UserConfigurationConductor` on the backend. These denote specific states
 * of the User that need to be completed before accessing the main content of the application.
 *
 * See `UserConfigurationConductor` and `UserConfigurationFilter` for additional detail.
 */
const UserConfigurationErrorKeys = {
    ERROR_CURRENT_ROLE_EXPIRED: "UserConfiguration.ERROR_CURRENT_ROLE_EXPIRED",
    ERROR_EULA_NOT_ACCEPTED: "UserConfiguration.ERROR_EULA_NOT_ACCEPTED",
    ERROR_NO_SUBSCRIPTIONS: "UserConfiguration.ERROR_NO_SUBSCRIPTIONS",
    ERROR_SELECT_ANOTHER_ROLE: "UserConfiguration.ERROR_SELECT_ANOTHER_ROLE",
    ERROR_TEAM_NOT_INITIALIZED: "UserConfiguration.ERROR_TEAM_NOT_INITIALIZED",
    ERROR_TOPICS_NOT_UPDATED: "UserConfiguration.ERROR_TOPICS_NOT_UPDATED",
    ERROR_ELIGIBLE_TEAMS_FOUND_FOR_NEW_SUBSCRIPTION:
        "UserConfiguration.ERROR_ELIGIBLE_TEAMS_FOUND_FOR_NEW_SUBSCRIPTION",
};

export default UserConfigurationErrorKeys;
