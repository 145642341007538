import UserRoleRecord from "models/view-models/user-role-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserRoleBasePathParams {}

export interface UserRoleResourcePathParams extends UserRoleBasePathParams {
    /**
     * Id of the UserRole resource
     */
    id: number;
}

/**
 * Search parameters when listing UserRole resources
 * These get translated to QueryString parameters
 */
export interface UserRoleListQueryParams {
    /**
     * An array of userRoleIds to filter by.
     */
    ids: number[];
    /** An option to allow retrieval of soft-deleted records */
    ignoreQueryFilters?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "userroles";
const resourceType = UserRoleRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserRoleService = {
    /**
     * Retrieve a single UserRole by id
     */
    get: ServiceFactory.get<UserRoleRecord, UserRoleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of UserRoles by userId
     */
    list: ServiceFactory.list<UserRoleRecord, UserRoleListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useGet: ServiceHookFactory.useGet<
        UserRoleRecord,
        UserRoleResourcePathParams
    >(resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useList<
        UserRoleRecord,
        UserRoleListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserRoleService;

// #endregion Exports
