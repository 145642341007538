import GroupRecord from "models/view-models/group-record";
import SupportUserRecord from "models/view-models/support-user-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import { useEffect, useState } from "react";
import DateUtils from "utilities/date-utils";
import useSubscription from "utilities/hooks/domain/subscriptions/use-subscription";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";
import GroupService from "utilities/services/groups/group-service";
import SupportUserService from "utilities/services/support/support-users-service";
import UserRoleGroupService from "utilities/services/user-role-groups/user-role-group-service";
import UserRoleService from "utilities/services/user-roles/user-role-service";
export interface UseAdminTeamDetailsOptions {
    teamId: number;
}
const useAdminTeamDetails = (options: UseAdminTeamDetailsOptions) => {
    const { teamId } = options;
    const [team, setTeam] = useState<GroupRecord>();
    const [userRoleGroup, setUserRoleGroup] = useState<UserRoleGroupRecord>();
    const [subscriptionOwner, setSubscriptionOwner] =
        useState<SupportUserRecord>();
    const [subscriptionId, setSubscriptionId] = useState<number>();
    const [expirationDate, setExpirationDate] = useState("");

    const { fromPin } = useEcommercePrices();
    const { resultObject: subscription } = useSubscription(subscriptionId);
    const { cycle, autoRenew } = fromPin(subscription.sku) ?? {};

    // Get Team based on id param
    useEffect(() => {
        const getTeam = async () => {
            const response = await GroupService.get({ id: teamId });
            setTeam(response.resultObject);
        };
        getTeam();
    }, [teamId]);

    // Get owner's User Role Group
    useEffect(() => {
        const getUserRoleGroupAndSubscriptionInfo = async () => {
            const userRoleGroupsResponse = await UserRoleGroupService.list({
                groupId: teamId,
            });
            const ownerUserRoleGroup =
                userRoleGroupsResponse.resultObjects.find((urg) => urg.isOwner);
            if (ownerUserRoleGroup) {
                setUserRoleGroup(ownerUserRoleGroup);
            }
        };
        getUserRoleGroupAndSubscriptionInfo();
    }, [teamId]);

    // Get Subscription info and owner's user data
    useEffect(() => {
        const getOwner = async () => {
            if (userRoleGroup) {
                const roleRes = await UserRoleService.get({
                    id: userRoleGroup.userRoleId,
                });
                setSubscriptionId(roleRes.resultObject?.subscriptionId);
                setExpirationDate(
                    DateUtils.formatDate(roleRes.resultObject?.expiresOn)
                );
                const userId = roleRes.resultObject?.userId;
                if (userId) {
                    const response = await SupportUserService.get({
                        id: userId,
                    });
                    setSubscriptionOwner(response.resultObject);
                }
            }
        };
        getOwner();
    }, [userRoleGroup]);

    return {
        subscriptionOwner,
        team,
        subscription,
        cycle,
        autoRenew,
        expirationDate,
        userRoleGroup,
        subscriptionId,
    };
};

export default useAdminTeamDetails;
