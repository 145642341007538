import { ServiceFactory } from "@rsm-hcd/javascript-react";
import NaturalLanguageSearchSessionRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-session-record";

export interface GetNaturalLanguagePathParams {
    id: number;
}

const baseEndpoint = "nl-search-sessions";

export const NaturalLanguageSearchService = {
    create: ServiceFactory.create<NaturalLanguageSearchSessionRecord>(
        NaturalLanguageSearchSessionRecord,
        baseEndpoint
    ),
};
