import React, { useCallback, useState } from "react";
import ContactUsMessage from "organisms/resources/contact-us-message";
import UserTopicsList from "organisms/user-topics-list/user-topics-list";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Button from "atoms/buttons/button";
import { ToastManager } from "utilities/toast/toast-manager";
import { useLocalization } from "utilities/hooks/use-localization";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Paragraph from "atoms/typography/paragraph";
import { uniqBy } from "lodash";
import PublicationRecord from "models/view-models/publication-record";
import PublicationCardSimple from "molecules/cards/publication-card-simple";
import useBook from "utilities/hooks/domain/books/use-book";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import useSelectedTopics from "utilities/hooks/domain/topics/use-selected-topics";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import PublicationService from "utilities/services/publications/publication-service";
import usePageErrors from "utilities/hooks/use-page-errors";
import BookRecord from "models/view-models/book-record";
import { useAsyncEffect } from "@rsm-hcd/javascript-react";
import { CollectionUtils } from "utilities/collection-utils";
import { List } from "immutable";
import Loader from "atoms/loaders/loader";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface CustomizeExperienceProps {}
const CustomizeExperienceStatus = {
    SelectTopics: 1,
    SelectFavorites: 2,
};

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-customize-experience";
const TAKE = 5;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CustomizeExperience: React.FC<CustomizeExperienceProps> = () => {
    const history = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [activeDot, setActiveDot] = useState<number>(
        CustomizeExperienceStatus.SelectTopics
    );
    const [publications, setPublications] = useState<Array<PublicationRecord>>(
        []
    );
    const { list: listPublicationApi } = PublicationService.useList();

    const {
        selectedTopicIds,
        updateUserTopics,
        handleCheck,
        loading,
        loaded,
        topics,
    } = useSelectedTopics();

    const { handlePageLoadError } = usePageErrors();

    const { favorites, setFavorites } = usePublicationFavorites();

    const { t } = useLocalization();

    const [showValidationError, setShowValidationError] =
        useState<boolean>(false);
    const { resultObject: featuredBooks } = useBook({ isFeatured: true });

    const findPublicationsByTopics = useCallback(
        async (selectedTopics: number[]): Promise<Array<PublicationRecord>> => {
            try {
                const publicationTopicsResponse = await listPublicationApi({
                    topicIds: selectedTopicIds,
                });
                const publications = publicationTopicsResponse.resultObjects;

                return publications;
            } catch (result) {
                handlePageLoadError(result);
                return [];
            }
        },
        [listPublicationApi, selectedTopicIds, handlePageLoadError]
    );

    const findFeaturedPublications = useCallback(
        async (
            featuredBooks: List<BookRecord>
        ): Promise<Array<PublicationRecord>> => {
            try {
                const response = await listPublicationApi();
                const allPublications = response.resultObjects ?? [];
                const featuredPublications = allPublications.filter(
                    (p: PublicationRecord) => {
                        return featuredBooks.some(
                            (b: BookRecord) =>
                                b.code === p.code && b.edition === p.edition
                        );
                    }
                );
                return featuredPublications;
            } catch (result) {
                handlePageLoadError(result);
                return [];
            }
        },
        [handlePageLoadError, listPublicationApi]
    );

    useAsyncEffect(
        async function getPublications() {
            if (CollectionUtils.isEmpty(selectedTopicIds)) {
                return;
            }

            let publications = await findPublicationsByTopics(selectedTopicIds);
            if (CollectionUtils.hasValues(publications)) {
                setPublications(publications);
                return;
            }

            publications = await findFeaturedPublications(featuredBooks);
            setPublications(publications);
        },
        [selectedTopicIds]
    );

    const findFeaturedTopicBooks = publications.filter((p) =>
        featuredBooks.some((f) => p.code === f.code && p.edition === f.edition)
    );

    const publicationsWithFeaturedFirst = uniqBy(
        [...findFeaturedTopicBooks, ...publications],
        "id"
    );

    const availablePublications = publicationsWithFeaturedFirst.slice(0, TAKE);

    const onClickContinueToFavorites = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (selectedTopicIds.length === 0) {
            setShowValidationError(true);
            return;
        }

        setShowValidationError(false);
        setShowLoader(true);

        const success = await updateUserTopics();
        if (success) {
            setShowLoader(false);
            setActiveDot(CustomizeExperienceStatus.SelectFavorites);
        } else {
            ToastManager.error(t("selectTopicsPage-errors-updateTopics"));
        }
    };

    const onClickContinueToDashboard = () => {
        history.push(siteMap.dashboards.user);
    };

    const onClickContinue = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        activeDot === CustomizeExperienceStatus.SelectTopics
            ? onClickContinueToFavorites(e)
            : onClickContinueToDashboard();
    };

    const onClickBackToTopics = () => {
        setActiveDot(CustomizeExperienceStatus.SelectTopics);
    };

    const disableContinueButton =
        selectedTopicIds.length === 0 || showValidationError;

    return (
        <div className={BASE_CLASS}>
            <div className={`${BASE_CLASS}__dots-container`}>
                <TwoDots activeDot={activeDot} />
            </div>
            {activeDot === CustomizeExperienceStatus.SelectTopics ? (
                <>
                    <div className={`${BASE_CLASS}__header`}>
                        <Heading priority={HeadingPriority.Five}>
                            {t("selectYourTopicsOfInterest")}
                        </Heading>
                        <Paragraph>{t("selectYourTopics-subHeader")}</Paragraph>
                    </div>
                    <div className={`${BASE_CLASS}__topics-list`}>
                        <UserTopicsList
                            loaderType={LoaderStyles.LinkGlyphGray}
                            onCheck={handleCheck}
                            selectedTopicIds={selectedTopicIds}
                            loaded={loaded}
                            loading={loading}
                            topics={topics}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className={`${BASE_CLASS}__header`}>
                        <Heading priority={HeadingPriority.Five}>
                            {t("selectFavoritesPage-header")}
                        </Heading>
                        <Paragraph>
                            {t("customizeExperience-selectFavorites-subheader")}
                        </Paragraph>
                    </div>

                    <div className={`${BASE_CLASS}__publications`}>
                        <div className={`${BASE_CLASS}__publications__list`}>
                            {availablePublications.map(
                                (publication: PublicationRecord) => {
                                    return (
                                        <PublicationCardSimple
                                            favorites={favorites}
                                            key={publication.id}
                                            onChangeFavorites={setFavorites}
                                            publication={publication}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className={`${BASE_CLASS}__button-container`}>
                {activeDot === CustomizeExperienceStatus.SelectFavorites && (
                    <Button
                        cssClassName={`${BASE_CLASS}__back-button`}
                        style={ButtonStyles.Tertiary}
                        disabled={disableContinueButton}
                        onClick={onClickBackToTopics}>
                        {t("back")}
                    </Button>
                )}
                {showLoader ? (
                    <Loader type={LoaderStyles.LinkGlyphGray} />
                ) : (
                    <Button
                        cssClassName={`${BASE_CLASS}__continue-button`}
                        disabled={disableContinueButton}
                        onClick={onClickContinue}>
                        {t("continue")}
                    </Button>
                )}
            </div>
            <ContactUsMessage />
        </div>
    );
};

// #endregion Component

export default CustomizeExperience;

const TwoDots = ({ activeDot }) => {
    return (
        <>
            <div
                className={`${BASE_CLASS}__dot ${activeDot === CustomizeExperienceStatus.SelectTopics ? "-active" : ""}`}
            />
            <div
                className={`${BASE_CLASS}__dot ${activeDot === CustomizeExperienceStatus.SelectFavorites ? "-active" : ""}`}
            />
        </>
    );
};
