import { CookieUtils } from "utilities/cookie-utils";

const ACCESS_TOKEN_KEY = "AccessToken";

export function saveAccessTokenInCookie(
    accessToken: string,
    expiresOn: Date | undefined
) {
    CookieUtils.setRaw(ACCESS_TOKEN_KEY, accessToken, {
        expires: expiresOn,
        path: "/",
    });
}

export function removeAccessTokenFromCookie(showSSODebugLogs: boolean) {
    if (showSSODebugLogs) {
        console.log(
            "In logout:  Removing access token from cookie.",
            ACCESS_TOKEN_KEY
        );
    }

    CookieUtils.clear(ACCESS_TOKEN_KEY);
}

export function getAccessTokenFromCookie() {
    return CookieUtils.get(ACCESS_TOKEN_KEY);
}
