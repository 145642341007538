import { RecordUtils } from "@rsm-hcd/javascript-core";
import NaturalLanguageSearchResult from "organisms/modals/natural-language-search-modal/interfaces/natural-language-search-result";
import { Record } from "immutable";

const defaultValues: NaturalLanguageSearchResult =
    RecordUtils.defaultValuesFactory<NaturalLanguageSearchResult>({
        path: "",
        title: "",
        text: "",
        relevanceScore: 0,
    });

export default class NaturalLanguageSearchResultRecord extends Record<NaturalLanguageSearchResult>(
    defaultValues
) {
    constructor(params?: NaturalLanguageSearchResult) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    public getFormattedRelevanceScore(): string {
        return this.relevanceScore.toPrecision(3);
    }

    public getPermalink(): string {
        return this.path.replace("/root", "");
    }
}
