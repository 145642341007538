import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState,
} from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export enum TeamOnboardingSteps {
    VerifyTeamInfo = 0,
    InviteTeamMembers = 1,
    Customize = 2,
}

export type StepContextUpdater = Dispatch<SetStateAction<number>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState = TeamOnboardingSteps.VerifyTeamInfo;
const defaultUpdater: StepContextUpdater = () => {};

export const StepContext = createContext<
    [TeamOnboardingSteps, StepContextUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Provider
// -------------------------------------------------------------------------------------------------

export const StepProvider = (props: PropsWithChildren<{}>) => {
    const [currentStep, setCurrentStep] = useState<number>(
        TeamOnboardingSteps.VerifyTeamInfo
    );

    return (
        <StepContext.Provider value={[currentStep, setCurrentStep]}>
            {props.children}
        </StepContext.Provider>
    );
};

// #endregion Provider

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export const useStepContext = () => {
    const [currentStep, syncCurrentStep] = useContext(StepContext);

    return { currentStep, syncCurrentStep };
};

// #endregion Hook
