import React, { useEffect, useState } from "react";
import GroupRecord from "models/view-models/group-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import InputFormField from "molecules/form-fields/input-form-field";
import Button from "atoms/buttons/button";
import { StringUtils } from "@rsm-hcd/javascript-core";
import { t } from "utilities/localization-utils";
import { DataConfiguration } from "constants/data-configuration";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import SelectFormField from "molecules/form-fields/select-form-field";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ToastManager } from "utilities/toast/toast-manager";
import ContactUsMessage from "organisms/resources/contact-us-message";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import GroupEligibleGroupService from "utilities/services/groups/group-eligible-groups-service";
import { CollectionUtils } from "utilities/collection-utils";
import { SelectOption } from "atoms/forms/select";
import { useGroupContext } from "utilities/contexts/group/group-context-provider";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface VerifyTeamInfoProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const MaximumTeamNameLength = DataConfiguration.ExtraShortStringLength;
const BASE_CLASS = "c-verify-team-info";
const CREATE_NEW_TEAM = "CREATE_NEW_TEAM";

// #endregion Constants

// -----------------------------------------------------------------------------------------7
// #region Component
// -----------------------------------------------------------------------------------------

const VerifyTeamInfo: React.FC<VerifyTeamInfoProps> = () => {
    const { globalState } = useGlobalState();
    const { unauthorizedResult } = globalState;
    const history = useHistory();
    const [isCreatingNewTeam, setIsCreatingNewTeam] = useState<boolean>(false);
    const { groupContext, setGroupContext } = useGroupContext();
    const [teamNameInput, setTeamNameInput] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | undefined>("");
    const isValidTeamName = StringUtils.isEmpty(errorMessage);

    const { list: getEligibleGroupsApi } = GroupEligibleGroupService.useList();
    const [eligibleGroups, setEligibleGroups] = useState<GroupRecord[]>([]);
    const [eligibleGroupsOptions, setEligibleGroupsOptions] = useState<
        SelectOption[]
    >([]);

    const hasEligibleTeamsForNewSubscription =
        unauthorizedResult != null &&
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_ELIGIBLE_TEAMS_FOUND_FOR_NEW_SUBSCRIPTION
        );

    useEffect(() => {
        const listEligibleGroupsApi = async () => {
            try {
                const { resultObjects } = await getEligibleGroupsApi();
                if (CollectionUtils.isEmpty(resultObjects)) {
                    setEligibleGroups([]);
                    setIsCreatingNewTeam(true);
                    return;
                }
                setEligibleGroups(resultObjects);
            } catch (error) {
                setErrorMessage(t("getTeamsError"));
                ToastManager.error(t("getTeamsError"));
            }
        };
        listEligibleGroupsApi();
    }, [getEligibleGroupsApi]);

    useEffect(() => {
        if (CollectionUtils.isEmpty(eligibleGroups)) {
            setEligibleGroupsOptions([]);
        }
        const options: SelectOption<any, any>[] = [
            {
                label: "",
                value: "",
                isPlaceholder: true,
            },
            ...eligibleGroups.map((group: GroupRecord) => {
                return {
                    label: group.name,
                    value: group.name,
                    data: group,
                };
            }),
            {
                label: t("createNewTeam"),
                value: CREATE_NEW_TEAM,
                data: null,
            },
        ];
        setEligibleGroupsOptions(options);
    }, [eligibleGroups]);

    const updateTeamName = () => {
        const finalGroup = groupContext!.with({
            name: teamNameInput,
        });

        const validationError = validateTeamName(finalGroup?.name);
        if (StringUtils.hasValue(validationError)) {
            setErrorMessage(validationError);
            return;
        }

        setGroupContext(groupContext!.with({ name: teamNameInput }));
    };

    const validateTeamName = (teamName?: string) => {
        if (StringUtils.isEmpty(teamName)) {
            return t("teamNameBlank");
        }

        if (teamName.length > MaximumTeamNameLength) {
            return t("teamNameMaxCharacters", {
                length: MaximumTeamNameLength,
            });
        }
    };

    const handleSelectChange = (e: any) => {
        if (e.value === CREATE_NEW_TEAM) {
            setIsCreatingNewTeam(true);
            setTeamNameInput("");
        } else {
            setTeamNameInput(e?.value);
            setGroupContext(e.data);
        }
    };

    const handleInputChange = (e: any) => {
        setTeamNameInput(e.currentTarget.value);
    };

    const handleOnContinueClick = async () => {
        if (isCreatingNewTeam) {
            updateTeamName();
        }
        history.push(siteMap.teamOnboarding.manage);
    };

    return (
        <div className={BASE_CLASS}>
            <p className={`${BASE_CLASS}__title`}>
                {t("welcomeToLiNK")}
                <br />
                <b>{t("createANewTeamToStartYourSubscription")}</b>
            </p>
            <div className={`${BASE_CLASS}__input-container`}>
                {hasEligibleTeamsForNewSubscription && !isCreatingNewTeam && (
                    <SelectFormField
                        cssClassName={`${BASE_CLASS}__select`}
                        label={`${t("teamName").toUpperCase()}:`}
                        options={eligibleGroupsOptions}
                        onChange={handleSelectChange}
                        value={teamNameInput}
                    />
                )}

                {(!hasEligibleTeamsForNewSubscription || isCreatingNewTeam) && (
                    <InputFormField
                        errorMessages={[errorMessage ?? ""]}
                        isValid={isValidTeamName}
                        label={`${t("teamName").toUpperCase()}:`}
                        maxLength={MaximumTeamNameLength}
                        onChange={handleInputChange}
                        placeholder={t("enterTeamName")}
                        value={teamNameInput}
                    />
                )}
            </div>
            <div>
                <Button
                    style={ButtonStyles.Primary}
                    size={ButtonSizes.Medium}
                    disabled={!StringUtils.hasValue(teamNameInput)}
                    onClick={handleOnContinueClick}>
                    {t("continue")}
                </Button>
            </div>
            <ContactUsMessage />
        </div>
    );
};

// #endregion Component

export default VerifyTeamInfo;
