import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import {
    AuthenticationContext,
    AuthenticationContextType,
} from "./authentication-context";
import {
    AuthenticationLifeCycleStatus,
    AuthenticationStrategyType,
} from "utilities/enumerations/authorization";
import { AuthenticationStrategyResult } from "utilities/services/auth/authentication-strategy-result";
import { DefaultAuthenticationWrapperComponent } from "utilities/services/auth/default-authentication-wrapper";
import { useAuthenticationFactory } from "utilities/contexts/authentication/use-authentication.factory";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import {
    ClientAuthError,
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCurrentIdentityProvider } from "utilities/atoms/current-identity-provider-atom";
import useNetworkInformation from "../network-information/use-network-information";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -----------------------------------------------------------------------------------------
// #region Provider
// -----------------------------------------------------------------------------------------

const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { isOnline } = useNetworkInformation();
    const { globalState, setGlobalState } = useGlobalState();
    const { build } = useCurrentIdentity();
    const [authenticationLifeCycleStatus, setAuthenticationLifeCycleStatus] =
        useState<AuthenticationLifeCycleStatus>(
            AuthenticationLifeCycleStatus.Uninitialized
        );

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [authenticationStrategyResult, setAuthenticationStrategyResult] =
        useState<AuthenticationStrategyResult | null>(null);

    const StrategyWrapper = useMemo(() => {
        if (authenticationStrategyResult == null) {
            return DefaultAuthenticationWrapperComponent;
        }
        return authenticationStrategyResult.getAuthWrapperComponent();
    }, [authenticationStrategyResult]);

    const { authenticationFactory } = useAuthenticationFactory();
    const { delete: deleteUserLogin } = UserLoginService.useDelete();
    const { inProgress } = useMsal();
    const { showSSODebugLogs } = useFeatureFlags();

    const verifyLogin = useCallback(
        async (
            authenticationStrategyType: AuthenticationStrategyType
        ): Promise<void> => {
            try {
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.Loading
                );
                const authStrategyResult = await authenticationFactory(
                    authenticationStrategyType
                );
                setAuthenticationStrategyResult(authStrategyResult);
                const userLogin =
                    await authStrategyResult.authStrategy.verifyLogin();
                await build(userLogin);
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.LoggedIn
                );
            } catch (error) {
                console.error(error);

                if (
                    error instanceof InteractionRequiredAuthError ||
                    error instanceof ClientAuthError ||
                    !isOnline
                ) {
                    if (globalState.isAuthenticated()) {
                        setAuthenticationLifeCycleStatus(
                            AuthenticationLifeCycleStatus.LoggedIn
                        );
                        return;
                    }
                }

                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToVerifyLogin
                );
                setErrorMessage(`Failed to verify login: ${error}`);
            }
        },
        [authenticationFactory, build, globalState, isOnline]
    );

    const deleteCurrentUserLoginFromApi =
        useCallback(async (): Promise<void> => {
            const userLoginId = globalState.currentIdentity?.userLogin?.id!;
            if (userLoginId == null) {
                if (showSSODebugLogs) {
                    console.log(
                        "In Logout:  deleteCurrentUserLoginFromApi threw an error.1"
                    );
                }
                throw new Error("Unable to delete user login from the API");
            }

            const response = await deleteUserLogin(userLoginId);
            const isDeleted = response.status === 200;
            if (!isDeleted) {
                if (showSSODebugLogs) {
                    console.log("Unable to delete user login from the API");
                }
                throw new Error("Unable to delete user login from the API");
            }
        }, [
            deleteUserLogin,
            globalState.currentIdentity?.userLogin?.id,
            showSSODebugLogs,
        ]);

    const deleteEmulatedUserLoginFromApi = useCallback(
        (emulatedUserId: number) => {
            deleteUserLogin(emulatedUserId);
        },
        [deleteUserLogin]
    );

    const logout = useCallback(
        async (loginSlug?: string): Promise<void> => {
            try {
                if (authenticationStrategyResult == null) {
                    return;
                }

                if (showSSODebugLogs) {
                    console.log(
                        "In LOGOUT: authenticationStrategyResult is not null",
                        authenticationStrategyResult.authStrategy
                    );
                }

                try {
                    await deleteCurrentUserLoginFromApi();
                } catch (error) {
                    console.error(
                        "In Logout:  Error deleting user login from API",
                        error
                    );
                }

                if (showSSODebugLogs) {
                    console.log(
                        "In LOGOUT: successfully deleted deleteCurrentUserLoginFromApi"
                    );
                }

                globalState.setUnauthenticated();

                if (showSSODebugLogs) {
                    console.log(
                        "In LOGOUT: after globalState.setUnauthenticated"
                    );
                }

                await authenticationStrategyResult.authStrategy.logout(
                    loginSlug
                );

                //You leave and don't return.
                if (showSSODebugLogs) {
                    console.log("In LOGOUT: I DONT THINK WE EVER GET HERE!!");
                }
            } catch (error) {
                console.error(error);
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToLogout
                );
                setErrorMessage(`Failed to logout: ${error}`);
            }
        },
        [
            authenticationStrategyResult,
            deleteCurrentUserLoginFromApi,
            globalState,
            showSSODebugLogs,
        ]
    );

    const endEmulationSession = useCallback(async (): Promise<boolean> => {
        try {
            if (authenticationStrategyResult == null) {
                return false;
            }
            const emulatedUserLoginId =
                globalState.currentIdentity?.userLogin?.id!;
            if (emulatedUserLoginId) {
                await authenticationStrategyResult.authStrategy.logout();
                deleteEmulatedUserLoginFromApi(emulatedUserLoginId);

                const authStrategyResult = await authenticationFactory(
                    AuthenticationStrategyType.AzureB2C
                );
                setGlobalState((prev) =>
                    prev.setAuthenticationStrategyType(
                        AuthenticationStrategyType.AzureB2C
                    )
                );
                setAuthenticationStrategyResult(authStrategyResult);
            }
            return true;
        } catch (error) {
            console.error(error);
            setAuthenticationLifeCycleStatus(
                AuthenticationLifeCycleStatus.FailedToEndEmulation
            );
            setErrorMessage(`Failed to logout: ${error}`);
            return false;
        } finally {
            console.log("Ending emulation session");
        }
    }, [
        authenticationFactory,
        authenticationStrategyResult,
        deleteEmulatedUserLoginFromApi,
        globalState.currentIdentity?.userLogin?.id,
        setGlobalState,
    ]);

    const isAuthenticated = useMemo(() => {
        return globalState.isAuthenticated();
    }, [globalState]);

    const allowStrategySwitching = useMemo((): ((
        authenticationStrategyTypeToChangeTo: AuthenticationStrategyType
    ) => boolean) => {
        return (
            authenticationStrategyTypeToChangeTo: AuthenticationStrategyType
        ): boolean => {
            if (!isAuthenticated) {
                return true;
            }

            switch (globalState.authStrategyType) {
                case AuthenticationStrategyType.AzureB2C:
                    return (
                        authenticationStrategyTypeToChangeTo !==
                        AuthenticationStrategyType.MultiTenantAuth
                    );
                case AuthenticationStrategyType.Emulation:
                    return (
                        authenticationStrategyTypeToChangeTo ===
                        AuthenticationStrategyType.AzureB2C
                    );
                case AuthenticationStrategyType.MultiTenantAuth:
                    return false;
                default:
                    return true;
            }
        };
    }, [globalState.authStrategyType, isAuthenticated]);

    const signUpRedirect = useCallback(
        async (
            authenticationStrategyType: AuthenticationStrategyType = AuthenticationStrategyType.AzureB2C,
            redirectStartPage: string
        ): Promise<void> => {
            try {
                if (!allowStrategySwitching(authenticationStrategyType)) {
                    console.error("Auth strategies switching not allowed.");
                    return;
                }

                const authStrategyResult = await authenticationFactory(
                    authenticationStrategyType
                );
                setGlobalState((prev) =>
                    prev.setAuthenticationStrategyType(
                        authenticationStrategyType
                    )
                );
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.Loading
                );

                if (showSSODebugLogs) {
                    console.log("inProgress:", inProgress);
                }

                authStrategyResult.authStrategy.signUp(redirectStartPage);
            } catch (error) {
                console.error(error);
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToLogin
                );
                setErrorMessage(`Failed to login: ${error}`);
            }
        },
        [
            allowStrategySwitching,
            authenticationFactory,
            inProgress,
            setGlobalState,
            showSSODebugLogs,
        ]
    );

    const forgotPasswordRedirect = useCallback(
        async (
            authenticationStrategyType: AuthenticationStrategyType = AuthenticationStrategyType.AzureB2C,
            isComingFromLogin: boolean
        ): Promise<void> => {
            try {
                if (!allowStrategySwitching(authenticationStrategyType)) {
                    console.error("Auth strategies switching not allowed.");
                    return;
                }

                const authStrategyResult = await authenticationFactory(
                    authenticationStrategyType
                );
                setGlobalState((prev) =>
                    prev.setAuthenticationStrategyType(
                        authenticationStrategyType
                    )
                );
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.Loading
                );

                if (showSSODebugLogs) {
                    console.log("inProgress:", inProgress);
                }

                authStrategyResult.authStrategy.forgotPassword(
                    isComingFromLogin
                );
            } catch (error) {
                console.error(error);
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToLogin
                );
                setErrorMessage(`Failed to login: ${error}`);
            }
        },
        [
            allowStrategySwitching,
            authenticationFactory,
            inProgress,
            setGlobalState,
            showSSODebugLogs,
        ]
    );

    const login = useCallback(
        async (
            authenticationStrategyType: AuthenticationStrategyType
        ): Promise<void> => {
            try {
                if (!allowStrategySwitching(authenticationStrategyType)) {
                    console.error("Auth strategies switching not allowed.");
                    return;
                }

                const authStrategyResult = await authenticationFactory(
                    authenticationStrategyType
                );
                setGlobalState((prev) =>
                    prev.setAuthenticationStrategyType(
                        authenticationStrategyType
                    )
                );
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.Loading
                );

                if (showSSODebugLogs) {
                    console.log("inProgress:", inProgress);
                }

                const loginResponse =
                    await authStrategyResult.authStrategy.login();
                if (loginResponse.shouldVerifyLogin) {
                    await verifyLogin(authenticationStrategyType);
                }
            } catch (error) {
                console.error(error);
                setAuthenticationLifeCycleStatus(
                    AuthenticationLifeCycleStatus.FailedToLogin
                );
                setErrorMessage(`Failed to login: ${error}`);
            }
        },
        [
            allowStrategySwitching,
            authenticationFactory,
            inProgress,
            setGlobalState,
            showSSODebugLogs,
            verifyLogin,
        ]
    );

    const {
        currentIdentityProvider,
        setCurrentIdentityProvider,
        setIdentityProviderFromLocalStorage,
    } = useCurrentIdentityProvider();

    useEffect(() => {
        if (globalState.authStrategyType == null) {
            return;
        }

        if (inProgress !== InteractionStatus.None) {
            if (showSSODebugLogs) {
                console.log("Interaction In Progress.  Returning...");
            }
            return;
        }
        if (
            globalState.authStrategyType ===
                AuthenticationStrategyType.MultiTenantAuth &&
            currentIdentityProvider == null
        ) {
            //grab from localstorage and rehydrate:
            setIdentityProviderFromLocalStorage();
            return;
        }

        if (
            authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.Uninitialized
        ) {
            verifyLogin(globalState.authStrategyType);
        }
    }, [
        authenticationLifeCycleStatus,
        globalState,
        globalState.authStrategyType,
        isAuthenticated,
        verifyLogin,
        currentIdentityProvider,
        inProgress,
        setCurrentIdentityProvider,
        setIdentityProviderFromLocalStorage,
        showSSODebugLogs,
    ]);

    const authenticationContext: AuthenticationContextType = {
        logout,
        login,
        authenticationLifeCycleStatus,
        errorMessage,
        endEmulationSession,
        signUpRedirect,
        forgotPasswordRedirect,
    };

    return (
        <>
            <AuthenticationContext.Provider value={authenticationContext}>
                {<StrategyWrapper>{children}</StrategyWrapper>}
            </AuthenticationContext.Provider>
        </>
    );
};

// #endregion Provider

// -----------------------------------------------------------------------------------------
// #region Provider Hook
// -----------------------------------------------------------------------------------------

const useAuthentication = () => {
    const {
        login,
        logout,
        authenticationLifeCycleStatus,
        errorMessage,
        endEmulationSession,
        signUpRedirect,
        forgotPasswordRedirect,
    } = React.useContext(AuthenticationContext);

    return {
        login,
        logout,
        authenticationLifeCycleStatus,
        errorMessage,
        endEmulationSession,
        signUpRedirect,
        forgotPasswordRedirect,
    };
};

// #endregion Provider Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AuthenticationProvider, useAuthentication };
// #endregion Exports
