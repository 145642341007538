import AdminChangeSummaryService from "utilities/services/admin/change-summaries/change-summary-service";
import ChangeSummaryService from "utilities/services/change-summaries/change-summary-service";
import ChangesSummaryAtom from "./change-summary-atom";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { ReactNode } from "react";
import { ResultRecord } from "@rsm-hcd/javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { atom, useAtom } from "jotai";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useParams } from "react-router-dom";

const defaultValues = {
    externalId: undefined,
    titleClassName: undefined,
    title: undefined,
    body: undefined,
    changes: undefined,
};

const contentAtom = atom<Content>(defaultValues);

export interface ChangeIndicatorRouteParams {
    code: string;
    edition: string;
}
export interface Content {
    externalId?: string;
    titleClassName?: string;
    title?: ReactNode;
    body?: ReactNode;
    changes?: string;
    priorLocation?: string;
    label?: JSX.Element | string;
    additionalTopPadding?: number;
    contentType?: PublicationComponentType;
}

const useChangeIndicatorDiffPanel = () => {
    const [content, setContent] = useAtom(contentAtom);
    const [changeSummaries, setChangeSummaries] = useAtom(ChangesSummaryAtom);
    const showDiffPanel = content.externalId != null;
    const { code, edition } = useParams<ChangeIndicatorRouteParams>();
    const { currentIdentity } = useGlobalState().globalState;

    const isAdmin =
        currentIdentity?.isAdmin() || currentIdentity?.isAuthorOrPublisher();
    const listApi = isAdmin
        ? AdminChangeSummaryService.list
        : ChangeSummaryService.list;

    const handleClose = () => {
        var scrollElement = document.getElementById(content.externalId!);
        setContent(defaultValues);
        //setTimeout is used to push the scroll to the end of the callstack
        setTimeout(() => scrollElement?.scrollIntoView({ block: "start" }));
    };

    const handleOpen = (content: Content) => {
        setContent(content);
        getUserChangeSummaries(content.externalId!, code, edition);
        var scrollElement = document.getElementById(content.externalId!);
        //setTimeout is used to push the scroll to the end of the callstack
        setTimeout(() => scrollElement?.scrollIntoView({ block: "start" }));
    };

    const getUserChangeSummaries = async (
        externalId: string,
        code: string,
        edition: string
    ) => {
        try {
            const prevChangeSummary = await listApi({
                externalId,
                code,
                edition,
            });
            setChangeSummaries(prevChangeSummary.resultObjects);
        } catch (error) {
            if (error instanceof ResultRecord) {
                ToastManager.error(error.listErrorMessages());
            }
        }
    };

    return {
        handleClose,
        handleOpen,
        showDiffPanel,
        content,
        changeSummaries,
        setChangeSummaries,
    };
};

export default useChangeIndicatorDiffPanel;
