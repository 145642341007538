import CaapsRecordUtils from "utilities/caaps-record-utils";
import ChapterRecord from "models/view-models/chapter-record";
import FigureSection from "organisms/section-detail-components/figure-section";
import InformationalNoteSection from "organisms/section-detail-components/informational-note-section";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import PublicationRecord from "models/view-models/publication-record";
import StringUtils from "utilities/string-utils";
import XmlUtils from "utilities/xml-utils";
import type Article from "models/interfaces/article";
import type { ReactNode } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import { Record } from "immutable";
import { RecordUtils } from "@rsm-hcd/javascript-core";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultValues: Article =
    RecordUtils.auditableDefaultValuesFactory<Article>({
        body: undefined,
        changes: "",
        chapter: undefined,
        chapterId: undefined,
        displaySequence: undefined,
        externalId: "",
        label: undefined,
        nfpaLabel: undefined,
        priorLocation: undefined,
        parentId: undefined,
        publication: undefined,
        publicationId: 0,
        title: undefined,
        titleAsPlainText: undefined,
    });

// #endregion Constants

export default class ArticleRecord
    extends Record(defaultValues)
    implements Article
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Article) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }
        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }
        if (params.chapter != null) {
            params.chapter = RecordUtils.ensureRecord(
                params.chapter,
                ChapterRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no body is present, returns an empty string.
     */
    public getBody(debug = false): ReactNode {
        // If we have no body, just return an empty string.
        if (this.body == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            ...XmlUtils.publicationTableConverters,
            div: XmlUtils.xmlConverter(XmlUtils.FragmentWrapper),
            fig: XmlUtils.xmlConverter(FigureSection),
            note: XmlUtils.xmlConverter(InformationalNoteSection),
        };

        return XmlUtils.convert(this.body!, xmlConverters, debug);
    }

    /**
     * Gets the full 'display' title of the Article in the format of: Article {Label} {Title}
     * @example Article 402 Fixture Wires
     */
    public getDisplayTitle(): string {
        if (!this.hasLabel()) {
            return this.getTitle();
        }

        return `${this.getLabel()} ${this.getTitle()}`;
    }

    /**
     * Returns an anchor href string to an element in the DOM representing this Article.
     */
    public getHashLink(): string {
        return `#${this.externalId}`;
    }

    /**
     * Returns the label (if it exists). Otherwise, returns an empty string.
     */
    public getLabel(): string {
        if (!this.hasLabel()) {
            return "";
        }

        return StringUtils.translateDomainTerms(
            this.label,
            this.publication?.locale
        );
    }
    public getDisplayLabel(): string {
        if (!this.hasLabel()) {
            return "";
        }

        return StringUtils.translateDomainTerms(
            this.label,
            this.publication?.locale
        );
    }

    /**
     * Get the application route to view the current Article in Book View
     */
    public getRoute(
        isAdminPreview: boolean = false,
        includeHash: boolean = true
    ): string {
        const anchor = PublicationAnchorRecord.fromArticleRecord(
            this,
            isAdminPreview
        );

        return anchor.getRoute(includeHash) ?? "#";
    }

    /**
     * Returns the title stripped of XML.
     *
     * @returns
     */
    public getTitle(): string {
        if (StringUtils.isEmpty(this.titleAsPlainText)) {
            return "";
        }

        return StringUtils.translateDomainTerms(
            this.titleAsPlainText,
            this.publication?.locale
        );
    }

    /**
     * Returns the title (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no title is present, returns an empty string.
     */
    public getTitleAsXml(): ReactNode {
        // If we have no title, just return an empty string.
        if (StringUtils.isEmpty(this.title)) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        return XmlUtils.convert(this.title!, xmlConverters);
    }

    /**
     * @returns Whether or not the record title or body XML has changes.
     * Uses XmlUtils.hasAttributeDeep to recursively check every element in the XML tree.
     */
    public doesTitleOrBodyHaveChanges(): boolean {
        const hasChanges = CaapsRecordUtils().doesTitleOrBodyHaveChanges(
            this.title,
            this.body,
            this.changes
        );

        return hasChanges;
    }

    /**
     * Returns the title stripped of XML with the label prepended.
     *
     * @returns
     */
    public getTitleWithLabel(): string {
        if (!this.hasLabel()) {
            return this.getTitle();
        }

        return `${this.getLabel()} ${this.getTitle()}`;
    }

    /**
     * Returns the title stripped of XML with the Nfpa label prepended.
     *
     * @returns
     */
    public getTitleWithNfpaLabel(): string {
        if (!this.nfpaLabel) {
            return this.getTitle();
        }

        return `${this.nfpaLabel} - ${this.getTitle()}`;
    }

    /**
     * Returns true if article has label that is not null or empty
     */
    public hasLabel(): boolean {
        return StringUtils.hasValue(this.label);
    }

    /**
     * Convenience method to check if this record is new or a persisted entity
     */
    public isPersisted(): boolean {
        return this.id != null && this.id > 0;
    }

    /**
     * Transforms this Article into an object representing the PublicationSidebarNavItem interface
     *
     * Useful when mapping out a set of ArticleRecords to nav items.
     *
     * @param {Partial<PublicationSidebarNavItem>} [values] (Optional) Values to override the default transformation
     */
    public toNavItem(
        values?: Partial<PublicationSidebarNavItem>
    ): PublicationSidebarNavItem {
        const defaultNavItem: PublicationSidebarNavItem = {
            dataTestDisplaySequence: this.displaySequence,
            id: this.id!,
            label: this.getLabel(),
            route: this.getRoute(),
            title: this.getTitle(),
            type: PublicationComponentType.Article,
            searchForLinkOnPage: false,
        };

        return Object.assign(defaultNavItem, values);
    }

    public toNavItemFromRelatedRecords(
        publication?: PublicationRecord,
        chapter?: ChapterRecord,
        isAdminPreview?: boolean
    ): PublicationSidebarNavItem {
        const article = this.with({
            publication,
            chapter,
        });

        return article.toNavItem({
            route: article.getRoute(isAdminPreview),
        });
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Article>} values
     */
    public with(values: Partial<Article>): ArticleRecord {
        return new ArticleRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds PublicationRecord relationship based on ExternalId from a list of PublicationRecords.
     *
     * @param {PublicationRecord[]} [publications]
     */
    public withPublication(publications?: PublicationRecord[]): ArticleRecord {
        if (CollectionUtils.isEmpty(publications)) {
            return this;
        }

        return this.with({
            publication: publications.find(
                (publication: PublicationRecord) =>
                    publication.id === this.publicationId
            ),
        });
    }
}
