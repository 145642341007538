enum TiaType {
    Addition = 0,
    Change = 1,
    Deletion = 2,
    Initial = 3,
    Moved = 4,
    MovedWithChanges = 5,
}

export default TiaType;
