import CreateGroupInvitationDtoRecord from "models/view-models/group-invitations/create-group-invitation-dto-record";
import GroupInvitationService, {
    GroupInvitationResourcePathParams,
} from "utilities/services/group-invitations/group-invitation-service";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import useAdminTeamDetails from "pages/admin/teams/use-admin-team-details";
import usePageErrors from "utilities/hooks/use-page-errors";
import { ResultErrorRecord } from "@rsm-hcd/javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import { useCallback, useMemo, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { MagicLinkUrlUtils } from "utilities/magic-link-url-utils";

interface UseGroupInviteHook {
    sendGroupInvite: () => Promise<boolean>;
    errors?: ResultErrorRecord[];
}

interface UseGroupInviteHookOptions {
    groupId: number;
    emails: string[];
}

export function useGroupInvite(
    props: UseGroupInviteHookOptions
): UseGroupInviteHook {
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { create: createGroupInvitationsApi } =
        GroupInvitationService.useCreate();
    const { delete: deletePendingGroupInvitationsApi } =
        GroupInvitationService.useDeletePendingInvites();
    const { handlePageLoadError } = usePageErrors();
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const groupId = props.groupId;
    const emails = props.emails;
    const ERROR_SENDING_INVITATION_MESSAGE = t(
        "groupInvite-sendInvitationError"
    );
    const ERROR_DELETE_PENDING_INVITATION_MESSAGE = t(
        "groupInvite-deletePendingInvitationError"
    );

    const { userRoleGroup } = useAdminTeamDetails({ teamId: groupId });

    const currentUserRoleGroup = useMemo(
        () =>
            userRoleGroup
                ? userRoleGroup
                : (currentIdentity?.userRoles.find(
                      (ur) => ur.id === currentIdentity?.userLogin?.userRoleId
                  )?.userRoleGroup ?? new UserRoleGroupRecord()),
        [currentIdentity?.userLogin, currentIdentity?.userRoles, userRoleGroup]
    );

    const magicLinkUrlTemplate = MagicLinkUrlUtils.getMagicLinkUrlTemplate(
        globalState,
        currentUserRoleGroup
    );

    const deletePendingGroupInvites =
        useCallback(async (): Promise<boolean> => {
            try {
                const groupInvitationPathParams: GroupInvitationResourcePathParams =
                    {
                        groupId: groupId,
                    };

                // ServiceHookFactory expects an Id but there is none. Setting it to 0
                const response = await deletePendingGroupInvitationsApi(
                    0,
                    groupInvitationPathParams
                );

                if (response?.result?.hasErrors()) {
                    setErrors(response.result?.errors);
                    return false;
                }

                return true;
            } catch (error) {
                if (error) {
                    setErrors([
                        new ResultErrorRecord({
                            message: ERROR_SENDING_INVITATION_MESSAGE,
                        }),
                    ]);
                }
                ToastManager.error(t("groupInvite-sendInvitationError"));
                return false;
            }
        }, [
            ERROR_SENDING_INVITATION_MESSAGE,
            deletePendingGroupInvitationsApi,
            groupId,
        ]);

    const sendGroupInvite = useCallback(async (): Promise<boolean> => {
        try {
            if (!(await deletePendingGroupInvites())) {
                setErrors([
                    new ResultErrorRecord({
                        message: ERROR_DELETE_PENDING_INVITATION_MESSAGE,
                    }),
                ]);
                ToastManager.error(t("groupInvite-sendInvitationError"));
                return false;
            }

            const dto = new CreateGroupInvitationDtoRecord({
                emails: emails,
                groupId: groupId,
                magicLinkUrlTemplate: magicLinkUrlTemplate,
            });
            const response = await createGroupInvitationsApi(dto, {
                groupId: groupId,
            });

            if (response.result?.hasErrors() || response.resultObject == null) {
                setErrors(response.result?.errors);
                handlePageLoadError(response.result?.resultObject);
            }

            return true;
        } catch (error) {
            if (error) {
                setErrors([
                    new ResultErrorRecord({
                        message: ERROR_SENDING_INVITATION_MESSAGE,
                    }),
                ]);
            }
            ToastManager.error(t("groupInvite-sendInvitationError"));

            return false;
        }
    }, [
        ERROR_DELETE_PENDING_INVITATION_MESSAGE,
        ERROR_SENDING_INVITATION_MESSAGE,
        createGroupInvitationsApi,
        deletePendingGroupInvites,
        emails,
        groupId,
        handlePageLoadError,
        magicLinkUrlTemplate,
    ]);

    return {
        sendGroupInvite,
        errors,
    };
}
