import Loader from "atoms/loaders/loader";
import { ArticleParams } from "interfaces/routing/route-params";
import CurrentPublicationEntityRecord from "models/view-models/atoms/current-publication-entity-record";
import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationContentArea from "organisms/publication-content/publication-content-area";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import { CollectionUtils } from "utilities/collection-utils";
import useUpdateAtomEffect from "utilities/hooks/atoms/use-update-atom-value-effect";
import useParts from "utilities/hooks/domain/publications/chapters/articles/parts/use-parts";
import useSections from "utilities/hooks/domain/publications/chapters/articles/sections/use-sections";
import useArticle from "utilities/hooks/domain/publications/chapters/articles/use-article";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";

import HiddenContentAlertBanner from "organisms/alerts/hidden-content-alert-banner";
import CaapsItemTitleArea from "organisms/caaps-items/caaps-item-title-area";
import TableOfContentsSection from "organisms/table-of-contents/table-of-contents-section";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useBreakpoint from "utilities/hooks/use-breakpoint";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ArticleContentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-page-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ArticleContent: React.FunctionComponent<ArticleContentProps> = (
    props: ArticleContentProps
) => {
    const { publication } = useCurrentPublication();

    const {
        code,
        edition,
        chapterNfpaLabel,
        nfpaLabel,
        chapterId,
        id: articleId,
    } = useParams<ArticleParams>();
    const { t } = useLocalization();
    const [resetFocus, setResetFocus] = useState<boolean>(false);

    const loaderText = t("loadingItem", {
        item: t("article"),
    });
    const articleContentRef = useRef<HTMLDivElement>(null);

    const { isAdminPreview } = useAdminPreview();

    const { tableOfContents } = useBookviewTableOfContents();

    const isTabletOrSmaller = useBreakpoint(Breakpoints.Tablet);

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        resultObject: chapter,
        loading: chapterLoading,
        errors: chapterErrors,
    } = useChapter({
        publicationId: publication?.id,
        chapterId: chapterId,
        nfpaLabel: chapterNfpaLabel,
    });

    const {
        resultObject: article,
        loading: articleLoading,
        loaded: articleLoaded,
        errors: articleErrors,
    } = useArticle({
        chapterId: isAdminPreview ? chapterId : chapter.id,
        code,
        edition,
        nfpaLabel,
        articleId,
        publicationId: publication?.id,
    });

    const {
        resultObject: parts,
        loading: partsLoading,
        errors: partsErrors,
    } = useParts({
        articleId: article?.id,
        chapterId: chapter?.id,
        pending: chapterLoading || articleLoading,
        publicationId: publication?.id,
    });

    const {
        resultObject: sections,
        loading: sectionLoading,
        errors: sectionsErrors,
    } = useSections({
        articleId: article?.id,
        chapterId: chapter?.id,
        pending: chapterLoading || articleLoading,
        publicationId: publication?.id,
    });

    const loading = useLoading(
        articleLoading,
        chapterLoading,
        partsLoading,
        sectionLoading
    );

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    const pageErrors = useErrors(
        chapterErrors,
        articleErrors,
        sectionsErrors,
        partsErrors
    );

    useErrorBoundary(pageErrors);

    useEffect(
        function setFocus() {
            if (resetFocus && articleContentRef && articleContentRef.current) {
                setResetFocus(false);
                articleContentRef.current.focus({ preventScroll: true });
            }
        },
        [resetFocus, article, articleLoading]
    );

    useUpdateAtomEffect({
        atom: CurrentPublicationEntityAtom,
        enabled: article != null && articleLoaded,
        value: new CurrentPublicationEntityRecord({
            entityId: article.id!,
            entityTitle: article.getDisplayTitle(),
            selected: true,
            type: "Article",
        }),
    });

    const sectionsTOC = useMemo(
        () =>
            tableOfContents.chapters
                ?.find((c) => c.articles?.some((a) => a.id === article.id))
                ?.articles?.find((a) => a.id === article.id)?.sections ?? [
                new SectionTableOfContentsRecord(),
            ],
        [article.id, tableOfContents.chapters]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const memoizedChildren = useMemo(
        () => (
            <CaapsItemTitleArea
                publication={publication}
                changes={article.changes}
                externalId={article.externalId}
                titleClassName={""}
                record={article}
                priorLocation={article.priorLocation}
                publicationComponentType={PublicationComponentType.Article}
                doesHaveTitleOrBodyChanges={article.doesTitleOrBodyHaveChanges()}>
                {!isTabletOrSmaller && (
                    <TableOfContentsSection
                        parts={parts}
                        sections={sectionsTOC}
                        parentType={SectionParentType.Article}
                    />
                )}
            </CaapsItemTitleArea>
        ),
        [article, isTabletOrSmaller, parts, publication, sectionsTOC]
    );

    return (
        <Fragment>
            {CollectionUtils.hasValues(pageErrors) && (
                <UnorderedList
                    cssClassName={`${BASE_CLASS_NAME}__errors`}
                    listItems={pageErrors}
                />
            )}
            {loading ? (
                <Loader accessibleText={loaderText} />
            ) : (
                <>
                    <HiddenContentAlertBanner
                        id={article.id}
                        type={PublicationComponentType.Article}
                    />
                    <PublicationContentArea
                        memoizedChildren={memoizedChildren}
                        parts={parts}
                        sections={sections}
                        externalId={article.externalId}
                    />
                </>
            )}
        </Fragment>
    );
    // #endregion Render
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ArticleContent;

// #endregion Exports
