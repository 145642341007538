import * as React from "react";
import Anchor from "atoms/anchors/anchor";
import GroupRecord from "models/view-models/group-record";
import Heading from "atoms/typography/heading";
import NotFoundPage from "pages/errors/not-found";
import Stepper from "organisms/stepper/stepper";
import logo from "assets/images/nfpa-link-color.png";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { GroupContext } from "utilities/contexts/group/group-context-provider";
import { NestedRoutes } from "utilities/routing/nested-route";
import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import { TeamOnboardingSteps } from "utilities/contexts/stepper/step-context-provider";
import { siteMap } from "internal-sitemap";
import { useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import { useLocation } from "react-router";
import OnboardingFooter from "molecules/footer/onboarding-footer";

const BASE_CLASS = "c-team-onboarding-layout";

const TeamOnboardingLayout: React.FC<any> = (props: any) => {
    const { globalState } = useGlobalState();
    const { internalizeTeams } = useFeatureFlags();
    const { t } = useLocalization();
    const steps = [
        t("verifyTeamInformation"),
        t("manageTeamSettings"),
        t("individualizedExperience"),
    ];
    const [group, setGroup] = React.useState<GroupRecord>();
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const location = useLocation();
    const pathName = location.pathname.split("/").pop();
    const [header, setHeader] = React.useState<string>(
        t("verifyTeamInformation")
    );

    useEffect(() => {
        if (!group)
            setGroup(
                globalState.currentIdentity?.getCurrentTeam() ??
                    new GroupRecord()
            );
    }, [group, globalState]);

    useEffect(() => {
        if (pathName === "verify") {
            setCurrentStep(TeamOnboardingSteps.VerifyTeamInfo);
            setHeader(t("verifyTeamInformation"));
        } else if (pathName === "manage") {
            setCurrentStep(TeamOnboardingSteps.InviteTeamMembers);
            setHeader(t("manageTeamSettings"));
        } else if (pathName === "customize") {
            setCurrentStep(TeamOnboardingSteps.Customize);
            setHeader(t("individualizedExperience"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, pathName]);

    return (
        <>
            {internalizeTeams ? (
                <GroupContext.Provider value={[group, setGroup]}>
                    <React.Fragment>
                        <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
                        <div className={BASE_CLASS}>
                            <div className={`${BASE_CLASS}__menu`}>
                                <div className={`${BASE_CLASS}__menu__logo`}>
                                    <Anchor to={siteMap.home} ariaLabel="Home">
                                        <img src={logo} alt="Home" />
                                    </Anchor>
                                </div>
                            </div>
                            <div className={`${BASE_CLASS}__content`}>
                                <SkipNavContent tabIndex={-1}>
                                    <Heading
                                        cssClassName={`${BASE_CLASS}__content__header`}>
                                        {header}
                                    </Heading>
                                    <div
                                        className={`${BASE_CLASS}__content__stepper`}>
                                        <Stepper
                                            steps={steps}
                                            currentStep={currentStep}
                                        />
                                    </div>

                                    <NestedRoutes routes={props.routes} />

                                    {props.children}
                                </SkipNavContent>
                            </div>
                            <OnboardingFooter />
                        </div>
                    </React.Fragment>
                </GroupContext.Provider>
            ) : (
                <NotFoundPage />
            )}
        </>
    );
};

export default TeamOnboardingLayout;
