import AboutModal from "organisms/modals/about-modal/about-modal";
import AccessibleList from "molecules/accessible-list/accessible-list";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import LogOutButton from "molecules/log-out-button.tsx/log-out-button";
import NotificationDotIcon from "atoms/icons/notification-dot-icon";
import ProfileMenuTeamName from "organisms/profile/profile-menu-team-name";
import React, { useRef, useState } from "react";
import SwitchProfileButton from "./switch-profile-button";
import useOutsideClick from "utilities/hooks/use-outside-click";
import { AppNameTm } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import ProfileAvatar from "./profile-avatar";

interface ProfileMenuDetailProps {
    focusFirstItem: boolean;
    handleOpenAnnouncementModal: (value: boolean) => void;
    hideActions?: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
    setSwitchProfileModalIsOpen: (value: React.SetStateAction<boolean>) => void;
    showWhatsNew?: boolean;
    triggerRef: React.RefObject<HTMLButtonElement>;
    unreadAnnouncement?: boolean;
}

const BASE_CLASS_NAME = "c-profile-menu";

const ProfileMenuDetail: React.FunctionComponent<ProfileMenuDetailProps> = (
    props: ProfileMenuDetailProps
) => {
    const {
        focusFirstItem,
        handleOpenAnnouncementModal,
        hideActions,
        setIsOpen,
        setSwitchProfileModalIsOpen,
        showWhatsNew,
        triggerRef,
        unreadAnnouncement,
    } = props;

    const [showAboutModal, setShowAboutModal] = useState<boolean>(false);
    const profileMenuRef = useRef<HTMLDivElement>(null);
    const { globalState } = useGlobalState();
    const { t } = useLocalization();

    useOutsideClick(profileMenuRef, () => {
        setIsOpen(false);
    });

    const fullName =
        globalState.currentIdentity?.user?.getFirstAndLastNameOrReturnDefault();
    const supportLabel = t("support");
    const accountSettingsLabel = t("accountSettings");
    const announcementLabel = t("announcements-whatsNew");
    const aboutApp = t("aboutApp", { appName: AppNameTm });

    return (
        <div className={`${BASE_CLASS_NAME}__menu`}>
            <div className={`${BASE_CLASS_NAME}__menu__user-info`}>
                <div
                    className={`${BASE_CLASS_NAME}__menu__user-info__initials`}>
                    <ProfileAvatar />
                </div>
                <div>
                    <div
                        className={`${BASE_CLASS_NAME}__menu__user-info__name`}>
                        {fullName}
                    </div>
                    <ProfileMenuTeamName />
                </div>
            </div>
            <div
                ref={profileMenuRef}
                className={`${BASE_CLASS_NAME}__menu__actions`}>
                <AccessibleList
                    focusFirstItem={focusFirstItem}
                    onEsc={() => {
                        triggerRef.current?.focus();
                        setIsOpen(false);
                    }}>
                    {!hideActions && (
                        <SwitchProfileButton
                            setIsOpen={setIsOpen}
                            setSwitchProfileModalIsOpen={
                                setSwitchProfileModalIsOpen
                            }
                        />
                    )}
                    <Anchor to={siteMap.support.faq} cssClassName="c-button">
                        {supportLabel}
                    </Anchor>
                    {!hideActions && (
                        <Anchor
                            cssClassName="c-button"
                            onClick={() =>
                                setTimeout(() => setIsOpen(false), 0)
                            }
                            to={siteMap.account.information}>
                            {accountSettingsLabel}
                        </Anchor>
                    )}
                    {showWhatsNew && (
                        <Button
                            onClick={() => handleOpenAnnouncementModal(true)}>
                            {announcementLabel}
                            {unreadAnnouncement && <NotificationDotIcon />}
                        </Button>
                    )}
                    {globalState.currentIdentity != null && (
                        <Button onClick={() => setShowAboutModal(true)}>
                            {aboutApp}
                        </Button>
                    )}
                    <LogOutButton />
                </AccessibleList>
                {showAboutModal && (
                    <AboutModal
                        isVisible={showAboutModal}
                        closeDialog={() => setShowAboutModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProfileMenuDetail;
