import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { siteMap } from "internal-sitemap";
import ProfileAvatar from "organisms/profile/profile-avatar";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";

const BASE_CLASS_NAME = "c-manage-team-shortcut";

const ManageTeamShortcut = () => {
    const { globalState } = useGlobalState();
    const fullName =
        globalState.currentIdentity?.user?.getFirstAndLastNameOrReturnDefault();
    const history = useHistory();

    const teamName =
        globalState.currentIdentity?.getCurrentTeam()?.name ??
        t("unassignedTeam");
    const handleManageTeamClick = () => {
        history.push(siteMap.account.team);
    };
    return (
        <div className={`${BASE_CLASS_NAME}`}>
            <div className={`${BASE_CLASS_NAME}__avatar`}>
                <ProfileAvatar />
            </div>
            <div className={`${BASE_CLASS_NAME}__info`}>
                <Heading
                    priority={HeadingPriority.Four}
                    cssClassName={`${BASE_CLASS_NAME}__fullName`}>
                    {fullName}
                </Heading>
                <Heading
                    priority={HeadingPriority.Five}
                    cssClassName={`${BASE_CLASS_NAME}__teamName`}>
                    {teamName}
                </Heading>
            </div>
            <Button
                onClick={handleManageTeamClick}
                cssClassName={`${BASE_CLASS_NAME}__button`}
                style={ButtonStyles.Tertiary}
                size={ButtonSizes.Small}>
                {t("manageTeam")}
            </Button>
        </div>
    );
};

export default ManageTeamShortcut;
