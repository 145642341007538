import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------
export interface GroupUserRoleGroupListPathParams {
    id: number;
}
// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "groups/:id/userrolegroups";
const resourceType = UserRoleGroupRecord;
const key = "groups-user-role-groups";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const GroupUserRoleGroupService = {
    key,
    list: ServiceFactory.nestedList<
        UserRoleGroupRecord,
        GroupUserRoleGroupListPathParams,
        null
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        UserRoleGroupRecord,
        GroupUserRoleGroupListPathParams,
        null
    >(resourceType, baseEndpoint),
    update: ServiceFactory.bulkUpdate<
        UserRoleGroupRecord,
        GroupUserRoleGroupListPathParams
    >(resourceType, baseEndpoint),
    useUpdate: ServiceHookFactory.useBulkUpdate<
        UserRoleGroupRecord,
        GroupUserRoleGroupListPathParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default GroupUserRoleGroupService;

// #region Exports
