import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from "react";
import GroupRecord from "models/view-models/group-record";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type GroupUpdater = Dispatch<SetStateAction<GroupRecord | undefined>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: GroupRecord = new GroupRecord();
const defaultUpdater: GroupUpdater = () => {};

export const GroupContext = createContext<
    [GroupRecord | undefined, GroupUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Provider
// -------------------------------------------------------------------------------------------------

const GroupContextProvider = (props: PropsWithChildren<{}>) => {
    const [group, setGroup] = useState<GroupRecord>();

    return (
        <GroupContext.Provider value={[group, setGroup]}>
            {props.children}
        </GroupContext.Provider>
    );
};

// #endregion Provider

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export const useGroupContext = () => {
    const [groupContext, setGroupContext] = useContext(GroupContext);

    return {
        groupContext,
        setGroupContext,
    };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default GroupContextProvider;

// #endregion Exports
