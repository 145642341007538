import React from "react";
import { AuthenticationProvider } from "utilities/contexts/authentication/authentication-provider";
import AuthenticatedApp from "authenticated-app";

// At this point the settings for the app have been loaded (featureFlags, etc.)
// Let's try to log them in
const ConfiguredApp = () => {
    return (
        <AuthenticationProvider>
            <AuthenticatedApp />
        </AuthenticationProvider>
    );
};

export default ConfiguredApp;
