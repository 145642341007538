import AuthService from "utilities/services/auth/auth-service";
import EmulationTokenRecord from "models/view-models/emulation-token-record";
import UserLoginRecord from "models/view-models/user-login-record";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import useLocalStorage from "utilities/hooks/use-local-storage";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import React, {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useGlobalState } from "../use-global-state-context";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { useHistory } from "react-router-dom";

interface EmulationModeContextState {
    token: EmulationTokenRecord | null;
    setToken: (value: EmulationTokenRecord | null) => void;
    userEmail: string;
    loadingEmulationSession: boolean;
    setLoadingEmulationSession: React.Dispatch<React.SetStateAction<boolean>>;
    endingEmulationSession: boolean;
    setEndingEmulationSession: React.Dispatch<React.SetStateAction<boolean>>;
    endEmulationMode: (guestId: number | undefined) => Promise<void>;
}

const defaultState: EmulationModeContextState = {
    token: null,
    setToken: () => {},
    userEmail: "",
    loadingEmulationSession: false,
    setLoadingEmulationSession: () => {},
    endingEmulationSession: false,
    setEndingEmulationSession: () => {},
    endEmulationMode: async () => {},
};

export const EmulationModeContext = createContext(defaultState);

const EmulationModeContextProvider = (props: PropsWithChildren<{}>) => {
    const { storedValue: token, setValue: setToken } =
        useLocalStorage<EmulationTokenRecord | null>("token", null);
    const timeoutId = useRef<number>();
    const { endEmulationSession } = useAuthentication();
    const { build } = useCurrentIdentity();

    const [loadingEmulationSession, setLoadingEmulationSession] =
        useState(false);
    const [endingEmulationSession, setEndingEmulationSession] = useState(false);

    const { globalState } = useGlobalState();
    const userEmail = globalState.currentIdentity?.user?.email ?? "";

    const history = useHistory();

    const emulationTransitionText = useMemo(() => {
        return loadingEmulationSession
            ? "Loading emulation session"
            : endingEmulationSession
              ? "Ending emulation session"
              : "";
    }, [endingEmulationSession, loadingEmulationSession]);

    const countDownToExpiration = (expiresOn: string | undefined) => {
        if (expiresOn !== undefined) {
            const expiresOnTime = Date.parse(expiresOn);
            const countdownMilliseconds = expiresOnTime - Date.now();
            return countdownMilliseconds;
        }
    };

    const endEmulationMode = useCallback(
        async (guestId: number | undefined) => {
            if (endingEmulationSession) {
                return;
            }
            setEndingEmulationSession(true);
            if (await endEmulationSession()) {
                const userLogin = await AuthService.create();
                const userLoginRecord = new UserLoginRecord(
                    userLogin.resultObject
                );
                await build(userLoginRecord);

                setEndingEmulationSession(false);

                history.push(
                    RouteUtils.getUrl(siteMap.admin.support.userDetail, {
                        id: guestId,
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [build, history, endEmulationSession, setEndingEmulationSession]
    );

    useEffect(() => {
        if (token?.id != null) {
            clearTimeout(timeoutId.current);
            const id = setTimeout(async () => {
                await endEmulationMode(token?.guestId);
            }, countDownToExpiration(token?.expiresOn));
            timeoutId.current = id;
        }
        return () => {
            clearTimeout(timeoutId.current);
        };
    }, [endEmulationMode, token?.expiresOn, token?.guestId, token?.id]);

    const value: EmulationModeContextState = {
        token,
        setToken,
        userEmail,
        loadingEmulationSession,
        setLoadingEmulationSession,
        endingEmulationSession,
        setEndingEmulationSession,
        endEmulationMode,
    };
    return (
        <EmulationModeContext.Provider value={value}>
            {loadingEmulationSession || endingEmulationSession ? (
                <FullScreenTransition
                    transitionText={emulationTransitionText}
                />
            ) : (
                props.children
            )}
        </EmulationModeContext.Provider>
    );
};

export default EmulationModeContextProvider;
