import Loader from "atoms/loaders/loader";
import { siteMap } from "internal-sitemap";
import SupportUserRecord from "models/view-models/support-user-record";
import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { useHistory } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { RouteUtils } from "utilities/route-utils";

export interface UserSupportTableProps {
    loading: boolean;
    users: Array<SupportUserRecord>;
}

const CSS_BASE_CLASS = "c-support-admin-dashboard-table";

const UserSupportTable: React.FC<any> = (props: UserSupportTableProps) => {
    const { loading, users } = props;
    const { useNetsuiteIdForUserLookup } = useFeatureFlags();

    const history = useHistory();

    if (loading) {
        return <Loader accessibleText={"Loading users..."} />;
    }

    const handleClick = (path: string) => history.push(path);

    return (
        <DataTable cssClassName={CSS_BASE_CLASS}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    {useNetsuiteIdForUserLookup ? (
                        <>
                            <th>Legacy ID</th>
                            <th>Netsuite ID</th>
                        </>
                    ) : (
                        <th>NFPA ID</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {!loading && CollectionUtils.isNotEmpty(users) ? (
                    users.map((user: SupportUserRecord) => {
                        const path = RouteUtils.getUrl(
                            siteMap.admin.support.userDetail,
                            { id: user.id }
                        );

                        return (
                            <tr
                                className={`${CSS_BASE_CLASS}__row`}
                                key={user.id}
                                onClick={() => handleClick(path)}>
                                <td>{user.id}</td>
                                <td>{user.fullName}</td>
                                <td>{user.email}</td>
                                {useNetsuiteIdForUserLookup ? (
                                    <>
                                        <td>{user.legacyId}</td>
                                        <td>{user.netsuiteId}</td>
                                    </>
                                ) : (
                                    <td>{user.legacyId}</td>
                                )}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={4} className="-no-results-row">
                            No results found - please try adjusting your search.
                        </td>
                    </tr>
                )}
            </tbody>
        </DataTable>
    );
};

export default UserSupportTable;
