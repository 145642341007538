import B2C_CONFIG from "pages/azure-login/b2c-config";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { AzureAuthenticationStrategy } from "./azure-authentication-strategy";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";
import { AzureAuthenticationWrapperComponent } from "utilities/services/auth/azure-strategies/azure-authentication/azure-authentication-wrapper-component";
import React from "react";
import { Configuration, IPublicClientApplication } from "@azure/msal-browser";
import { MsalFactory } from "utilities/services/auth/msal-factory";
import { AuthenticationStrategyResult } from "utilities/services/auth/authentication-strategy-result";
import { AuthenticationStrategyFactoryHookResult } from "utilities/services/auth/authentication-strategy-factory-hook-result";

const config: Configuration = {
    auth: {
        clientId: B2C_CONFIG.CLIENT_ID,
        redirectUri: B2C_CONFIG.REDIRECT_URI,
        authority: `https://${B2C_CONFIG.DOMAIN}/${B2C_CONFIG.TENANT}.onmicrosoft.com/${B2C_CONFIG.POLICY}`,
        knownAuthorities: [B2C_CONFIG.DOMAIN],
        navigateToLoginRequestUrl: true,
    },
};

export function useAzureAuthenticationStrategy(): AuthenticationStrategyFactoryHookResult {
    const { globalState } = useGlobalState();
    const currentCulture = globalState.getPreferredOrCurrentCulture();
    const isAuthenticated = useMemo(
        () => globalState.isAuthenticated(),
        [globalState]
    );
    const { showSSODebugLogs, useSsoSilent } = useFeatureFlags();
    const { callbackUrl } = useUrlCallback();

    const authStrategyFactory =
        async (): Promise<AuthenticationStrategyResult> => {
            const msalFactory = new MsalFactory();
            const instance = await msalFactory.getOrCreateMsalInstance(config);
            const azureAuthenticationStrategy = new AzureAuthenticationStrategy(
                isAuthenticated,
                useSsoSilent,
                currentCulture,
                callbackUrl ?? "",
                instance,
                config,
                globalState.currentIdentity?.userLogin,
                showSSODebugLogs
            );

            const authStrategyResult: AuthenticationStrategyResult = {
                authStrategy: azureAuthenticationStrategy,
                getAuthWrapperComponent: () => getWrapperComponent(instance),
            };
            return authStrategyResult;
        };

    const create = useCallback(authStrategyFactory, [
        callbackUrl,
        currentCulture,
        globalState.currentIdentity?.userLogin,
        isAuthenticated,
        showSSODebugLogs,
        useSsoSilent,
    ]);

    const getWrapperComponent = (instance: IPublicClientApplication) => {
        const WrapperComponent: React.FC<PropsWithChildren> = ({
            children,
        }) => {
            return (
                <AzureAuthenticationWrapperComponent instance={instance}>
                    {children}
                </AzureAuthenticationWrapperComponent>
            );
        };
        return WrapperComponent;
    };

    return { create };
}
