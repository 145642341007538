import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import NaturalLanguageSearch from "organisms/modals/natural-language-search-modal/interfaces/natural-language-search";

const defaultValues: NaturalLanguageSearch =
    RecordUtils.defaultValuesFactory<NaturalLanguageSearch>({
        id: "",
        search: "",
        results: [],
        skip: 0,
        take: 25,
        totalCount: 0,
    });

export default class NaturalLanguageSearchRecord
    extends Record(defaultValues)
    implements NaturalLanguageSearch
{
    constructor(params?: NaturalLanguageSearch) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
}
