import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface OriginsSectionConverterProps
    extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OriginsSectionConverter: React.FC<OriginsSectionConverterProps> = (
    props: OriginsSectionConverterProps
) => {
    const { bookviewLayoutContext } = useBookviewLayoutContext();
    const isContentExpanded = bookviewLayoutContext.bookviewContentExpanded;

    const CSS_BASE_CLASS = "c-origins-section";

    // There are times where props.children is undefined
    if (props.children == null) {
        return null;
    }

    return (
        <div
            className={`${CSS_BASE_CLASS} ${isContentExpanded ? "-expanded" : ""}`}>
            {props.children}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default OriginsSectionConverter;

// #endregion Exports
