import ServiceHookFactory from "utilities/services/service-hook-factory";
import NaturalLanguageSearchRecord from "organisms/modals/natural-language-search-modal/view-models/natural-language-search-record";

export interface NaturalLanguageSearchParams {
    id: string;
}

const baseEndpoint = "nl-search-sessions/:id/searches";
const resourceType = NaturalLanguageSearchRecord;

export const NaturalLanguageSearchSessionService = {
    useCreate: ServiceHookFactory.useNestedCreate<
        NaturalLanguageSearchRecord,
        NaturalLanguageSearchParams
    >(resourceType, baseEndpoint),
};
