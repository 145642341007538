import Loader from "atoms/loaders/loader";
import PublicationTypes from "constants/publication-types";
import { ChapterParams } from "interfaces/routing/route-params";
import CurrentPublicationEntityRecord from "models/view-models/atoms/current-publication-entity-record";
import ChapterTableOfContentsRecord from "models/view-models/table-of-contents/chapter-table-of-contents-record";
import LinkResources from "molecules/linkResources/link-resources";
import useLinkResourcesChapterProvider from "molecules/linkResources/link-resources-chapter-provider";
import UnorderedList from "molecules/lists/unordered-list";
import HiddenContentAlertBanner from "organisms/alerts/hidden-content-alert-banner";
import CaapsItemTitleArea from "organisms/caaps-items/caaps-item-title-area";
import PublicationContentArea from "organisms/publication-content/publication-content-area";
import TableOfContentsArticle from "organisms/table-of-contents/table-of-contents-article";
import TableOfContentsSection from "organisms/table-of-contents/table-of-contents-section";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import { CollectionUtils } from "utilities/collection-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useUpdateAtomEffect from "utilities/hooks/atoms/use-update-atom-value-effect";
import useArticles from "utilities/hooks/domain/publications/chapters/articles/use-articles";
import useSections from "utilities/hooks/domain/publications/chapters/sections/use-sections";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useDebounce from "utilities/hooks/use-debounce";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ChapterContentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-page-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ChapterContent: React.FunctionComponent<ChapterContentProps> = (
    props: ChapterContentProps
) => {
    const { publication } = useCurrentPublication();
    const { id: chapterId, nfpaLabel } = useParams<ChapterParams>();
    const { t } = useLocalization();
    const [shouldLoadSections, setShouldLoadSections] =
        useState<boolean>(false);
    const loaderText = t("loadingItem", {
        item: t("chapter"),
    });

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        resultObject: chapter,
        loading: chapterLoading,
        loaded: chapterLoaded,
        errors: chapterErrors,
    } = useChapter({
        chapterId: chapterId,
        nfpaLabel: nfpaLabel,
        publicationId: publication?.id,
    });

    const {
        resultObject: articles,
        loading: articlesLoading,
        loaded: articlesLoaded,
        errors: articleErrors,
    } = useArticles(publication?.id, chapter.id);

    const {
        resultObject: sections,
        loading: sectionsLoading,
        errors: sectionErrors,
    } = useSections(
        shouldLoadSections ? publication?.id : undefined,
        shouldLoadSections ? chapter.id : undefined
    );

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    const loading = useLoading(
        articlesLoading,
        chapterLoading,
        sectionsLoading
    );
    const loadingDebounced = useDebounce(loading, 50);

    useEffect(() => {
        if (!articlesLoaded) {
            return;
        }

        // If articles have been loaded but the array is still empty, it might mean this Chapter only
        // contains sections. Load them now.
        if (CollectionUtils.isEmpty(articles)) {
            setShouldLoadSections(true);
        }
    }, [articles, articlesLoaded]);

    const errors = useErrors(chapterErrors, sectionErrors, articleErrors);
    useErrorBoundary(errors);

    useUpdateAtomEffect({
        atom: CurrentPublicationEntityAtom,
        enabled: chapter != null && chapterLoaded,
        value: new CurrentPublicationEntityRecord({
            entityId: chapter.id,
            entityTitle: chapter.getDisplayTitle(),
            selected: true,
            type: "Chapter",
        }),
    });

    const { chapters } = useBookviewTableOfContents().tableOfContents;
    const chapterTOC =
        chapters?.find((c) => c.id === chapter.id) ??
        new ChapterTableOfContentsRecord();
    const { articles: articlesToC, sections: sectionsToC } = chapterTOC;

    const useSectionTableOfContents =
        CollectionUtils.isEmpty(articlesToC) &&
        CollectionUtils.hasValues(sectionsToC) &&
        publication.type !== PublicationTypes.NFC;

    const { isAdminPreview } = useAdminPreview();

    const LinkResourcesProvider = useLinkResourcesChapterProvider();

    const linkResources = useMemo(
        () =>
            isAdminPreview
                ? LinkResourcesProvider.getChapterPreviewLinkResources(
                      chapter.id,
                      `${chapter.number}`,
                      publication?.code ?? "",
                      publication?.id ?? 0,
                      chapter?.hasTables ?? false
                  )
                : LinkResourcesProvider.getChapterLinkResources(
                      `${chapter.number}`,
                      publication?.code ?? "",
                      publication?.edition ?? "",
                      chapter.nfpaLabel ?? "",
                      chapter?.hasTables ?? false
                  ),
        [
            LinkResourcesProvider,
            chapter?.hasTables,
            chapter.id,
            chapter.nfpaLabel,
            chapter.number,
            isAdminPreview,
            publication?.code,
            publication?.edition,
            publication?.id,
        ]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const nfcChildren = useMemo(
        () => (
            <CaapsItemTitleArea
                titleClassName={""}
                record={chapter}
                priorLocation={chapter.priorLocation}
                publication={publication}
                changes={chapter.changes}
                doesHaveTitleOrBodyChanges={chapter.doesTitleOrBodyHaveChanges()}
                externalId={chapter.externalId}
                publicationComponentType={PublicationComponentType.Chapter}
            />
        ),
        [chapter, publication]
    );

    const necChildren = useMemo(
        () => (
            <CaapsItemTitleArea
                titleClassName={""}
                record={chapter}
                publication={publication}
                priorLocation={chapter.priorLocation}
                changes={chapter.changes}
                doesHaveTitleOrBodyChanges={chapter.doesTitleOrBodyHaveChanges()}
                externalId={chapter.externalId}
                publicationComponentType={PublicationComponentType.Chapter}>
                <nav className={`${BASE_CLASS_NAME}__table-of-contents`}>
                    {CollectionUtils.hasValues(articlesToC) && (
                        <>
                            <TableOfContentsArticle
                                articles={articlesToC}
                                chapterNfpaLabel={chapter.nfpaLabel ?? ""}
                            />
                            {CollectionUtils.hasValues(linkResources) && (
                                <LinkResources
                                    linkResourceRecords={linkResources}
                                />
                            )}
                        </>
                    )}
                    {useSectionTableOfContents && (
                        <TableOfContentsSection
                            sections={sectionsToC!}
                            useHashLinks={false}
                            chapterNfpaLabel={chapter.nfpaLabel}
                            parentType={SectionParentType.Chapter}
                        />
                    )}
                </nav>
            </CaapsItemTitleArea>
        ),
        [
            articlesToC,
            chapter,
            linkResources,
            publication,
            sectionsToC,
            useSectionTableOfContents,
        ]
    );

    if (publication == null) {
        // Failed to load publication from context
        return null;
    }

    return (
        <React.Fragment>
            {
                // if
                loadingDebounced && <Loader accessibleText={loaderText} />
            }
            {
                // if
                CollectionUtils.hasValues(errors) && (
                    <UnorderedList
                        listItems={errors}
                        cssClassName={`${BASE_CLASS_NAME}__errors`}
                    />
                )
            }
            {
                // if
                !loadingDebounced && (
                    <>
                        <HiddenContentAlertBanner
                            id={chapter.id}
                            type={PublicationComponentType.Chapter}
                        />
                        <PublicationContentArea
                            memoizedChildren={
                                publication.type === PublicationTypes.NFC
                                    ? nfcChildren
                                    : necChildren
                            }
                            sections={
                                publication.type === PublicationTypes.NFC
                                    ? sections
                                    : []
                            }
                            externalId={chapter.externalId}
                        />
                    </>
                )
            }
        </React.Fragment>
    );

    // #endregion Render
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ChapterContent;

// #endregion Exports
