import Button, { ButtonTypes } from "atoms/buttons/button";
import Heading from "atoms/typography/heading";
import Icon from "atoms/icons/icon";
import React from "react";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { StringUtils } from "@rsm-hcd/javascript-core";
import { useLocalization } from "utilities/hooks/use-localization";

interface ChangeIndicatorDiffPanelHeaderProps {
    changes: string;
}

const BASE_CLASSNAME = "c-change-indicator-diff-panel";

const ChangeIndicatorDiffPanelHeader = (
    props: ChangeIndicatorDiffPanelHeaderProps
) => {
    const { handleClose } = useChangeIndicatorDiffPanel();

    const { t } = useLocalization();

    const { publication } = useCurrentPublication();

    return (
        <div className={`${BASE_CLASSNAME}__header`}>
            <Heading
                cssClassName={`${BASE_CLASSNAME}__heading`}
                priority={HeadingPriority.Two}>
                {StringUtils.hasValue(publication.jurisdiction)
                    ? `${t("nfpaVersion")}`
                    : t("priorText")}
            </Heading>
            <Button
                accessibleText={t("closeItem", { item: t("viewChanges") })}
                ariaControls={"diff-panel"}
                cssClassName={`-modal-close -icon ${BASE_CLASSNAME}__closeIcon`}
                onClick={handleClose}
                size={ButtonSizes.Medium}
                style={ButtonStyles.None}
                type={ButtonTypes.Button}>
                <Icon
                    cssClassName={`${BASE_CLASSNAME}__closeIcon`}
                    type={Icons.Close}
                    size={IconSizes.Medium}
                />
            </Button>
        </div>
    );
};

export default ChangeIndicatorDiffPanelHeader;
