import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import Button from "atoms/buttons/button";
import ContactUsMessage from "organisms/resources/contact-us-message";
import React, { useEffect, useState } from "react";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { CollectionUtils, StringUtils } from "@rsm-hcd/javascript-core";
import { siteMap } from "internal-sitemap";
import { t } from "utilities/localization-utils";
import { useGroupContext } from "utilities/contexts/group/group-context-provider";
import { useGroupInvite } from "utilities/hooks/domain/groups/use-group-invite";
import { useHistory } from "react-router-dom";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import GroupUserRoleGroupService from "utilities/services/groups/group-user-role-group-service";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import useGroupUserRoleGroups from "utilities/hooks/domain/groups/use-groups-user-role-groups";
import { ToastManager } from "utilities/toast/toast-manager";
import GroupService from "utilities/services/groups/group-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ManageTeamSettingsProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-manage-team-settings";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ManageTeamSettings: React.FC<ManageTeamSettingsProps> = () => {
    const history = useHistory();
    const { groupContext } = useGroupContext();
    const [inviteEmail, setInviteEmail] = useState<string>("");
    const [pendingInvites, setPendingInvites] = useState<string[]>([]);
    const [removedPendingInvites, setRemovedPendingInvites] = useState<
        string[]
    >([]);
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [notEnoughSeatsError, setNotEnoughSeatsError] = useState("");
    const { isOnline } = useNetworkInformation();
    const totalLicenses = groupContext?.totalLicenses;
    const { refresh } = useRefreshIdentity();
    const { update: updateGroup } = GroupService.useUpdate();
    const { update: updateUserRoleGroupsApi } =
        GroupUserRoleGroupService.useUpdate();
    const [existingUserRoleGroups, setExistingUserRoleGroups] = useState<
        UserRoleGroupRecord[]
    >([]);
    const [removedExistingUserRoleGroups, setRemovedExistingUserRoleGroups] =
        useState<UserRoleGroupRecord[]>([]);

    const { sendGroupInvite } = useGroupInvite({
        groupId: groupContext?.id!,
        emails: pendingInvites,
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setInviteEmail(e.currentTarget.value);

    const handleAddClick = () => {
        const emailsToAdd = inviteEmail.split(",").map((e) => e.trim());
        addPendingInvites(emailsToAdd);
    };

    const handleUndoDelete = (email: string) => {
        const emailsToAdd = [email];
        addPendingInvites(emailsToAdd);
    };

    const { resultObject: userRoleGroups } = useGroupUserRoleGroups({
        groupId: groupContext?.id ?? 0,
        includeUserRoleAndUser: true,
    });

    const updateUserRoleGroups = async (groupId: number) => {
        try {
            await updateUserRoleGroupsApi(existingUserRoleGroups, {
                id: groupId,
            });
        } catch (e) {
            ToastManager.error(t("manageTeamsErrorUpdatingMembers"));
        }
    };

    const handleContinueClick = async () => {
        try {
            // update team name
            await updateGroup(groupContext!, { id: groupContext?.id! });

            // update group members
            if (groupContext?.id) {
                await updateUserRoleGroups(groupContext?.id);
            }

            // send invites
            await sendGroupInvite();

            await refresh(true);

            history.push(siteMap.teamOnboarding.customize);
        } catch (e) {
            ToastManager.error(t("manageTeamsErrorUpdatingTeam"));
        }
    };

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        if (userRoleGroups.length > 0) {
            setExistingUserRoleGroups(userRoleGroups);
        }
    }, [userRoleGroups]);
    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    const INVITATION_INPUT_HELPER_TEXT = t(
        "groupInvite-invitationInputHelperText"
    );

    const addPendingInvites = (emailsToAdd: Array<string>) => {
        if (
            pendingInvites.length +
                emailsToAdd.length +
                existingUserRoleGroups.length >
            totalLicenses!
        ) {
            setNotEnoughSeatsError(t("notEnoughSeatsForCurrentSubscription"));
            return;
        }
        setNotEnoughSeatsError("");
        const filteredEmails = removeDuplicateEmails(emailsToAdd);
        const emailsAreValid = validateEmails(filteredEmails);

        if (!emailsAreValid) {
            return;
        }
        setInviteEmail("");
        setPendingInvites([...pendingInvites, ...filteredEmails]);
    };

    // If the email is already in the pending invites, don't add it again.
    const removeDuplicateEmails = (emails: Array<string>): Array<string> => {
        const filteredInviteEmails =
            removeDuplicateEmailFromInviteEmail(emails);

        const filteredInvites = filteredInviteEmails.filter(
            (email) => !pendingInvites.includes(email)
        );

        return filteredInvites;
    };

    const removeDuplicateEmailFromInviteEmail = (
        emails: Array<string>
    ): Array<string> => {
        return emails.filter(
            (email, id) =>
                StringUtils.hasValue(email) && emails.indexOf(email) === id
        );
    };

    const validateEmails = (emails: Array<string>): boolean => {
        if (CollectionUtils.isEmpty(emails)) {
            setEmailError(t("email-required-field"));
            return false;
        }

        if (emails.length === 1 && !StringUtils.isValidEmail(emails[0])) {
            setEmailError(t("invalid-email-field"));
            return false;
        }

        const invalidEmails: string[] = [];
        emails.forEach((e) => {
            const isValid = StringUtils.isValidEmail(e);
            if (!isValid) {
                invalidEmails.push(e);
            }
        });

        if (CollectionUtils.hasValues(invalidEmails)) {
            setEmailError(
                t("invalid-emails-field", { emails: invalidEmails.join() })
            );
            return false;
        }

        setEmailError(undefined);
        return true;
    };

    const removePendingEmail = (email: string) => {
        setRemovedPendingInvites([...removedPendingInvites, email]);
        setPendingInvites(pendingInvites.filter((e) => e !== email));
    };

    const restorePendingEmail = (email: string) => {
        if (pendingInvites.length + 1 > totalLicenses!) {
            setNotEnoughSeatsError(t("notEnoughSeatsForCurrentSubscription"));
            return;
        }
        handleUndoDelete(email);
        setRemovedPendingInvites(
            removedPendingInvites.filter((e) => e !== email)
        );
    };

    const removeExistingUserRoleGroup = (
        userRoleGroup: UserRoleGroupRecord
    ) => {
        setRemovedExistingUserRoleGroups([
            ...removedExistingUserRoleGroups,
            userRoleGroup,
        ]);
        setExistingUserRoleGroups(
            existingUserRoleGroups.filter((urg) => urg !== userRoleGroup)
        );
    };

    const restoreExistingUserRoleGroup = (
        userRoleGroup: UserRoleGroupRecord
    ) => {
        if (
            pendingInvites.length + existingUserRoleGroups.length + 1 >
            totalLicenses!
        ) {
            setNotEnoughSeatsError(t("notEnoughSeatsForCurrentSubscription"));
            return;
        }
        setRemovedExistingUserRoleGroups(
            removedExistingUserRoleGroups.filter((urg) => urg !== userRoleGroup)
        );
    };

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={CLASS_NAME}>
            {StringUtils.hasValue(notEnoughSeatsError) && (
                <div className={`${CLASS_NAME}__not-enough-seats-error`}>
                    <AlertBanner alertLevel={AlertLevels.Error}>
                        {t("notEnoughSeatsForCurrentSubscription")}
                    </AlertBanner>
                </div>
            )}
            <div className={`${CLASS_NAME}__card`}>
                <div className={`${CLASS_NAME}__content`}>
                    <div
                        className={`${CLASS_NAME}__content__email${
                            StringUtils.hasValue(emailError) ? " -invalid" : ""
                        }`}>
                        <TextAreaFormField
                            disabled={!isOnline}
                            errorMessage={emailError}
                            isValid={StringUtils.isEmpty(emailError)}
                            label={""}
                            placeholder={t("teamInvitePlaceholder")}
                            onChange={handleOnChange}
                            helperText={INVITATION_INPUT_HELPER_TEXT}
                            rows={1}
                            value={inviteEmail}
                        />
                        <Button
                            disabled={!isOnline}
                            onClick={() => handleAddClick()}>
                            {t("add")}
                        </Button>
                    </div>
                </div>
                <div className={`${CLASS_NAME}__pending`}>
                    <div className={`${CLASS_NAME}__pending__title`}>
                        {pendingInvites.length + existingUserRoleGroups.length}{" "}
                        {t("workgroup-members")}
                    </div>
                    <div className={`${CLASS_NAME}__pending__content`}>
                        {pendingInvites?.map((email, idx) => (
                            <div
                                className={`${CLASS_NAME}__pending__content__row`}
                                key={idx}>
                                <span
                                    className={`${CLASS_NAME}__pending__content__row__email`}>
                                    {email}
                                </span>
                                <div
                                    className={`${CLASS_NAME}__pending__content__row__actions`}>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions__buttons`}>
                                        <Button
                                            disabled={!isOnline}
                                            onClick={() =>
                                                removePendingEmail(email)
                                            }
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.Destructive}>
                                            {t("cancelInvite")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {existingUserRoleGroups?.map(
                            (userRoleGroup: UserRoleGroupRecord, idx) => (
                                <div
                                    className={`${CLASS_NAME}__pending__content__row`}
                                    key={idx}>
                                    <span
                                        className={`${CLASS_NAME}__pending__content__row__email`}>
                                        {userRoleGroup.userRole?.user?.email}
                                    </span>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions`}>
                                        <div
                                            className={`${CLASS_NAME}__pending__content__row__actions__buttons`}>
                                            <Button
                                                disabled={!isOnline}
                                                onClick={() =>
                                                    removeExistingUserRoleGroup(
                                                        userRoleGroup
                                                    )
                                                }
                                                size={ButtonSizes.Small}
                                                style={
                                                    ButtonStyles.Destructive
                                                }>
                                                {t("manageTeamsRemoveMember")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        {removedPendingInvites.map((email, idx) => (
                            <div
                                className={`${CLASS_NAME}__pending__content__row`}
                                key={idx}>
                                <span
                                    className={`${CLASS_NAME}__removed-content-row-email`}>
                                    {email}
                                </span>
                                <div
                                    className={`${CLASS_NAME}__pending__content__row__actions`}>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions__buttons`}>
                                        <Button
                                            disabled={!isOnline}
                                            onClick={() =>
                                                restorePendingEmail(email)
                                            }
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.Destructive}>
                                            {t("undoDelete")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {removedExistingUserRoleGroups.map(
                            (userRoleGroup: UserRoleGroupRecord, idx) => (
                                <div
                                    className={`${CLASS_NAME}__pending__content__row`}
                                    key={idx}>
                                    <span
                                        className={`${CLASS_NAME}__removed-content-row-email`}>
                                        {userRoleGroup.userRole?.user?.email}
                                    </span>
                                    <div
                                        className={`${CLASS_NAME}__pending__content__row__actions`}>
                                        <div
                                            className={`${CLASS_NAME}__pending__content__row__actions__buttons`}>
                                            <Button
                                                disabled={!isOnline}
                                                onClick={() =>
                                                    restoreExistingUserRoleGroup(
                                                        userRoleGroup
                                                    )
                                                }
                                                size={ButtonSizes.Small}
                                                style={
                                                    ButtonStyles.Destructive
                                                }>
                                                {t("undoDelete")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className={`${CLASS_NAME}__pending__actions`}>
                <Button
                    disabled={false}
                    style={ButtonStyles.Tertiary}
                    onClick={() => history.push(siteMap.teamOnboarding.verify)}>
                    {t("back")}
                </Button>

                <span className={`${CLASS_NAME}__pending__forward`}>
                    <Button
                        style={ButtonStyles.Secondary}
                        onClick={() =>
                            history.push(siteMap.teamOnboarding.customize)
                        }>
                        {t("skip")}
                    </Button>

                    <Button disabled={false} onClick={handleContinueClick}>
                        {t("continue")}
                    </Button>
                </span>
            </div>
            <div className={`${CLASS_NAME}__contact-us-message`}>
                <ContactUsMessage />
            </div>
        </div>
    );
    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ManageTeamSettings;

// #endregion Exports
