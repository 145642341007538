import GlobalStateRecord from "models/view-models/global-state-record";
import useFeatureFlags from "./use-feature-flags";
import useIdentity from "utilities/hooks/use-identity";
import { atom, useAtom } from "jotai";
import { useEffect, useCallback } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

/**
 * Custom hook to refresh the identity in globalState, with callback to trigger additional refreshes
 * on command.
 */
const loadingAtom = atom(false);
const loadedAtom = atom(false);

export default function useRefreshIdentity() {
    const { globalState, setGlobalState } = useGlobalState();
    const { buildCurrentIdentity } = useIdentity();
    const { showSSODebugLogs } = useFeatureFlags();

    const [loading, setLoading] = useAtom(loadingAtom);
    const [loaded, setLoaded] = useAtom(loadedAtom);

    const updateIdentity = useCallback(
        async (forceRefresh = false) => {
            if ((loaded || loading) && !forceRefresh) {
                return;
            }

            try {
                setLoading(true);

                if (globalState.currentIdentity?.userLogin === undefined) {
                    return;
                }

                if (showSSODebugLogs) {
                    console.log(
                        "***buildCurrentIdentity called in use-refresh-identity.ts"
                    );
                }

                const identity = await buildCurrentIdentity(
                    globalState.currentIdentity?.userLogin
                );

                if (showSSODebugLogs) {
                    console.log(
                        "***buildCurrentIdentity Completed in use-refresh-identity.ts"
                    );
                }

                if (identity == null) {
                    return;
                }

                setGlobalState((globalState: GlobalStateRecord) =>
                    globalState.setIdentity(identity)
                );
            } catch (error) {
                console.error("use-refresh-identity error: ", error);
            } finally {
                setLoaded(true);
                setLoading(false);
            }
        },
        [
            buildCurrentIdentity,
            globalState.currentIdentity?.userLogin,
            loaded,
            loading,
            setGlobalState,
            setLoaded,
            setLoading,
            showSSODebugLogs,
        ]
    );

    useEffect(() => {
        if (showSSODebugLogs) {
            console.log("Calling updateIdentity");
        }

        updateIdentity();
    }, [showSSODebugLogs, updateIdentity]);

    return { loading, refresh: updateIdentity, setLoading };
}
