import React from "react";
import StepperStep from "./stepper-step";

interface StepperProps {
    steps: string[];
    currentStep: number;
}

const BASE_CLASS = "c-stepper";

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
    return (
        <div className={BASE_CLASS}>
            {steps.map((step, index) => (
                <StepperStep
                    key={index}
                    step={step}
                    number={index + 1}
                    isActive={currentStep === index}
                />
            ))}
        </div>
    );
};

export default Stepper;
