import LandingPageFAQsSection from "organisms/landing-page-sections/landing-page-faqs-section";
import LandingPageHero from "organisms/landing-page-sections/landing-page-hero";
import LandingPagePlansSection from "organisms/landing-page-sections/landing-page-plans-section";
import LandingPageSpotlightsSection from "organisms/landing-page-sections/landing-page-spotlights-section";
import OnboardingLayoutV2 from "templates/onboarding-layout-v2";
import React, { useEffect } from "react";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import {
    AuthenticationLifeCycleStatus,
    AuthenticationStrategyType,
} from "utilities/enumerations/authorization";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

const LandingPage = () => {
    const { globalState, setGlobalState, isAuthenticated } = useGlobalState();
    const { authenticationLifeCycleStatus } = useAuthentication();
    const { subscriptionFreezeActive } = useConfigurableAlertMessages();
    const { showSSODebugLogs } = useFeatureFlags();

    useEffect(() => {
        // Set the authentication strategy to Azure B2C if the user is not authenticated
        // and we haven't yet set it to Azure B2C.
        if (
            !isAuthenticated &&
            globalState.authStrategyType !== AuthenticationStrategyType.AzureB2C
        ) {
            setGlobalState((prev) =>
                prev.setAuthenticationStrategyType(
                    AuthenticationStrategyType.AzureB2C
                )
            );
        }
    }, [globalState.authStrategyType, isAuthenticated, setGlobalState]);

    if (
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.LoggedIn &&
        globalState.isAuthenticated()
    ) {
        if (showSSODebugLogs) {
            console.log("LandingPage: redirecting to DASHBOARD.");
        }
        return <Redirect to={siteMap.dashboards.user} />;
    }

    return (
        <OnboardingLayoutV2>
            <LandingPageHero />
            {!subscriptionFreezeActive && <LandingPagePlansSection />}
            <LandingPageSpotlightsSection />
            <LandingPageFAQsSection />
        </OnboardingLayoutV2>
    );
};

export default LandingPage;
