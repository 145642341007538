import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import GroupRecord from "models/view-models/group-record";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "groups/eligible";
const resourceType = GroupRecord;
const key = "groups-eligible";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const GroupEligibleGroupService = {
    key,
    /**
     * Retrieves a list of Group resources that are eligible to be applied to a new UserSubscription
     */
    list: ServiceFactory.list(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList(resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default GroupEligibleGroupService;

// #region Exports
