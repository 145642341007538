import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useCurrentRouteDefinition from "utilities/hooks/routing/use-current-route-definition";
import { RouteUtils } from "utilities/route-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserConfigurationRedirectsProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserConfigurationRedirects: React.FunctionComponent<
    UserConfigurationRedirectsProps
> = (props: UserConfigurationRedirectsProps) => {
    const { globalState } = useGlobalState();
    const { unauthorizedResult } = globalState;
    const currentRoute = useCurrentRouteDefinition();
    const location = useLocation();
    const isFreeAccess = location.pathname.includes("free-access");
    const isInviteOnDemandRoute = location.pathname.includes("on-demand");
    const isAcceptInvitationRoute = location.pathname.includes("invitation");
    const isRegisterRoute = location.pathname.includes(siteMap.signup.register);
    const isPublicPublicationsRoute = location.pathname.includes(
        siteMap.publications.public.all
    );
    const teamName = globalState.currentIdentity?.getCurrentTeam()?.name;
    const isTeamOwner =
        globalState.currentIdentity?.getCurrentUserRole()?.userRoleGroup
            ?.isOwner;

    const { internalizeTeams } = useFeatureFlags();

    const isInInternalTeamsOnboarding =
        (RouteUtils.assertCurrentUrl(siteMap.teamOnboarding.verify) ||
            RouteUtils.assertCurrentUrl(siteMap.teamOnboarding.manage) ||
            RouteUtils.assertCurrentUrl(siteMap.teamOnboarding.customize)) &&
        internalizeTeams;

    // If the unauthorized result is not present, or we don't have any errors, there's no
    // specific redirection logic to follow here. Just return null.
    if (
        unauthorizedResult == null ||
        unauthorizedResult.doesNotHaveErrors() ||
        currentRoute?.skipUserConfigurationRedirects === true
    ) {
        return null;
    }

    // -----------------------------------------------------------------------------------------
    // Developer note: In all cases, we will check to make sure we are not currently sitting on
    // the page we want to redirect the user to. This causes unnecessary re-renders and is probably
    // contributing to some jittery redirection behavior, as well as potentially more API calls
    // being fired off to page data.
    // -----------------------------------------------------------------------------------------

    // Check to see if the user's current role has been expired or removed. We will redirect to the
    // login page, sign them out, and display an toast to let them know what's happening.
    const shouldRedirectToUserLoginPage =
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_SELECT_ANOTHER_ROLE
        ) && RouteUtils.assertIsNotCurrentUrl(siteMap.userlogins.new);

    // Check to see that the user has a valid subscription. If not, redirect them to
    // the plan selection page to check out through ecommerce and come back. The only exception is
    // if the user is attempting to login as a different account they shouldn't be prevented
    // from doing so
    const shouldRedirectToPlansPage =
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_CURRENT_ROLE_EXPIRED,
            UserConfigurationErrorKeys.ERROR_NO_SUBSCRIPTIONS
        ) &&
        RouteUtils.assertIsNotCurrentUrl(siteMap.signup.plans) &&
        RouteUtils.assertIsNotCurrentUrl(siteMap.userlogins.new) &&
        RouteUtils.assertIsNotCurrentUrl(siteMap.errors.notFound) &&
        !isRegisterRoute &&
        !isPublicPublicationsRoute &&
        !isFreeAccess &&
        !isInviteOnDemandRoute &&
        !isAcceptInvitationRoute;

    // Check to see that the user has accepted the Terms and Conditions and EULA.
    // If not, redirect them to the Terms and Conditions page to prevent them from continuing.
    const shouldRedirectToTermsPage =
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_EULA_NOT_ACCEPTED
        ) && RouteUtils.assertIsNotCurrentUrl(siteMap.termsAndConditions);

    // Check to see that the user has updated their topics of interest list or set a team name.
    // If not, redirect them to the topic selection page to update them before continuing.
    // TODO: Check that the user is any other user role than team management/owner roles (that would trigger shouldRedirectToTeamOnboarding)
    const shouldRedirectToTopicsPage =
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_TOPICS_NOT_UPDATED,
            UserConfigurationErrorKeys.ERROR_TEAM_NOT_INITIALIZED
        ) &&
        !isInInternalTeamsOnboarding &&
        (!isTeamOwner || (isTeamOwner && !internalizeTeams)) &&
        !isFreeAccess &&
        RouteUtils.assertIsNotCurrentUrl(siteMap.topics);

    // When InternalizeTeams feature flag is on, and the user has a new uninitialized team subscription they should be redirected to the internal teams onboarding flow
    const shouldRedirectToTeamOnboarding =
        internalizeTeams &&
        (unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_TEAM_NOT_INITIALIZED
        ) ||
            unauthorizedResult.hasErrorFor(
                UserConfigurationErrorKeys.ERROR_ELIGIBLE_TEAMS_FOUND_FOR_NEW_SUBSCRIPTION
            )) &&
        !isFreeAccess &&
        !isInInternalTeamsOnboarding &&
        !teamName &&
        isTeamOwner &&
        RouteUtils.assertIsNotCurrentUrl(siteMap.teamOnboarding.verify);

    if (shouldRedirectToUserLoginPage) {
        return (
            <Redirect
                to={{
                    pathname: siteMap.userlogins.new,
                    state: { from: location },
                }}
            />
        );
    }

    if (shouldRedirectToPlansPage) {
        return (
            <Redirect
                to={{
                    pathname: siteMap.signup.plans,
                    state: { from: location },
                }}
            />
        );
    }

    if (shouldRedirectToTermsPage) {
        return (
            <Redirect
                to={{
                    pathname: siteMap.termsAndConditions,
                    state: { from: location },
                }}
            />
        );
    }

    // Check to see that the user has updated their topics of interest list.
    // If not, redirect them to the topic selection page to update them before continuing.
    if (shouldRedirectToTopicsPage) {
        return (
            <Redirect
                to={{ pathname: siteMap.topics, state: { from: location } }}
            />
        );
    }

    if (shouldRedirectToTeamOnboarding) {
        return (
            <Redirect
                to={{
                    pathname: siteMap.teamOnboarding.verify,
                    state: { from: location },
                }}
            />
        );
    }

    // Otherwise, we don't have any specific redirection flow to follow. Just return null.
    return null;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default UserConfigurationRedirects;

// #endregion Export
