export enum NfpaUrlPathsV2 {
    CustomerSupport = "forms/customer-support-inquiry",
    ForgottenPassword = "forgottenpassword",
    LicenseAgreement = "Customer-Support/Products-Terms-of-Use#nfpa-link%C2%AE-",
    LiNK = "LiNK",
    MyProfile = "User/My-Account/Account-Information",
    PrivacyPolicy = "customer-support/privacy-policy",
    TermsOfUse = "Customer-Support/Products-Terms-of-Use#nfpa-link%C2%AE-",
    Login = "Login",
    RequestQuote = "forms/LiNK-Enterprise-Inquiries-Form",
    AccountSubscriptions = "user/my-account/subscriptions",
    PlanOptions = "/for-professionals/codes-and-standards/nfpa-link#about-nfpa-link-and-plan-options",
    ProductBasePath = "/product",
}
// AccountInfo = "user/my-account/account%20information",
