import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import NaturalLanguageSearchSession from "organisms/modals/natural-language-search-modal/interfaces/natural-language-search-session";

const defaultValue: NaturalLanguageSearchSession =
    RecordUtils.defaultValuesFactory<NaturalLanguageSearchSession>({
        id: undefined,
    });

export default class NaturalLanguageSearchSessionRecord extends Record<NaturalLanguageSearchSession>(
    defaultValue
) {
    constructor(params?: NaturalLanguageSearchSession) {
        if (params == null) {
            params = Object.assign({}, defaultValue);
        }

        super(params);
    }
}
