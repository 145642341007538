import { Record } from "immutable";
import type UserRoleGroup from "models/interfaces/user-role-group";
import GroupRecord from "models/view-models/group-record";
import UserRoleRecord from "models/view-models/user-role-record";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import DateUtils from "utilities/date-utils";
import { t } from "utilities/localization-utils";

const defaultValues: UserRoleGroup =
    RecordUtils.auditableDefaultValuesFactory<UserRoleGroup>({
        group: undefined,
        groupId: 0,
        isAdmin: false,
        userRoleId: 0,
        userRole: undefined,
        lastActive: undefined,
        isOwner: undefined,
    });

export default class UserRoleGroupRecord
    extends Record(defaultValues)
    implements UserRoleGroup
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserRoleGroup) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.group != null) {
            params.group = RecordUtils.ensureRecord(params.group, GroupRecord);
        }

        if (params.userRole != null) {
            params.userRole = RecordUtils.ensureRecord(
                params.userRole,
                UserRoleRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getTypeName(): string {
        return this.isOwner
            ? t("owner")
            : this.isAdmin
              ? t("admin")
              : t("team-member");
    }

    public getLastActive(): string | undefined {
        return DateUtils.formatDate(this.lastActive?.toString());
    }

    /**
     * Merges new values into the record and returns a new instance.
     */
    public with(values: Partial<UserRoleGroup>): UserRoleGroupRecord {
        return new UserRoleGroupRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds GroupRecord relationship to UserRoleGroup from a list of GroupRecords.
     */
    public withGroup(groups?: Array<GroupRecord>): UserRoleGroupRecord {
        if (groups == null) {
            return this.with({});
        }

        var group = groups.find(
            (group: GroupRecord) => group.id === this.groupId
        );

        return this.with({ group: group });
    }

    // #endregion Public Methods
}
